import React, { useState } from 'react';
import emojiIcon from '/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/face-smile-regular.svg';
import Emoji, { gitHubEmojis } from '@tiptap-pro/extension-emoji'
import {
  Button,
  Modal,
  ModalBody,
} from "reactstrap";

const EmojiPicker = ({ editor, visible, toggleVisible }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleEmojiSelect = (emoji) => {
    if (editor) {
      editor.chain().focus().setEmoji(emoji).run();
    }
    toggleVisible(); // Close the dropdown after selection
  };

  return (
    <div style={{ position: 'relative' }}>
      <button onClick={toggleVisible} style={{ position: 'relative', padding: '4px 10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img
          src={emojiIcon}
          alt="emoji-icon"
          style={{ height: '12px' }}
        />
      </button>
      {visible && (
        <Modal isOpen={visible} toggle={toggleVisible} scrollable>
          <ModalBody>
            <div style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(16, 1fr)',
              gap: '1px',
            }}>
              {gitHubEmojis.map((emoji, index) => (
                <button
                  key={index}
                  onClick={() => handleEmojiSelect(emoji.name)}
                  onMouseOver={() => setHoveredIndex(index)}
                  onMouseOut={() => setHoveredIndex(null)}
                  style={{
                    background: hoveredIndex === index ? 'blue' : 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    fontSize: '20px',
                    padding: '1px',
                    border: 'none'
                  }}
                >
                  {emoji.emoji}
                </button>
              ))}
            </div>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default EmojiPicker;
