import React, { useState } from 'react'
import { Card, Col, Row, Button} from "reactstrap";
import { selectUser } from "features/userSlice";
import { useSelector } from "react-redux";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { setDoc, doc } from "firebase/firestore";
import SpinningWheel from './SpinningWheel';

export default function Randomizer({props}) { 

  const user = useSelector(selectUser);
  const classIndex = user.classes.findIndex((c) => c.classId === props.current);
  const [showSingleName, setShowSingleName] = useState(true);

  const rosterData = user.classes[classIndex].rosterData || {
    roster:  [
        {name: "No Roster Data", descriptor1: "", descriptor2: "", birthday: ""},
        {name: "No Roster Data", descriptor1: "", descriptor2: "", birthday: ""},
        {name: "No Roster Data", descriptor1: "", descriptor2: "", birthday: ""},
        {name: "No Roster Data", descriptor1: "", descriptor2: "", birthday: ""},
        {name: "No Roster Data", descriptor1: "", descriptor2: "", birthday: ""},
        {name: "No Roster Data", descriptor1: "", descriptor2: "", birthday: ""},
        {name: "No Roster Data", descriptor1: "", descriptor2: "", birthday: ""},
        {name: "No Roster Data", descriptor1: "", descriptor2: "", birthday: ""},
    ],
    descriptors: {
        descriptor1: "Descriptor 1",
        descriptor2: "Descriptor 2",
    }
}

  const [groups, setGroups] = useState([])

  // function splitRosterIntoGroups(numberOfGroups) {
  //   setRandomName("")
  //   // Shuffle the roster array to randomize the order
  //   const shuffledRoster = [...rosterData.roster].filter((x) => x.name.length > 0).sort(() => Math.random() - 0.5);

  //   const remainingDecimals = (shuffledRoster.length / numberOfGroups) % 1;

  //   // Calculate the size of each group
  //   let groupSize;
  //   const groups = [];
  //   if (remainingDecimals >= .5) {
  //     groupSize = Math.ceil(shuffledRoster.length / numberOfGroups);
  //     for (let i = 0; i < numberOfGroups; i++) {
  //       const startIndex = i * groupSize;
  //       const endIndex = startIndex + groupSize;
  //       const currentGroup = shuffledRoster.slice(startIndex, endIndex);
  //       groups.push(currentGroup);
  //     }
  //   } else {
  //     groupSize = Math.floor(shuffledRoster.length / numberOfGroups);
  //     let oversizedGroupsCount = shuffledRoster.length - (groupSize * numberOfGroups);
  //     for (let i = 0; i < numberOfGroups; i++) {
  //       const startIndex = i * groupSize;
  //       let endIndex = startIndex + groupSize;
  //       if (oversizedGroupsCount > 0) endIndex++;
  //       const currentGroup = shuffledRoster.slice(startIndex, endIndex);
  //       groups.push(currentGroup);
  //       oversizedGroupsCount--;
  //     }
  //   }
  //   setGroups(groups);
  // }

  function splitRosterIntoGroups(numberOfGroups) {
    setShowSingleName(false)

    // Shuffle the roster array to randomize the order
    const shuffledRoster = [...rosterData.roster]
        .filter((x) => x.name.length > 0)
        .sort(() => Math.random() - 0.5);

    const totalMembers = shuffledRoster.length;
    const baseGroupSize = Math.floor(totalMembers / numberOfGroups);
    const remainder = totalMembers % numberOfGroups;

    const groups = [];
    let currentIndex = 0;

    for (let i = 0; i < numberOfGroups; i++) {
        // If remainder > 0, add 1 to the current group size to distribute extra members evenly
        const currentGroupSize = baseGroupSize + (i < remainder ? 1 : 0);
        const currentGroup = shuffledRoster.slice(currentIndex, currentIndex + currentGroupSize);
        groups.push(currentGroup);
        currentIndex += currentGroupSize;
    }

    setGroups(groups);
}


  return (
    <div>
      <Row>
        <Col xs="2" style={{marginTop: '6vh', borderRight: 'solid 1px'}} >
          <Row className='justify-content-center align-items-center'>
          <Button style={{width: '12vw'}} onClick={() => {setShowSingleName(true); setGroups([])}}>Single Name</Button>
          <Button style={{width: '12vw'}} onClick={() => splitRosterIntoGroups(1)}>Random Order</Button>
          <Button style={{width: '12vw'}} onClick={() => splitRosterIntoGroups(2)}>2 Groups</Button>
          <Button style={{width: '12vw'}} onClick={() => splitRosterIntoGroups(3)}>3 Groups</Button>
          <Button style={{width: '12vw'}} onClick={() => splitRosterIntoGroups(4)}>4 Groups</Button>
          <Button style={{width: '12vw'}} onClick={() => splitRosterIntoGroups(5)}>5 Groups</Button>
          <Button style={{width: '12vw'}} onClick={() => splitRosterIntoGroups(6)}>6 Groups</Button>
          <Button style={{width: '12vw'}} onClick={() => splitRosterIntoGroups(7)}>7 Groups</Button>
          <Button style={{width: '12vw'}} onClick={() => splitRosterIntoGroups(8)}>8 Groups</Button>
          </Row>
        </Col>
        <Col xs="10" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: '5vw'}}>
          {/* <Row className='h1'>
            {randomName}
          </Row> */}
          <Row>
            {showSingleName && 
              <div className="container mt-5">
                <SpinningWheel names={rosterData?.roster.map(person => person.name)}/>
              </div>
            }
          </Row>
          <Row className='h4'>
            {/* {groups && groups.map((group, index) => (
              <Card key={index} style={{minWidth: '275px', margin: '10px', padding: '10px'}}>
                {groups.length < 2 ? (
                  <ol>
                    {group.map((person, personIndex) => (
                      <>
                        <li key={personIndex}>{person.name}</li>
                      </>
                    ))}
                  </ol>
                ) 
                : 
                (
                  <ul>
                    <div className='h3 mb-1'>Group {index+1}</div>
                    {group.map((person, personIndex) => (
                      <>
                        <li key={personIndex}>{person.name}</li>
                      </>
                    ))}
                  </ul>
                )
                }
              </Card>
            ))} */}

            {groups && groups.map((group, index) => {
                if (groups.length < 2 && group.length > 15) {
                    // Splitting large single group into multiple subgroups of 15
                    const subGroups = [];
                    for (let i = 0; i < group.length; i += 15) {
                        subGroups.push(group.slice(i, i + 15));
                    }
                    return subGroups.map((subGroup, subIndex) => (
                        <Card key={index + '-' + subIndex} style={{minWidth: '275px', margin: '10px', padding: '10px'}}>
                            <ol start={subIndex * 15 + 1}>
                                {subGroup.map((person, personIndex) => (
                                    <li key={personIndex}>{person.name}</li>
                                ))}
                            </ol>
                        </Card>
                    ));
                } else {
                    return (
                        <Card key={index} style={{minWidth: '275px', margin: '10px', padding: '10px'}}>
                            {groups.length < 2 ? (
                                <ol>
                                    {group.map((person, personIndex) => (
                                        <li key={personIndex}>{person.name}</li>
                                    ))}
                                </ol>
                            ) 
                            : 
                            (
                                <ul>
                                    <div className='h3 mb-1'>Group {index+1}</div>
                                    {group.map((person, personIndex) => (
                                        <li key={personIndex}>{person.name}</li>
                                    ))}
                                </ul>
                            )
                            }
                        </Card>
                    );
                }
            })}
          </Row>
        </Col>
      </Row>
    </div>
  )
}
