import React from 'react'
import { NavLink } from 'react-router-dom';
import { Card, CardBody, Row, Col, Navbar, NavbarBrand,
     NavItem, UncontrolledAccordion, AccordionItem,
      AccordionBody, Button, Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardHeader } from "reactstrap";
import logo from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/launchpad.png";
import logoWithText from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/logo-with-text.png";
import { Link } from 'react-router-dom';

const LandingPageTemplate = () => {
  return (
    <div style={{ backgroundColor: '#f0f0f0' }}>
        <Row style={{height: '10%', justifyContent: 'center', backgroundColor: "rgb(11, 192, 223)"}}>
            <Card style={{width: '80%', marginTop: '1%'}}>
                <Navbar className='landing-navbar'>
                    <NavbarBrand>
                        <a href={"https://www.lessonlaunchpad.com"}>
                        <img src={logoWithText} alt="lesson-launchpad-logo" style={{ width: '175px', height: 'auto' }}/>
                        </a>
                    </NavbarBrand>

                    <div className="ml-auto d-flex align-items-center">
                        {/* <NavLink to="/why-lesson-launchpad" style={{ color: 'black', marginRight: '10px' }}>
                            Why Lesson Launchpad?
                        </NavLink> */}
                        {/* <NavLink to="/pricing" style={{ color: 'black', marginRight: '10px' }}>
                            Pricing
                        </NavLink> */}
                    </div>
                    
                    <div className="ml-auto d-flex align-items-center">
                        {/* <NavLink to="/pricing" style={{ color: 'black', marginRight: '40px' }}>
                            Pricing
                        </NavLink> */}

                        <NavLink to="/auth/login" style={{ color: 'black', marginRight: '50px' }}>
                        Login
                        </NavLink>

                        <Button tag={Link} to="/auth/register" style={{ marginLeft: '10px' }}>
                        Register
                        </Button>
                    </div>
                </Navbar>
            </Card>
        </Row>

{/************************************************* HEADLINE *************************************************/}

        <Row style={{backgroundColor: "white", justifyContent: 'center' }}>
        <div style={{ textAlign: 'center', marginBottom: '50px', marginLeft: '20%', marginRight: '20%'}}>
            <h1 style={{ color: 'rgb(11, 192, 223)', fontWeight: '1000', fontSize: '4rem', textShadow: '1px 1px 0px #5271FF', marginTop: '3%'}}>
            <b>Why Lesson Launchpad?</b>
            </h1>

            <div>
                <h5>
                Welcome to Lesson Launchpad, the ultimate presentation web app designed with teachers in mind. If you're an educator looking to transform the way you deliver daily announcements, agenda updates, and lessons, you've come to the right place. Let's explore why Lesson Launchpad is your must-have tool for a more efficient and engaging classroom experience.
                </h5>
            </div>

            <div>
                <h3 className="article-subheadline">Streamline Your Teaching</h3>
                <p>
                <b>Say goodbye to the chaos of juggling various tools and resources. Lesson Launchpad offers a seamless platform to organize and present your materials.</b> Create, edit, and deliver your lessons with ease, all from one intuitive interface. Whether you're teaching in person or online, our user-friendly design ensures a hassle-free experience.
                </p>
            </div>

            <div>
                <h3 className="article-subheadline">Time Managment Automated</h3>
                <p><b>With automation features, your classes can progress seamlessly throughout the day. Timers can automatically lead up to the tardy bell, notifying students of the time remaining before class starts.</b> It's like having your own teaching assistant, ensuring every minute is utilized effectively.</p>
                <p><b>Teaching is all about timing, and Lesson Launchpad understands that.</b> Our built-in timers and stopwatches empower you to keep your lessons on track. No more fumbling with separate gadgets or checking the clock constantly – Lesson Launchpad does the job for you.</p>
            </div>

            <div>
                <h3 className="article-subheadline">Tailored to Educators</h3>
                <p>
                <b>Lesson Launchpad is not just another generic presentation tool; it's designed specifically for educators.</b> We understand the unique challenges teachers face, and our platform is tailored to address them. From customizable templates to educational resources, we have you covered.
                </p>
            </div>

            <div>
                <h3 className="article-subheadline">Support When You Need It</h3>
                <p>
                <b>Our commitment to your success is ongoing</b> We want to take this journey with you and are committed to making sure we build the perfect app for you and your needs.
                </p>
            </div>
            <br></br>
            <div>
                <p>
                <b>In conclusion,</b> Lesson Launchpad is more than just a presentation app – it's a game-changer for educators. Streamline your teaching, engage your students like never before, and simplify your daily routine with our powerful platform. Join all of the teachers who have already embraced Lesson Launchpad and experience the difference for yourself.
                </p>
            </div>
            <br></br>
            <div>
                <h5>
                Ready to take your teaching to the next level? <a href={'/auth/register'}>Sign up for Lesson Launchpad</a> today and embark on a journey of educational excellence, where automation enhances your teaching efficiency.
                </h5>
            </div>
            {/* <Button
            tag={Link}
            to="/auth/register"
            className="landing-btn-no-hover"
            style={{
                backgroundColor: 'rgb(11, 192, 223)',
                color: 'white',
                height: '50px',
                width: '200px',
                fontSize: '1.5rem',
            }}
            >
            Get Started
            </Button> */}
        </div>
        </Row>





{/************************************************* FOOTER *************************************************/}

        <Row className="ml-auto" style={{ backgroundColor: "rgb(11, 192, 223)", height: '100px', justifyContent: 'center' }}>
        <Button tag={Link} to="/auth/register" className="landing-btn-no-hover" style={{ backgroundColor: 'white', color: "rgb(11, 192, 223)", height: '75px', width: 'auto', fontSize: '20px' }}>
            <div className="d-flex align-items-center">
            <img src={logo} alt="lesson-launchpad-logo" style={{ width: '50px', height: 'auto', marginRight: '15px' }} />
            Try Lesson Launchpad NOW
            </div>
        </Button>
        </Row>





    </div>
  )
}

export default LandingPageTemplate
