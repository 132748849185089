import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, login, selectUser, updateUser } from "features/userSlice";
import { doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import { auth, db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import AdminLayout from "layouts/Admin.js";
import ShareLayout from "layouts/Share.js";
import LandingPage from "views/LandingPage copy";
import { Spinner, Row } from "reactstrap"
import LogInView from "views/LogInView";
import RegisterView from "views/RegisterView";
import Privacy from "components/Privacy";
import LandingPageTemplate from "views/LandingPageTemplate"
import PricingTable from "components/PricingTable";
import ShowRegisterPay from "views/ShowRegisterPay";
import Contact from "views/Contact";

function App() {
    const location = useLocation();
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true)
    const [firebaseInitialized, setFirebaseInitialized] = useState(false);

    useEffect(() => {
    //I copied this code from somewhere so I don't know why this is called unsubscribe.
    const unsubscribe = auth.onAuthStateChanged(async(userAuth) => {
        if (userAuth) {
            setFirebaseInitialized(true)
            const docRef = doc(db, "users", userAuth.uid);
            const docSnap = await getDoc(docRef);
            let userData = null;
            
            if (docSnap.exists()) {
                userData = docSnap.data();
            } else {
              // docSnap.data() will be undefined in this case
              console.log("No such document!");
            }
        dispatch(login({
            uid: userAuth.uid,
            email: userData.email,
            firstName: userData.firstName,
            lastName: userData.lastName,
            classes: userData.classes,
            contentBoxTemplates: userData.contentBoxTemplates,
            premium: userData.premium,
            settings: userData.settings,
            countdownTimers: userData.countdownTimers,
            trial: userData.trial,
            reminders: userData.reminders,
            accountCreated: userData.accountCreated,
            announcements: userData.announcements,
            leadData: userData.leadData,
            shareCode: userData.shareCode
        })); 
        setIsLoading(false)
        } else {
        //logged out...
        dispatch(logout);
        }
        setIsLoading(false)
    });
    return unsubscribe;
    },[]);

    useEffect(() => {
        if (firebaseInitialized) {
            const currentUser = auth.currentUser;

            if (currentUser) {
            const docRef = doc(db, "users", currentUser.uid);
        
            const unsubscribe = onSnapshot(docRef, (doc) => {
                if (doc.exists()) {
                const userData = doc.data();
        
                dispatch(updateUser({
                    uid: currentUser.uid,
                    email: userData.email,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    classes: userData.classes,
                    contentBoxTemplates: userData.contentBoxTemplates,
                    premium: userData.premium,
                    settings: userData.settings,
                    trial: userData.trial,
                    countdownTimers: userData.countdownTimers,
                    reminders: userData.reminders,
                    accountCreated: userData.accountCreated,
                    announcements: userData.announcements,
                    leadData: userData.leadData,
                    shareCode: userData.shareCode
                }));
                } else {
                // Document doesn't exist
                console.log("No such document!");
                }
            });
            return () => {
                // Unsubscribe from the Firestore listener when the component unmounts
                unsubscribe();
            };
            }
        }
      }, [firebaseInitialized, auth, dispatch]);

      useEffect(() => {
        if (user) {
          if (!user.hasOwnProperty('settings')) {
            addSettingsObjectToUser();
          }
        }
      }, [user]);

      const addSettingsObjectToUser = async () => {
        try {
          const docRef = doc(db, "users", user.uid);
    
          const userCopy = JSON.parse(JSON.stringify(user));
    
          // Update the content boxes with active = true
          userCopy.settings = {
            showSeconds: true,
            logo: true,
            marqueeSpeed: '150',
            alteredSchedule: false,
            multiWeek: false,
            numberOfWeeks: 1,
            currentWeek: "A",
            differentTimes: false,
            useActiveDates: false,
            useShareCode: false,
            screenTimeout: '60'
          }
    
          // Update the document in Firestore
          await setDoc(docRef, userCopy);
  
        } catch (error) {
          console.error('Error updating document:', error);
        }
      }


      //Added for share code functionality
      useEffect(() => {
        const fetchUserData = async () => {
          if (location.pathname.includes("share")) {
            // Parse share code from URL
            const shareCode = location.pathname.match(/\/share\/([a-zA-Z0-9]{6})/)?.[1];
    
            if (shareCode) {
              try {
                // Get userId associated with share code
                const userIDforShareCode = await getUserIDforShareCode(shareCode);
    
                // Get docRef for share code user
                const docRef = doc(db, "users", userIDforShareCode);
    
                const unsubscribe = onSnapshot(docRef, (doc) => {
                  if (doc.exists()) {
                    const userData = doc.data();

                    if (userData.settings?.useShareCode) {
                      // Dispatch the updateUser action
                      dispatch(updateUser({
                        uid: userIDforShareCode,
                        email: userData.email,
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        classes: userData.classes,
                        contentBoxTemplates: userData.contentBoxTemplates,
                        premium: userData.premium,
                        settings: userData.settings,
                        trial: userData.trial,
                        countdownTimers: userData.countdownTimers,
                        reminders: userData.reminders,
                        accountCreated: userData.accountCreated,
                        announcements: userData.announcements,
                        leadData: userData.leadData,
                        shareCode: userData.shareCode
                      }));
                      setIsLoading(false)
                    } else {
                      window.alert("This user is not sharing their content.")
                    }
  
                  } else {
                    // Document doesn't exist
                    console.log("No such document!");
                  }
                });
    
                return () => {
                  // Unsubscribe from the Firestore listener when the component unmounts
                  unsubscribe();
                };
              } catch (error) {
                console.error('Error fetching user ID for share code:', error);
              }
            }
          }
        };
    
        fetchUserData();
      }, [firebaseInitialized, location.pathname, dispatch]);
    
      const getUserIDforShareCode = async (shareCode) => {
        try {
          const docRef = doc(db, "shareCodes", shareCode);
          const docSnap = await getDoc(docRef);
    
          if (docSnap.exists()) {
            const shareCodeDataFetch = docSnap.data();
            console.log("shareCodeDataFetch => ", shareCodeDataFetch);
            return shareCodeDataFetch.uid; // Assuming userID is part of the document data
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error('Error fetching document:', error);
        }
      };

      //only runs mailchimp script on landingpage.
      useEffect(() => {
        // Dynamically load Mailchimp script if on the LandingPage component
        if (location.pathname === "/" && !user) {
            const script = document.createElement("script");
            script.id = "mcjs";
            script.async = true;
            script.src = "https://chimpstatic.com/mcjs-connected/js/users/d6aaa500bb03c2821bd199093/1005a6f4accfc551bf159b59e.js";
            document.head.appendChild(script);
        }
    }, [location.pathname]);

      return (
        <Switch>
        {/* Show loading indicator while user data is loading */}
        {isLoading && 
        <Route render={() => 
            <Row
                style={{
                    display: 'flex',
                    justifyContent: 'center', /* Horizontally center */
                    alignItems: 'center', /* Vertically center */
                    minHeight: '100vh' /* Ensures it covers the entire viewport height */
                }}
            >
                <Spinner style={{ height: '3rem', width: '3rem' }} color="info">Loading...</Spinner>
            </Row>
            } 
        />}
        {/* Render the appropriate route once user data is available */}
        <Route exact path="/auth/register" component={RegisterView} />
        <Route exact path="/auth/login" component={LogInView} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/why-lesson-launchpad" component={LandingPageTemplate} />
        <Route exact path="/show" component={ShowRegisterPay} />
        <Route exact path="/show-pricing" component={PricingTable} />
        <Route path="/pricing" component={PricingTable} />
        {/* <Route exact path="/share" component={ShareLayout} /> */}
        {!isLoading && (
            <>
            <Route path="/" component={user ? AdminLayout : LandingPage}/>
            <Route path="/share" component={user && ShareLayout} />
            </>
        )}
        </Switch>
      );
}

export default App;