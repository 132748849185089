
import React, { useState, useEffect, useRef } from "react";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { setDoc, doc } from "firebase/firestore";
import GoogleSlidesWrapper from "./GoogleSlidesWrapper"; // Import the wrapper component
import backgroundOptionsData from "/Users/michaeltucker/Documents/coding-projects/client/src/BackgroundOptionsData.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import googleSlides from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/googleslides.gif"
import { saveContentBoxTemplate } from "saveTemplate";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, CardFooter, FormFeedback, Button, Modal, ModalBody, ModalHeader, ModalFooter, Container, Row, FormGroup, Label, Input, UncontrolledTooltip} from "reactstrap";
import { createRandomId } from "RandomId";

function GoogleSlidesBox({content, navbarMD, view, user, classId, multiBox, updateContentBox, updateContentBoxWithinMultiBox, printActive}) {
  const [cardHeight, setCardHeight] = useState({card: "auto", editor: "auto"}); // Initially set the height to 'auto'
  const [slideNumber, setSlideNumber] = useState(
    parseInt(localStorage.getItem('slideNumber'), 10) || 1
  );
  const [showMainEditButton, setShowMainEditButton] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [alert, setAlert] = useState(null);
  const location = useLocation();
  const [boxToCopyId, setBoxToCopyId] = useState();
  const [copyBoxModal, setCopyBoxModal] = useState(false);
  const [classesSelectedForContentBoxCopy, setClassesSelectedForContentBoxCopy] = useState([]);
  const today = new Date().toISOString().slice(0,10);

  // Update localStorage whenever slideNumber changes
  useEffect(() => {
    localStorage.setItem('slideNumber', slideNumber);
  }, [slideNumber]);

  useEffect(() => {
    // Function to update the card height based on window height
    const updateCardHeight = () => {
      const windowHeight = window.innerHeight;
      const desiredDistance = navbarMD?.clock === '0' && navbarMD?.marquee === '0' ? 93 : 188; // Desired distance from the bottom
      const cardHeight = windowHeight - desiredDistance;
      setCardHeight({card: `${cardHeight}px`, editor: `${cardHeight-25}px`});
    };

    const handleFullscreenChange = () => {
      const isFullscreen = document.fullscreenElement !== null;
      if (!isFullscreen) {
        const windowHeight = window.innerHeight;
        const desiredDistance = navbarMD?.clock === '0' && navbarMD?.marquee === '0' ? 93 : 188;
        const cardHeight = windowHeight - desiredDistance;
        setCardHeight({card: `${cardHeight}px`, editor: `${cardHeight-25}px`});
        // toggleFullScreenButton();
        setIsFullScreen(false);
      } else {
        // setIsFullScreen(true)
      }
    };
  
    window.addEventListener("resize", updateCardHeight);
    // window.addEventListener("resize", console.log("resize"));
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    // document.addEventListener("fullscreenchange", console.log("fullscreenchange"));
  
    updateCardHeight();
  
    return () => {
      window.removeEventListener("resize", updateCardHeight);
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, [navbarMD, view]);

  // to stop the warning of calling setState of unmounted component
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  const [isFullScreen, setIsFullScreen] = useState(false)

  useEffect(() => {
    // This code will run after the component has mounted
    const element = document.getElementsByClassName('punch-viewer-container');
    if (element) {
      // Do something with the element
      console.log('Found element:', element);
    } else {
      console.log('Element not found');
    }
  }, []); // Empty dependency array ensures this effect runs only once after initial render

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      document.getElementById(`slides-${content.content}`).requestFullscreen().catch(err => {
        console.log(`Error attempting to enable fullscreen: ${err.message}`);
      });
      toggleFullScreenButton();
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen().then(() => {
          const windowHeight = window.innerHeight;
          const desiredDistance = navbarMD?.clock === '0' && navbarMD?.marquee === '0' ? 93 : 188;
          const cardHeight = windowHeight - desiredDistance;
          setCardHeight({card: `${cardHeight}px`, editor: `${cardHeight-25}px`});
          toggleFullScreenButton();
          setIsFullScreen(false);
        }).catch(err => {
          console.log(`Error attempting to exit fullscreen: ${err.message}`);
        });
      }
    }
  };

  const [showFullScreenButton, setShowFullScreenButton] = useState(false)

  const toggleFullScreenButton = () => {
    setShowFullScreenButton(!showFullScreenButton)
  }

  const [activeDatesModal, setActiveDatesModal] = useState(false)

  const toggleSetActiveDatesModal = () => {
    setActiveDatesModal(!activeDatesModal)
  }

  const [activeDates, setActiveDates] = useState({
    startDate: content.startDate === undefined ? "" : content.startDate,
    endDate: content.endDate === undefined ? "" : content.endDate
  });

  const handleActiveDateChange = (event) => {
    const { name, value } = event.target;
    setActiveDates(prevDates => ({
      ...prevDates,
      [name]: value
    }));
  };

  const saveContentBoxActiveDates = async (contentBoxId) => {
    try {
      const docRef = doc(db, "users", user.uid);
  
      const userCopy = JSON.parse(JSON.stringify(user));
  
      // Find the class index based on classId
      const classIndex = userCopy.classes.findIndex(
        (c) => c.classId === classId
      );
  
      if (classIndex !== -1) {
        // Find the content box index based on contentBoxId
        const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
          (box) => box.contentBoxId === contentBoxId
        );
  
        if (contentBoxIndex !== -1) {
          // Make a copy of the existing content box using the spread operator
          const updatedContentBox = { ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex]};
  
          // Update the content box with the new data
          updatedContentBox.startDate = activeDates.startDate;
          updatedContentBox.endDate = activeDates.endDate;
  
          // Update the content box in the document data
          userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;
  
          // Update the document in Firestore
          await setDoc(docRef, userCopy); // Replace with the correct path to your document
  
          // Update the local state with the updated document data
          setBoxes(userCopy.classes[classIndex].contentBoxes);
        }
        toggleSetActiveDatesModal();
      }
    } catch (error) {
      console.error('Error updating document:', error);
    }
};

const [backgroundContentBoxModal, setBackgroundContentBoxModal] = useState(false);
const [backgroundContentBoxIdToChange, setBackgroundContentBoxIdToChange] = useState('');

const toggleBackgroundContentBoxModal = (contentBoxId) => {
  setBackgroundContentBoxModal(!backgroundContentBoxModal);
  setBackgroundContentBoxIdToChange(contentBoxId);
};

const saveBackgroundContentBox = async (imageFileName) => {
  const thing = `url(${imageFileName})`
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
      (box) => box.contentBoxId === content.contentBoxId
    );

    userCopy.classes[classIndex].contentBoxes[contentBoxIndex].background = thing;

    // Update the document in Firestore
    await setDoc(docRef, userCopy);

  } catch (error) {
    console.error('Error updating document:', error);
  }
}

const notificationAlert = useRef();

const editBoxNameAlert = (contentBoxId) => {
  setAlert(
    <ReactBSAlert
      input
      showCancel
      style={{ display: "block", marginTop: "-100px" }}
      title="What would you like to name this box?"
      onConfirm={(e) => updateContentBoxHeading(contentBoxId, e)}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      btnSize=""
      defaultValue={content.heading}
    />
  );
};

const updateContentBoxHeading = async (contentBoxId, updatedHeading) => {
  hideAlert()
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    // Find the class index based on classId
    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    if (classIndex !== -1) {
      // Find the content box index based on contentBoxId
      const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
        (box) => box.contentBoxId === contentBoxId
      );

      if (contentBoxIndex !== -1) {
        // Make a copy of the existing content box using the spread operator
        const updatedContentBox = { ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex], userHeading: true };

        // Update the content box with the new data
        if (updatedHeading.length > 9) {
          updatedContentBox.heading = updatedHeading.substring(0, 9) + "..."
        } else if (updatedHeading.length === 0) {
          updatedContentBox.heading = "Type here..."
        } else {
          updatedContentBox.heading = updatedHeading
        }

        // Update the content box in the document data
        userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;

        // Update the document in Firestore
        await setDoc(docRef, userCopy); // Replace with the correct path to your document

        // Update the local state with the updated document data
        setBoxes(userCopy.classes[classIndex].contentBoxes);

        successAlert()
      }
    }
  } catch (error) {
    console.error('Error updating document:', error);
  }
};

const sendBoxToStorage = async () => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
      (box) => box.contentBoxId === content.contentBoxId
    );

    userCopy.classes[classIndex].contentBoxes[contentBoxIndex].active = false;

    // Update the document in Firestore
    await setDoc(docRef, userCopy);

  } catch (error) {
    console.error('Error updating document:', error);
  }
}

const warningWithConfirmAndCancelMessage = (id) => {
  setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title="Are you sure?"
      onConfirm={() => deleteContentBox(id)}
      onCancel={() => cancelDelete()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText="Yes, delete it!"
      cancelBtnText="Cancel"
      showCancel
      btnSize=""
    >
      You will not be able to recover this content box!
    </ReactBSAlert>
  );
};

const successDelete = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px" }}
      title="Deleted!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box has been deleted.
    </ReactBSAlert>
  );
};

const cancelDelete = () => {
  setAlert(
    <ReactBSAlert
      danger
      style={{ display: "block", marginTop: "-100px" }}
      title="Cancelled"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box is safe :)
    </ReactBSAlert>
  );
};

const deleteContentBox = async (contentBoxId) => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
      (box) => box.contentBoxId === contentBoxId
    );

    // Remove the content box at the specified index
    userCopy.classes[classIndex].contentBoxes.splice(contentBoxIndex, 1);

    // Update the document in Firestore
    await setDoc(docRef, userCopy);

    // // Update the local state with the updated document data
    // setStateContent(userCopy);

    successDelete()

    console.log('Content box deleted successfully!');
  } catch (error) {
    console.error('Error deleting content box:', error);
  }
}
  
const successAlert = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px" }}
      title="Saved!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
    </ReactBSAlert>
  );
};

const hideAlert = () => {
  setAlert(null);
};

  const [slideDuration, setSlideDuration] = useState(content.slideDuration || ""); 
  const [slideLoop, setSlideLoop] = useState(content.slideLoop || false);
  const [slideLink, setSlideLink] = useState(content.slideLink || "");
  const [slideAutoplay, setSlideAutoplay] = useState(content.autoplay || false);

  console.log("content => ", content)

  const handleSlideDurationChange = (event) => {
    const selectedValue = event.target.value;
    setSlideDuration(selectedValue);
  };

  const handleSlideLoopChange = (event) => {
    const selectedValue = event.target.value;
    setSlideLoop(selectedValue);
  };

  const handleSlideLinkChange = (value) => {
    const isValidInput = /(docs\.google\.com\/presentation).*sharing/.test(value);
    setIsGoogleSlideLinkValid(isValidInput);
    setSlideLink(value);
  }

  const handleSlideAutoplayChange = (value) => {
    setSlideAutoplay(value)
  }

  const options = [];
  for (let i = 1; i <= 60; i++) {
    options.push(<option key={i} value={i}>{`${i} Second${i !== 1 ? 's' : ''}`}</option>);
  }

  const [showSlideshowAlert, setShowSlideshowAlert] = useState(false);

  const toggleShowSlideshowAlert = () => {
    setShowSlideshowAlert(prev => !prev)
    setSlideDuration("");
    setSlideLoop(false);
    setSlideLink("");
    setSlideAutoplay(false)
  }

  const [isGoogleSlideLinkValid, setIsGoogleSlideLinkValid] = useState(true); // Set initial validation state


const addSlideshowConfirmAlert = (e) => {
  if (/(docs\.google\.com\/presentation).*sharing/.test(slideLink)) {
    if (!multiBox) {
      updateContentBox(
        content.contentBoxId, 
        {...content, 
          content: slideLink,
          autoplay: slideAutoplay,
          slideDuration: slideDuration,
          loop: slideLoop
        }
      )
    } else {
      updateContentBoxWithinMultiBox({
        ...content,
        content: slideLink,
        autoplay: slideAutoplay,
        slideDuration: slideDuration,
        loop: slideLoop
      })
    }
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Google Slides Added!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
      </ReactBSAlert>
    );
    toggleShowSlideshowAlert()
  } else {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Invalid Google Slides Link!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        <p><b>You entered:</b> {slideLink}</p>
        <br></br>
        <p>A valid URL will start with 'https://docs.google.com/presentation' and end with 'usp=sharing'.</p>
        <br></br>
        <a href="https://support.google.com/drive/answer/2494822?hl=en" target="_blank"><b>CLICK HERE </b></a>for more info.
      </ReactBSAlert>
    );
  }
};

const toggleCopyBoxModal = () => {
  setCopyBoxModal(!copyBoxModal)
  setClassesSelectedForContentBoxCopy([])
}

const handleCheckboxChange = (classId) => {
  setClassesSelectedForContentBoxCopy(prev => {
    if (prev.includes(classId)) {
      // If classId is already selected, remove it
      return prev.filter(id => id !== classId);
    } else {
      // If classId is not selected, add it
      return [...prev, classId];
    }
  });
};

const copyBoxToClasses = async () => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    // Find the class index based on classId
    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    let boxToCopy = userCopy.classes[classIndex].contentBoxes.find(box => box.contentBoxId === boxToCopyId);
    boxToCopy = { ...boxToCopy, contentBoxId: createRandomId() };

    let classesCopy = [...userCopy.classes];

    classesSelectedForContentBoxCopy.forEach(classId => {
      const classIndex = classesCopy.findIndex(c => c.classId === classId);

      if (classIndex !== -1) {
        // Copy the contentBoxes array and add the new box
        const updatedContentBoxes = [...classesCopy[classIndex].contentBoxes, boxToCopy];
        classesCopy[classIndex] = {
          ...classesCopy[classIndex],
          contentBoxes: updatedContentBoxes
        };
      } else {
        console.log(`Class with ID ${classId} not found.`);
      }
    });

    userCopy.classes = classesCopy;

    await setDoc(docRef, userCopy);

    toggleCopyBoxModal()

  } catch (error) {
    console.log("failed", error);
  }
};

  return (
    <>

      {showSlideshowAlert && (
          <ReactBSAlert
          showCancel
          title="Add Google Slides Box"
          style={{ display: "block", marginTop: "-100px" }}
          onConfirm={(e) => addSlideshowConfirmAlert(e)}
          onCancel={() => toggleShowSlideshowAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          btnSize=""
          validationRegex={/(docs\.google\.com\/presentation).*sharing/}
          validationMsg={"Invalid URL. A valid URL will start with 'https://docs.google.com/presentation' and end with 'usp=sharing'. Vist 'https://support.google.com/drive/answer/2494822?hl=en' for more info"}
          dependencies={[slideAutoplay]}
        >
        <img src={googleSlides} alt="google-slides-gif" style={{ width: '400px', height: 'auto', marginBottom: '20px', border: '2px solid black' }}/>
        <div>
          <hr />
          <br></br>
          <div>
          Input the link to your Google Slideshow
          <Input
            type="text"
            onChange={(e) => handleSlideLinkChange(e.target.value)}
            defaultValue={content.content || null}
            placeholder={"Your Google Slides Share link here..."}
            required={true}
            valid={isGoogleSlideLinkValid}
            invalid={!isGoogleSlideLinkValid}
          />
          <FormFeedback type="invalid">
            {/* Validation error message */}
            {isGoogleSlideLinkValid ? null : 'Invalid input!'}
          </FormFeedback>
          </div>
          <br></br>
          <div>
          Would you like the slideshow to autoplay?
          <Input
            type="select"
            onChange={(e) => handleSlideAutoplayChange(e.target.value)}
            defaultValue={slideAutoplay}
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </Input>
          </div>
          {slideAutoplay && (
            <>
              <br></br>
              <div>
                Slide Duration
                <Input
                  disabled={slideAutoplay ? false : true}
                  type="select"
                  onChange={handleSlideDurationChange}
                  value={slideDuration}
                  defaultValue={slideDuration}
                >
                  {options}
                </Input>
              </div>
              <br></br>
              <div>
                Loop Slideshow
                <Input
                  disabled={slideAutoplay ? false : true}
                  type="select"
                  onChange={handleSlideLoopChange}
                  value={slideLoop}
                  defaultValue={slideLoop}
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </Input>
              </div>
            </>
          )}
          <hr />
        </div>
        </ReactBSAlert>
        )}

        <Modal isOpen={copyBoxModal} toggle={toggleCopyBoxModal}>
          <ModalBody>
            <h5>Copy box to:</h5>
            {user && user.classes && user.classes.map(x => 
              <FormGroup check>
                <Label check>
                <Input
                  type="checkbox"
                  defaultChecked={classesSelectedForContentBoxCopy.length > 0 && classesSelectedForContentBoxCopy.includes(x.classId)}
                  onChange={() => handleCheckboxChange(x.classId)}
                />                
                <span className="form-check-sign" />
                {x.className}
                </Label>
              </FormGroup>
            )}
          </ModalBody>
          <ModalFooter>
            <Button disabled={classesSelectedForContentBoxCopy.length < 1} onClick={() => copyBoxToClasses()}>Copy</Button>
            <Button onClick={toggleCopyBoxModal}>Cancel</Button>
          </ModalFooter>
        </Modal>


      <Col style={{ flex: "1", minHeight: "0", paddingLeft: "6px", paddingRight: '6px', height: '100%' }}>
        {alert}
        <Card 
          key={content.contentBoxId} 
          style={{ height: multiBox ? '100%' : cardHeight.card, backgroundImage: content.background || '', zoom: location.pathname === '/print' ? '.6' : '1', border: printActive === true ? '4px solid blue' : location.pathname === '/print' ? 'solid 1px' : 'none'}}
          onMouseEnter={() => {
            setShowMainEditButton(!showMainEditButton);
            toggleFullScreenButton();
          }}
          onMouseLeave={() => {
            setShowMainEditButton(!showMainEditButton);
            toggleFullScreenButton();
          }}
        >
        {showMainEditButton && location.pathname !== '/add-edit-classes' && !multiBox && location.pathname !== '/print' && !location.pathname.includes('/share/') && (
            <>
              <Button
                // className="btn-round btn-icon"
                id="options"
                type="button"
                style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(-60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '7px 11px', zIndex: '9999', overflow: 'visible'}}
                size="sm"
                onClick={() => setShowButtons(!showButtons)}
              >
                <i className={showButtons === false ? "fa fa-caret-down" : "fa fa-caret-up"} />
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="options"
                  placement="left"
                >
                    Options
                </UncontrolledTooltip>
        {showButtons && location.pathname !== '/add-edit-classes' && (
            <>
              <Button
                // className="btn-round btn-icon"
                id="editContentBoxHeading"
                type="button"
                style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '9999'}}
                size="sm"
                onClick={() => editBoxNameAlert(content.contentBoxId)}
              >
                <i className="fa fa-pencil" />
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="editContentBoxHeading"
                  placement="left"
                >
                    Edit Heading
                </UncontrolledTooltip>
              <Button
                // className="btn-round btn-icon"
                id="changeSlidesLink"
                type="button"
                style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(180%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 9px', zIndex: '9999', overflow: 'visible'}}
                size="sm"
                onClick={() => setShowSlideshowAlert(!showSlideshowAlert)}
                >
                <i className="fa fa-link" />
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="changeSlidesLink"
                  placement="left"
                >
                    Change Google Slides Link
                </UncontrolledTooltip>
              <Button
               // className="btn-round btn-icon"
               id="changeContentBoxBackground"
               type="button"
               style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(300%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 8px', zIndex: '9999'}}
               size="sm"
               onClick={() => toggleBackgroundContentBoxModal()}
              >
                <i class="nc-icon nc-palette"></i>             
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="changeContentBoxBackground"
                  placement="left"
                >
                    Change Background
                </UncontrolledTooltip>
                {user.settings.useActiveDates ? (
                        <>
                        <Button
                        // className="btn-round btn-icon"
                        id="setContentBoxActiveDates"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(420%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '9999'}}
                        size="sm"
                        onClick={() => toggleSetActiveDatesModal()}
                        // onClick={null}
                        >
                          <i className="fa fa-calendar" />
                        </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="setContentBoxActiveDates"
                            placement="left"
                          >
                              Set Active Dates
                          </UncontrolledTooltip>
                        </>
                      ) :
                      (
                        <>
                        <Button
                        // className="btn-round btn-icon"
                        id="sendBoxToStorage"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(420%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '9999'}}
                        size="sm"
                        onClick={() => sendBoxToStorage()}
                        // onClick={null}
                        >
                          <i className="nc-icon nc-box" />
                        </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="sendBoxToStorage"
                            placement="left"
                          >
                              Send Box to Storage
                          </UncontrolledTooltip>
                        </>
                      )}
              <Button
              // className="btn-round btn-icon"
              id="copyBox"
              type="button"
              style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(540%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '9999'}}
              size="sm"
              onClick={() => {setBoxToCopyId(content.contentBoxId); toggleCopyBoxModal()}}
              // onClick={null}
              >
                <i className="fa fa-copy" />
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="copyBox"
                  placement="left"
                >
                    Duplicate Box
                </UncontrolledTooltip>
              <Button
              // className="btn-round btn-icon"
              id="deleteContentBox"
              type="button"
              style={{ backgroundColor: 'lightcoral', color: 'white', position: 'absolute', transform: 'translateY(660%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '9999'}}
              size="sm"
              onClick={() => warningWithConfirmAndCancelMessage(content.contentBoxId)}
              >
                <i className="fa fa-trash" />
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="deleteContentBox"
                  placement="left"
                >
                    Delete
                </UncontrolledTooltip>
            </>
            )}
            </>
          )}
          <CardBody id={`slides-${content.content}`}>
          {content.content !== null ?
            <div style={{height: '100%'}}>
              <GoogleSlidesWrapper
                url={content.content}
                width={'100%'}
                height={isFullScreen ? '100%' : '100%'}
                // height={cardHeight.editor}
                containerStyle={{ maxHeight: isFullScreen ? '100%' : null, overflowY: 'auto' }}
                // containerStyle={{ maxHeight: cardHeight.editor, overflowY: 'auto' }}
                autoplay={content.autoplay}
                slideDuration={content.slideDuration}
                loop={content.loop}
                position={slideNumber}
              />
              {showFullScreenButton ? (
                <>
                  <Row style={{ position: 'absolute', bottom: '12px', left: '30px', right: '30px', background: 'none', opacity: '90%', textAlign: 'left', cursor: 'pointer', height:'50px', marginTop: '0px'}}>
                    <Col style={{ display: 'inline-block'}}>
                      <Button className="btn-round btn-icon" size="sm" id="google-slides-button" style={{marginRight: '5px'}} onClick={() => slideNumber > 1 ? setSlideNumber(prev => prev - 1) : null}>
                        <i className="nc-icon nc-minimal-left" />
                      </Button>
                      <Button className="m-0" size="sm" style={{height:'40px'}}>
                        Slide {slideNumber}
                      </Button>
                      <Button className="btn-round btn-icon" size="sm" id="google-slides-button" style={{background: 'rgb(11, 192, 223) !important'}} onClick={() => setSlideNumber(prev => prev + 1)}>
                        <i className="nc-icon nc-minimal-right" />
                      </Button>
                    </Col>
                    <Col style={{ display: 'inline-block', marginTop: '5px'}}>
                      <Button className="float-right m-0" size="sm" onClick={() => toggleFullScreen()}>
                        {!isFullScreen ? 'Full Screen' : 'Exit'}
                      </Button>
                    </Col>
                  </Row>
                </>
                ) : null}
              </div>
              :
              <div style={{ height: '100%', overflowY: 'auto', alignContent: 'center', textAlign: 'center' }}>
                <Button
                  onClick={() => setShowSlideshowAlert(!showSlideshowAlert)}
                >
                  Add Google Slides Link
                </Button>
              </div>
            }
          </CardBody>
        </Card>
        <Modal 
            isOpen={activeDatesModal}
            toggle={toggleSetActiveDatesModal}
            scrollable
            style={{padding: '20px'}}
          >
            <ModalHeader toggle={toggleSetActiveDatesModal}>Set Active Dates</ModalHeader>
            <br></br>
            <ModalBody>
              <Container>
                <Row xs="2">
                  <FormGroup>
                    <Label for="startDate">
                      Start Date
                    </Label>
                    <Input
                        id="startDate"
                        name="startDate"
                        type="date"
                        onChange={handleActiveDateChange}
                        // defaultValue={"2024-02-02"}
                        value={activeDates.startDate || today}
                    >
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="endDate">
                      End Date
                    </Label>
                    <Input
                        id="endDate"
                        name="endDate"
                        type="date"
                        onChange={handleActiveDateChange}
                        value={activeDates.endDate || today}
                    >
                    </Input>
                  </FormGroup>
                </Row>
                <Row>
                  <a href="https://lessonlaunchpad.com/faq#active-dates">What are Active Dates?</a>
                </Row>
              </Container>
            </ModalBody>
            <Button onClick={() => saveContentBoxActiveDates(content.contentBoxId)}>Save Active Dates</Button>
          </Modal>
          <Modal 
            isOpen={backgroundContentBoxModal}
            toggle={toggleBackgroundContentBoxModal}
            backdrop={false}
            scrollable
          >
            <ModalHeader toggle={toggleBackgroundContentBoxModal} />
            <ModalBody style={{padding: '0'}}>
              <Container>
                <Row xs="6">
                  {backgroundOptionsData.map((option, index) => (
                    <Col key={index} onClick={() => saveBackgroundContentBox(option.image)} style={{ padding: '0px' }}>
                      <div className="image-container">
                        <img src={option.thumbnail} alt={`Background option ${index + 1}`} />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
            </ModalBody>
          </Modal>
      </Col>
    </>
  );
}

export default GoogleSlidesBox;
