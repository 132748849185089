import React, { useEffect, useState } from 'react'
import { FormFeedback, Badge, Card, CardHeader, CardBody, Container, Input, CardTitle, Row, Col, UncontrolledTooltip, ButtonDropdown, Button, ButtonGroup, DropdownToggle, DropdownMenu, DropdownItem, Spinner, Dropdown, Modal, ModalBody, ModalHeader } from "reactstrap";

const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
const daysLong = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Octo', 'Nov', 'Dec'];
const monthsLong = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const daysFrench = ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'];
const daysLongFrench = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
const monthsFrench = ['jan', 'fév', 'mar', 'avr', 'mai', 'juin', 'juil', 'août', 'sept', 'oct', 'nov', 'déc'];
const monthsLongFrench = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];

const suffix = ['st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'st'   ]

export const Clock = ({showSeconds, clockVersion, clockFontColor, clockLanguage, clockKey}) => {

    let h24 = clockLanguage === "French" ? true : false
    const [hour     , setHour       ] = useState(0);
    const [minute   , setMinute     ] = useState(0);
    const [second   , setSecond     ] = useState(0);
    const [day      , setDay        ] = useState(0);
    const [dayNumber, setDayNumber  ] = useState(0);
    const [month    , setMonth      ] = useState(0);
    const [year     , setYear       ] = useState(0);
    const [pm       , setPm         ] = useState(false);

    // let formattedTime = `${hour}:${minute < 10 ? "0" + minute : minute}`;

    let formattedTime = `${hour}:${minute < 10 ? "0" + minute : minute}:${second < 10 ? "0" + second : second}`;

    let formattedDate = 
        clockLanguage === "French" ? `${daysLongFrench[day]} ${dayNumber === 1 ? "1er" : dayNumber} ${monthsLongFrench[month]} ${year}`
        : `${days[day]}, ${months[month]}. ${dayNumber + suffix[dayNumber-1]}, ${year}`;

    let formattedDateShort = 
        clockLanguage === "French" ? `${daysLongFrench[day]} ${dayNumber}/${month+1}/${year}`
        : `${days[day]}. ${month+1}/${dayNumber}/${year}`;

    let dayLongOnly = 
        clockLanguage === "French" ? `${daysLongFrench[day]}`
        : `${daysLong[day]}`;

    let dateLongOnly = 
        clockLanguage === "French" ? `${dayNumber === 1 ? "1er" : dayNumber} ${monthsLongFrench[month]} ${year}`
        : `${monthsLong[month]} ${dayNumber + suffix[dayNumber-1]}, ${year}`;

    let option5 = 
        clockLanguage === "French" ? `${dayNumber}/${month+1}/${year-2000}`
        : `${month+1}/${dayNumber}/${year-2000}`;


    if (!showSeconds) {
      formattedTime = formattedTime.replace(`:${second < 10 ? "0" + second : second}`, '');
    }

    useEffect(()=> {

        const update = () => {
            const date = new Date();
            let hour = date.getHours();
            if(!h24) {
                hour = (hour % 12) || 12;
            }
            setHour(hour);
            setMinute(date.getMinutes());
            setSecond(date.getSeconds());
            setDay(date.getDay());
            setDayNumber(date.getDate())
            setMonth(date.getMonth());
            setYear(date.getFullYear());
            setPm(date.getHours() >= 12);
        }

        update();

        const interval = setInterval(()=> {
            update();
        }, 1000);

        return ()=>clearInterval(interval);
    }, [clockLanguage, clockKey]);

    return (
        <div key={`clockKey-${clockKey}`}>
        {clockVersion === 1 ? (
            <CardTitle className='h1' style={{marginTop: '20px', color: clockFontColor}}>
                <div>
                    <div className="clock">
                        {formattedTime}
                    </div>
                </div>
            </CardTitle>
        ) : clockVersion === 2 ? (
            <CardTitle className='h1' style={{marginTop: '0px', color: clockFontColor}}>
                <div>
                    <div className="clock">
                        {formattedTime}
                    </div>
                    <div style={{fontSize: '20px', textAlign: 'center'}}>
                        {formattedDate}
                    </div>
                </div>
            </CardTitle>
        ) : clockVersion === 3 ? (
            <CardTitle className='h1' style={{marginTop: '0px', color: clockFontColor}}>
                <div>
                    <div className="clock">
                        {formattedTime}
                    </div>
                    <div style={{fontSize: '27px', textAlign: 'center'}}>
                        {formattedDateShort}
                    </div>
                </div>
            </CardTitle>
        ) : clockVersion === 4 ? (
            <CardTitle className='h1' style={{marginTop: '10px', color: clockFontColor}}>
                <div>
                    <div style={{fontSize: '35px', textAlign: 'center'}}>
                        {dayLongOnly}
                    </div>
                    <div style={{fontSize: '20px', textAlign: 'center'}}>
                        {dateLongOnly}
                    </div>
                </div>
            </CardTitle>
        ) : clockVersion === 5 ? (
            <CardTitle className='h2' style={{marginTop: '15px', color: clockFontColor}}>
                <div>
                    <div style={{fontSize: '55px', textAlign: 'center'}}>
                        {option5}
                    </div>
                </div>
            </CardTitle>
        ) : (
            <CardTitle className='h1' style={{marginTop: '20px', color: clockFontColor}}>
                <div>
                    <div className="clock">
                        {formattedTime}
                    </div>
                </div>
            </CardTitle>
        )}
        </div>
    )
    
}

export default Clock;
