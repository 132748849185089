import React, { useState, useEffect, useRef } from 'react';
import { CardBody } from "reactstrap";
import AlarmSoundShort from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/sounds/alarm-short.mp3";
import AlarmSoundMedium from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/sounds/alarm-medium.mp3";
import AlarmSoundLong from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/sounds/alarm-long.mp3";
import VideoModal from './VideoModal';  // Import the new VideoModal component

const CountdownTimer = ({ props, onComplete, modalClassName, backdrop }) => {
  const minutes = Math.floor(props?.duration / 60);
  const seconds = props?.duration % 60;
  const [minutesInTimer, setMinutesInTimer] = useState(minutes);
  const [secondsInTimer, setSecondsInTimer] = useState(seconds);
  const [isActive, setIsActive] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    let timer;
    if (isActive) {
      timer = setInterval(() => {
        if (minutesInTimer === 0 && secondsInTimer === 0) {
          playSound();
          clearInterval(timer);
          setIsCompleted(true);
          setIsActive(false);
          onComplete(); // Trigger the flash effect
          if (props.youtube) {
            setIsModalOpen(true); // Open the modal when the countdown completes
          }
        } else if (secondsInTimer === 0) {
          setMinutesInTimer((prevMinutes) => prevMinutes - 1);
          setSecondsInTimer(59);
        } else {
          setSecondsInTimer((prevSeconds) => prevSeconds - 1);
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isActive, minutesInTimer, secondsInTimer]);

  const playSound = () => {
    if (!props.youtube && audioRef.current) {
      audioRef.current.play();
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <CardBody className="text-center">
        <h1 style={{ marginBottom: '0px' }}>{props.header}</h1>
        <h1 className='timer-digits' style={{fontSize: '100px'}}>
          {String(minutesInTimer).padStart(2, '0')}:{String(secondsInTimer).padStart(2, '0')}
        </h1>
        <div>
          {isCompleted && (
            <h1>{props.alertMessage}</h1>
          )}
        </div>
      </CardBody>
      {!props.youtube ? (
        <audio ref={audioRef}>
          <source
            src={
              props.alertSound === "short" ? AlarmSoundShort
                : props.alertSound === "medium" ? AlarmSoundMedium
                  : props.alertSound === "long" ? AlarmSoundLong
                    : props.alertSound === "none" ? null
                      : props.alertSound
            }
            type="audio/mpeg"
          />
          Your browser does not support the audio element.
        </audio>
      ) : null}
      <VideoModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        videoUrl={props.youtubeSrc}
        modalClassName={modalClassName}
        backdrop={backdrop}
      />
    </>
  );
};

export default CountdownTimer;
