const backgroundOptionsData = [
    { name: '', thumbnail: '/noBackground.png', image: '' },
    { name: '', thumbnail: '/colors/colors1.png', image: '/colors/colors1.png' },
    { name: '', thumbnail: '/colors/colors2.png', image: '/colors/colors2.png' },
    { name: '', thumbnail: '/colors/colors3.png', image: '/colors/colors3.png' },
    { name: '', thumbnail: '/colors/colors4.png', image: '/colors/colors4.png' },
    { name: '', thumbnail: '/colors/colors5.png', image: '/colors/colors5.png' },
    { name: '', thumbnail: '/colors/colors6.png', image: '/colors/colors6.png' },
    { name: '', thumbnail: '/colors/colors7.png', image: '/colors/colors7.png' },
    { name: '', thumbnail: '/colors/colors26.png', image: '/colors/colors26.png' },
    { name: '', thumbnail: '/colors/colors27.png', image: '/colors/colors27.png' },
    { name: '', thumbnail: '/colors/colors28.png', image: '/colors/colors28.png' },
    { name: '', thumbnail: '/colors/colors29.png', image: '/colors/colors29.png' },
    { name: '', thumbnail: '/colors/colors8.png', image: '/colors/colors8.png' },
    { name: '', thumbnail: '/colors/colors9.png', image: '/colors/colors9.png' },
    { name: '', thumbnail: '/colors/colors30.png', image: '/colors/colors30.png' },
    { name: '', thumbnail: '/colors/colors31.png', image: '/colors/colors31.png' },
    { name: '', thumbnail: '/colors/colors32.png', image: '/colors/colors32.png' },
    { name: '', thumbnail: '/colors/colors10.png', image: '/colors/colors10.png' },
    { name: '', thumbnail: '/colors/colors33.png', image: '/colors/colors33.png' },
    { name: '', thumbnail: '/colors/colors34.png', image: '/colors/colors34.png' },
    { name: '', thumbnail: '/colors/colors35.png', image: '/colors/colors35.png' },
    { name: '', thumbnail: '/colors/colors36.png', image: '/colors/colors36.png' },
    { name: '', thumbnail: '/colors/colors11.png', image: '/colors/colors11.png' },
    { name: '', thumbnail: '/colors/colors37.png', image: '/colors/colors37.png' },
    { name: '', thumbnail: '/colors/colors38.png', image: '/colors/colors38.png' },
    { name: '', thumbnail: '/colors/colors39.png', image: '/colors/colors39.png' },
    { name: '', thumbnail: '/colors/colors40.png', image: '/colors/colors40.png' },
    { name: '', thumbnail: '/colors/colors12.png', image: '/colors/colors12.png' },
    { name: '', thumbnail: '/colors/colors41.png', image: '/colors/colors41.png' },
    { name: '', thumbnail: '/colors/colors42.png', image: '/colors/colors42.png' },
    { name: '', thumbnail: '/colors/colors43.png', image: '/colors/colors43.png' },
    { name: '', thumbnail: '/colors/colors44.png', image: '/colors/colors44.png' },
    { name: '', thumbnail: '/colors/colors13.png', image: '/colors/colors13.png' },
    { name: '', thumbnail: '/colors/colors45.png', image: '/colors/colors45.png' },
    { name: '', thumbnail: '/colors/colors46.png', image: '/colors/colors46.png' },
    { name: '', thumbnail: '/colors/colors47.png', image: '/colors/colors47.png' },
    { name: '', thumbnail: '/colors/colors48.png', image: '/colors/colors48.png' },
    { name: '', thumbnail: '/colors/colors14.png', image: '/colors/colors14.png' },
    { name: '', thumbnail: '/colors/colors49.png', image: '/colors/colors49.png' },
    { name: '', thumbnail: '/colors/colors50.png', image: '/colors/colors50.png' },
    { name: '', thumbnail: '/colors/colors51.png', image: '/colors/colors51.png' },
    { name: '', thumbnail: '/colors/colors52.png', image: '/colors/colors52.png' },
    { name: '', thumbnail: '/colors/colors15.png', image: '/colors/colors15.png' },
    { name: '', thumbnail: '/colors/colors53.png', image: '/colors/colors53.png' },
    { name: '', thumbnail: '/colors/colors54.png', image: '/colors/colors54.png' },
    { name: '', thumbnail: '/colors/colors55.png', image: '/colors/colors55.png' },
    { name: '', thumbnail: '/colors/colors56.png', image: '/colors/colors56.png' },
    { name: '', thumbnail: '/colors/colors16.png', image: '/colors/colors16.png' },
    { name: '', thumbnail: '/colors/colors57.png', image: '/colors/colors57.png' },
    { name: '', thumbnail: '/colors/colors58.png', image: '/colors/colors58.png' },
    { name: '', thumbnail: '/colors/colors59.png', image: '/colors/colors59.png' },
    { name: '', thumbnail: '/colors/colors60.png', image: '/colors/colors60.png' },
    { name: '', thumbnail: '/colors/colors17.png', image: '/colors/colors17.png' },
    { name: '', thumbnail: '/colors/colors61.png', image: '/colors/colors61.png' },
    { name: '', thumbnail: '/colors/colors62.png', image: '/colors/colors62.png' },
    { name: '', thumbnail: '/colors/colors63.png', image: '/colors/colors63.png' },
    { name: '', thumbnail: '/colors/colors64.png', image: '/colors/colors64.png' },
    { name: '', thumbnail: '/colors/colors18.png', image: '/colors/colors18.png' },
    { name: '', thumbnail: '/colors/colors65.png', image: '/colors/colors65.png' },
    { name: '', thumbnail: '/colors/colors66.png', image: '/colors/colors66.png' },
    { name: '', thumbnail: '/colors/colors67.png', image: '/colors/colors67.png' },
    { name: '', thumbnail: '/colors/colors68.png', image: '/colors/colors68.png' },
    { name: '', thumbnail: '/colors/colors19.png', image: '/colors/colors19.png' },
    { name: '', thumbnail: '/colors/colors69.png', image: '/colors/colors69.png' },
    { name: '', thumbnail: '/colors/colors70.png', image: '/colors/colors70.png' },
    { name: '', thumbnail: '/colors/colors71.png', image: '/colors/colors71.png' },
    { name: '', thumbnail: '/colors/colors72.png', image: '/colors/colors72.png' },
    { name: '', thumbnail: '/colors/colors20.png', image: '/colors/colors20.png' },
    { name: '', thumbnail: '/colors/colors73.png', image: '/colors/colors73.png' },
    { name: '', thumbnail: '/colors/colors74.png', image: '/colors/colors74.png' },
    { name: '', thumbnail: '/colors/colors75.png', image: '/colors/colors75.png' },
    { name: '', thumbnail: '/colors/colors76.png', image: '/colors/colors76.png' },
    { name: '', thumbnail: '/colors/colors21.png', image: '/colors/colors21.png' },
    { name: '', thumbnail: '/colors/colors77.png', image: '/colors/colors77.png' },
    { name: '', thumbnail: '/colors/colors78.png', image: '/colors/colors78.png' },
    { name: '', thumbnail: '/colors/colors79.png', image: '/colors/colors79.png' },
    { name: '', thumbnail: '/colors/colors80.png', image: '/colors/colors80.png' },
    { name: '', thumbnail: '/colors/colors22.png', image: '/colors/colors22.png' },
    { name: '', thumbnail: '/colors/colors81.png', image: '/colors/colors81.png' },
    { name: '', thumbnail: '/colors/colors82.png', image: '/colors/colors82.png' },
    { name: '', thumbnail: '/colors/colors83.png', image: '/colors/colors83.png' },
    { name: '', thumbnail: '/colors/colors84.png', image: '/colors/colors84.png' },
    { name: '', thumbnail: '/colors/colors23.png', image: '/colors/colors23.png' },
    { name: '', thumbnail: '/colors/colors85.png', image: '/colors/colors85.png' },
    { name: '', thumbnail: '/colors/colors86.png', image: '/colors/colors86.png' },
    { name: '', thumbnail: '/colors/colors87.png', image: '/colors/colors87.png' },
    { name: '', thumbnail: '/colors/colors88.png', image: '/colors/colors88.png' },
    { name: '', thumbnail: '/colors/colors24.png', image: '/colors/colors24.png' },
    { name: '', thumbnail: '/colors/colors89.png', image: '/colors/colors89.png' },
    { name: '', thumbnail: '/colors/colors90.png', image: '/colors/colors90.png' },
    { name: '', thumbnail: '/colors/colors91.png', image: '/colors/colors91.png' },
    { name: '', thumbnail: '/colors/colors92.png', image: '/colors/colors92.png' },
    { name: '', thumbnail: '/colors/colors25.png', image: '/colors/colors25.png' },
    { name: '', thumbnail: '/colors/colors93.png', image: '/colors/colors93.png' },
    { name: '', thumbnail: '/colors/colors94.png', image: '/colors/colors94.png' },
    { name: '', thumbnail: '/colors/colors95.png', image: '/colors/colors95.png' },
    { name: '', thumbnail: '/colors/colors96.png', image: '/colors/colors96.png' },
    { name: '', thumbnail: '/gradients/gradient1.png', image: '/gradients/gradient19.png' },
    { name: '', thumbnail: '/gradients/gradient2.png', image: '/gradients/gradient20.png' },
    { name: '', thumbnail: '/gradients/gradient3.png', image: '/gradients/gradient21.png' },
    { name: '', thumbnail: '/gradients/gradient4.png', image: '/gradients/gradient22.png' },
    { name: '', thumbnail: '/gradients/gradient5.png', image: '/gradients/gradient23.png' },
    { name: '', thumbnail: '/gradients/gradient6.png', image: '/gradients/gradient24.png' },
    { name: '', thumbnail: '/gradients/gradient7.png', image: '/gradients/gradient25.png' },
    { name: '', thumbnail: '/gradients/gradient8.png', image: '/gradients/gradient26.png' },
    { name: '', thumbnail: '/gradients/gradient9.png', image: '/gradients/gradient27.png' },
    { name: '', thumbnail: '/gradients/gradient10.png', image: '/gradients/gradient28.png' },
    { name: '', thumbnail: '/gradients/gradient11.png', image: '/gradients/gradient29.png' },
    { name: '', thumbnail: '/gradients/gradient12.png', image: '/gradients/gradient30.png' },
    { name: '', thumbnail: '/gradients/gradient13.png', image: '/gradients/gradient31.png' },
    { name: '', thumbnail: '/gradients/gradient14.png', image: '/gradients/gradient32.png' },
    { name: '', thumbnail: '/gradients/gradient15.png', image: '/gradients/gradient33.png' },
    { name: '', thumbnail: '/gradients/gradient16.png', image: '/gradients/gradient34.png' },
    { name: '', thumbnail: '/gradients/gradient17.png', image: '/gradients/gradient35.png' },
    { name: '', thumbnail: '/gradients/gradient18.png', image: '/gradients/gradient36.png' },
    { name: '', thumbnail: '/instruments/instruments1.png', image: '/instruments/instruments1.png' },
    { name: '', thumbnail: '/instruments/instruments2.png', image: '/instruments/instruments2.png' },
    { name: '', thumbnail: '/instruments/instruments3.png', image: '/instruments/instruments3.png' },
    { name: '', thumbnail: '/instruments/instruments4.png', image: '/instruments/instruments4.png' },
    { name: '', thumbnail: '/instruments/instruments5.png', image: '/instruments/instruments5.png' },
    { name: '', thumbnail: '/instruments/instruments6.png', image: '/instruments/instruments6.png' },
    { name: '', thumbnail: '/instruments/instruments7.png', image: '/instruments/instruments7.png' },
    { name: '', thumbnail: '/instruments/instruments8.png', image: '/instruments/instruments8.png' },
    { name: '', thumbnail: '/instruments/instruments9.png', image: '/instruments/instruments9.png' },
    { name: '', thumbnail: '/instruments/instruments10.png', image: '/instruments/instruments10.png' },
    { name: '', thumbnail: '/instruments/instruments11.png', image: '/instruments/instruments11.png' },
    { name: '', thumbnail: '/instruments/instruments12.png', image: '/instruments/instruments12.png' },
    { name: '', thumbnail: '/instruments/instruments13.png', image: '/instruments/instruments13.png' },
    { name: '', thumbnail: '/instruments/instruments14.png', image: '/instruments/instruments14.png' },
    { name: '', thumbnail: '/instruments/instruments15.png', image: '/instruments/instruments15.png' },
    { name: '', thumbnail: '/instruments/instruments16.png', image: '/instruments/instruments16.png' },
    { name: '', thumbnail: '/instruments/instruments17.png', image: '/instruments/instruments17.png' },
    { name: '', thumbnail: '/instruments/instruments18.png', image: '/instruments/instruments18.png' },
    { name: '', thumbnail: '/instruments/instruments19.png', image: '/instruments/instruments19.png' },
    { name: '', thumbnail: '/instruments/instruments20.png', image: '/instruments/instruments20.png' },
    { name: '', thumbnail: '/instruments/instruments21.png', image: '/instruments/instruments21.png' },
    { name: '', thumbnail: '/instruments/instruments22.png', image: '/instruments/instruments22.png' },
    { name: '', thumbnail: '/instruments/instruments23.png', image: '/instruments/instruments23.png' },
    { name: '', thumbnail: '/instruments/instruments24.png', image: '/instruments/instruments24.png' },
    { name: '', thumbnail: '/instruments/instruments25.png', image: '/instruments/instruments25.png' },
    { name: '', thumbnail: '/instruments/instruments26.png', image: '/instruments/instruments26.png' },
    { name: '', thumbnail: '/instruments/instruments27.png', image: '/instruments/instruments27.png' },
    { name: '', thumbnail: '/instruments/instruments28.png', image: '/instruments/instruments28.png' },
    { name: '', thumbnail: '/instruments/instruments29.png', image: '/instruments/instruments29.png' },
    { name: '', thumbnail: '/instruments/instruments30.png', image: '/instruments/instruments30.png' },
    { name: '', thumbnail: '/instruments/instruments31.png', image: '/instruments/instruments31.png' },
    { name: '', thumbnail: '/instruments/instruments32.png', image: '/instruments/instruments32.png' },
    { name: '', thumbnail: '/instruments/instruments33.png', image: '/instruments/instruments33.png' },
    { name: '', thumbnail: '/instruments/instruments34.png', image: '/instruments/instruments34.png' },
    { name: '', thumbnail: '/instruments/instruments35.png', image: '/instruments/instruments35.png' },
    { name: '', thumbnail: '/instruments/instruments36.png', image: '/instruments/instruments36.png' },
    { name: '', thumbnail: '/instruments/instruments37.png', image: '/instruments/instruments37.png' },
    { name: '', thumbnail: '/instruments/instruments38.png', image: '/instruments/instruments38.png' },
    { name: '', thumbnail: '/instruments/instruments39.png', image: '/instruments/instruments39.png' },
    { name: '', thumbnail: '/instruments/instruments40.png', image: '/instruments/instruments40.png' },
    { name: '', thumbnail: '/instruments/instruments41.png', image: '/instruments/instruments41.png' },
    { name: '', thumbnail: '/instruments/instruments42.png', image: '/instruments/instruments42.png' },
    { name: '', thumbnail: '/instruments/instruments43.png', image: '/instruments/instruments43.png' },
    { name: '', thumbnail: '/instruments/instruments44.png', image: '/instruments/instruments44.png' },
    { name: '', thumbnail: '/instruments/instruments45.png', image: '/instruments/instruments45.png' },
    { name: '', thumbnail: '/instruments/instruments46.png', image: '/instruments/instruments46.png' },
    { name: '', thumbnail: '/math/math1.png', image: '/math/math1.png' },
    { name: '', thumbnail: '/math/math2.png', image: '/math/math2.png' },
    { name: '', thumbnail: '/math/math3.png', image: '/math/math3.png' },
    { name: '', thumbnail: '/math/math4.png', image: '/math/math4.png' },
    { name: '', thumbnail: '/math/math5.png', image: '/math/math5.png' },
    { name: '', thumbnail: '/math/math6.png', image: '/math/math6.png' },
    { name: '', thumbnail: '/math/math7.png', image: '/math/math7.png' },
    { name: '', thumbnail: '/math/math8.png', image: '/math/math8.png' },
    { name: '', thumbnail: '/math/math9.png', image: '/math/math9.png' },
    { name: '', thumbnail: '/math/math10.png', image: '/math/math10.png' },
    { name: '', thumbnail: '/math/math11.png', image: '/math/math11.png' },
    { name: '', thumbnail: '/math/math12.png', image: '/math/math12.png' },
    { name: '', thumbnail: '/math/math13.png', image: '/math/math13.png' },
    { name: '', thumbnail: '/math/math14.png', image: '/math/math14.png' },
    { name: '', thumbnail: '/math/math15.png', image: '/math/math15.png' },
    { name: '', thumbnail: '/math/math16.png', image: '/math/math16.png' },
    { name: '', thumbnail: '/math/math17.png', image: '/math/math17.png' },
    { name: '', thumbnail: '/math/math18.png', image: '/math/math18.png' },
    { name: '', thumbnail: '/math/math19.png', image: '/math/math19.png' },
    { name: '', thumbnail: '/math/math20.png', image: '/math/math20.png' },
    { name: '', thumbnail: '/math/math21.png', image: '/math/math21.png' },
    { name: '', thumbnail: '/math/math22.png', image: '/math/math22.png' },
    { name: '', thumbnail: '/math/math23.png', image: '/math/math23.png' },
    { name: '', thumbnail: '/math/math24.png', image: '/math/math24.png' },
    { name: '', thumbnail: '/math/math25.png', image: '/math/math25.png' },
    { name: '', thumbnail: '/math/math26.png', image: '/math/math26.png' },
    { name: '', thumbnail: '/math/math27.png', image: '/math/math27.png' },
    { name: '', thumbnail: '/math/math28.png', image: '/math/math28.png' },
    { name: '', thumbnail: '/math/math29.png', image: '/math/math29.png' },
    { name: '', thumbnail: '/math/math30.png', image: '/math/math30.png' },
    { name: '', thumbnail: '/math/math31.png', image: '/math/math31.png' },
    { name: '', thumbnail: '/math/math32.png', image: '/math/math32.png' },
    { name: '', thumbnail: '/math/math33.png', image: '/math/math33.png' },
    { name: '', thumbnail: '/math/math34.png', image: '/math/math34.png' },
    { name: '', thumbnail: '/math/math35.png', image: '/math/math35.png' },
    { name: '', thumbnail: '/math/math36.png', image: '/math/math36.png' },
    { name: '', thumbnail: '/math/math37.png', image: '/math/math37.png' },
    { name: '', thumbnail: '/math/math38.png', image: '/math/math38.png' },
    { name: '', thumbnail: '/math/math39.png', image: '/math/math39.png' },
    { name: '', thumbnail: '/math/math40.png', image: '/math/math40.png' },
    { name: '', thumbnail: '/math/math41.png', image: '/math/math41.png' },
    { name: '', thumbnail: '/math/math42.png', image: '/math/math42.png' },
    { name: '', thumbnail: '/math/math43.png', image: '/math/math43.png' },
    { name: '', thumbnail: '/math/math44.png', image: '/math/math44.png' },
    { name: '', thumbnail: '/math/math45.png', image: '/math/math45.png' },
    { name: '', thumbnail: '/math/math46.png', image: '/math/math46.png' },
    { name: '', thumbnail: '/science/science1.png', image: '/science/science1.png' },
    { name: '', thumbnail: '/science/science2.png', image: '/science/science2.png' },
    { name: '', thumbnail: '/science/science3.png', image: '/science/science3.png' },
    { name: '', thumbnail: '/science/science4.png', image: '/science/science4.png' },
    { name: '', thumbnail: '/science/science5.png', image: '/science/science5.png' },
    { name: '', thumbnail: '/science/science6.png', image: '/science/science6.png' },
    { name: '', thumbnail: '/science/science7.png', image: '/science/science7.png' },
    { name: '', thumbnail: '/science/science8.png', image: '/science/science8.png' },
    { name: '', thumbnail: '/science/science9.png', image: '/science/science9.png' },
    { name: '', thumbnail: '/science/science10.png', image: '/science/science10.png' },
    { name: '', thumbnail: '/science/science11.png', image: '/science/science11.png' },
    { name: '', thumbnail: '/science/science12.png', image: '/science/science12.png' },
    { name: '', thumbnail: '/science/science13.png', image: '/science/science13.png' },
    { name: '', thumbnail: '/science/science14.png', image: '/science/science14.png' },
    { name: '', thumbnail: '/science/science15.png', image: '/science/science15.png' },
    { name: '', thumbnail: '/science/science16.png', image: '/science/science16.png' },
    { name: '', thumbnail: '/science/science17.png', image: '/science/science17.png' },
    { name: '', thumbnail: '/science/science18.png', image: '/science/science18.png' },
    { name: '', thumbnail: '/science/science19.png', image: '/science/science19.png' },
    { name: '', thumbnail: '/science/science20.png', image: '/science/science20.png' },
    { name: '', thumbnail: '/science/science21.png', image: '/science/science21.png' },
    { name: '', thumbnail: '/science/science22.png', image: '/science/science22.png' },
    { name: '', thumbnail: '/science/science23.png', image: '/science/science23.png' },
    { name: '', thumbnail: '/science/science24.png', image: '/science/science24.png' },
    { name: '', thumbnail: '/science/science25.png', image: '/science/science25.png' },
    { name: '', thumbnail: '/science/science26.png', image: '/science/science26.png' },
    { name: '', thumbnail: '/science/science27.png', image: '/science/science27.png' },
    { name: '', thumbnail: '/science/science28.png', image: '/science/science28.png' },
    { name: '', thumbnail: '/science/science29.png', image: '/science/science29.png' },
    { name: '', thumbnail: '/science/science30.png', image: '/science/science30.png' },
    { name: '', thumbnail: '/science/science31.png', image: '/science/science31.png' },
    { name: '', thumbnail: '/science/science32.png', image: '/science/science32.png' },
    { name: '', thumbnail: '/science/science33.png', image: '/science/science33.png' },
    { name: '', thumbnail: '/science/science34.png', image: '/science/science34.png' },
    { name: '', thumbnail: '/science/science35.png', image: '/science/science35.png' },
    { name: '', thumbnail: '/science/science36.png', image: '/science/science36.png' },
    { name: '', thumbnail: '/science/science37.png', image: '/science/science37.png' },
    { name: '', thumbnail: '/science/science38.png', image: '/science/science38.png' },
    { name: '', thumbnail: '/science/science39.png', image: '/science/science39.png' },
    { name: '', thumbnail: '/science/science40.png', image: '/science/science40.png' },
    { name: '', thumbnail: '/science/science41.png', image: '/science/science41.png' },
    { name: '', thumbnail: '/science/science42.png', image: '/science/science42.png' },
    { name: '', thumbnail: '/science/science43.png', image: '/science/science43.png' },
    { name: '', thumbnail: '/science/science44.png', image: '/science/science44.png' },
    { name: '', thumbnail: '/science/science45.png', image: '/science/science45.png' },
    { name: '', thumbnail: '/science/science46.png', image: '/science/science46.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th1.png', image: '/abstract/abstract1.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th2.png', image: '/abstract/abstract2.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th3.png', image: '/abstract/abstract3.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th4.png', image: '/abstract/abstract4.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th5.png', image: '/abstract/abstract5.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th6.png', image: '/abstract/abstract6.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th7.png', image: '/abstract/abstract7.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th8.png', image: '/abstract/abstract8.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th9.png', image: '/abstract/abstract9.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th10.png', image: '/abstract/abstract10.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th11.png', image: '/abstract/abstract11.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th12.png', image: '/abstract/abstract12.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th13.png', image: '/abstract/abstract13.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th14.png', image: '/abstract/abstract14.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th15.png', image: '/abstract/abstract15.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th16.png', image: '/abstract/abstract16.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th17.png', image: '/abstract/abstract17.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th18.png', image: '/abstract/abstract18.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th19.png', image: '/abstract/abstract19.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th20.png', image: '/abstract/abstract20.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th21.png', image: '/abstract/abstract21.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th22.png', image: '/abstract/abstract22.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th23.png', image: '/abstract/abstract23.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th24.png', image: '/abstract/abstract24.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th25.png', image: '/abstract/abstract25.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th26.png', image: '/abstract/abstract26.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th27.png', image: '/abstract/abstract27.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th28.png', image: '/abstract/abstract28.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th29.png', image: '/abstract/abstract29.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th30.png', image: '/abstract/abstract30.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th31.png', image: '/abstract/abstract31.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th32.png', image: '/abstract/abstract32.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th33.png', image: '/abstract/abstract33.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th34.png', image: '/abstract/abstract34.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th35.png', image: '/abstract/abstract35.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th36.png', image: '/abstract/abstract36.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th37.png', image: '/abstract/abstract37.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th38.png', image: '/abstract/abstract38.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th39.png', image: '/abstract/abstract39.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th40.png', image: '/abstract/abstract40.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th41.png', image: '/abstract/abstract41.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th42.png', image: '/abstract/abstract42.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th43.png', image: '/abstract/abstract43.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th44.png', image: '/abstract/abstract44.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th45.png', image: '/abstract/abstract45.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th46.png', image: '/abstract/abstract46.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th47.png', image: '/abstract/abstract47.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th48.png', image: '/abstract/abstract48.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th49.png', image: '/abstract/abstract49.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th50.png', image: '/abstract/abstract50.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th51.png', image: '/abstract/abstract51.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th52.png', image: '/abstract/abstract52.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th53.png', image: '/abstract/abstract53.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th54.png', image: '/abstract/abstract54.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th55.png', image: '/abstract/abstract55.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th56.png', image: '/abstract/abstract56.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th57.png', image: '/abstract/abstract57.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th58.png', image: '/abstract/abstract58.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th59.png', image: '/abstract/abstract59.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th60.png', image: '/abstract/abstract60.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th61.png', image: '/abstract/abstract61.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th62.png', image: '/abstract/abstract62.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th63.png', image: '/abstract/abstract63.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th64.png', image: '/abstract/abstract64.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th65.png', image: '/abstract/abstract65.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th66.png', image: '/abstract/abstract66.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th67.png', image: '/abstract/abstract67.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th68.png', image: '/abstract/abstract68.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th69.png', image: '/abstract/abstract69.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th70.png', image: '/abstract/abstract70.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th71.png', image: '/abstract/abstract71.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th72.png', image: '/abstract/abstract72.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th73.png', image: '/abstract/abstract73.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th74.png', image: '/abstract/abstract74.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th75.png', image: '/abstract/abstract75.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th76.png', image: '/abstract/abstract76.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th77.png', image: '/abstract/abstract77.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th78.png', image: '/abstract/abstract78.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th79.png', image: '/abstract/abstract79.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th80.png', image: '/abstract/abstract80.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th81.png', image: '/abstract/abstract81.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th82.png', image: '/abstract/abstract82.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th83.png', image: '/abstract/abstract83.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th84.png', image: '/abstract/abstract84.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th85.png', image: '/abstract/abstract85.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th86.png', image: '/abstract/abstract86.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th87.png', image: '/abstract/abstract87.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th88.png', image: '/abstract/abstract88.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th89.png', image: '/abstract/abstract89.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th90.png', image: '/abstract/abstract90.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th91.png', image: '/abstract/abstract91.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th92.png', image: '/abstract/abstract92.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th93.png', image: '/abstract/abstract93.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th94.png', image: '/abstract/abstract94.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th95.png', image: '/abstract/abstract95.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th96.png', image: '/abstract/abstract96.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th97.png', image: '/abstract/abstract97.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th98.png', image: '/abstract/abstract98.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th99.png', image: '/abstract/abstract99.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th100.png', image: '/abstract/abstract100.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th101.png', image: '/abstract/abstract101.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th102.png', image: '/abstract/abstract102.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th103.png', image: '/abstract/abstract103.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th104.png', image: '/abstract/abstract104.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th105.png', image: '/abstract/abstract105.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th106.png', image: '/abstract/abstract106.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th107.png', image: '/abstract/abstract107.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th108.png', image: '/abstract/abstract108.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th109.png', image: '/abstract/abstract109.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th110.png', image: '/abstract/abstract110.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th111.png', image: '/abstract/abstract111.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th112.png', image: '/abstract/abstract112.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th113.png', image: '/abstract/abstract113.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th114.png', image: '/abstract/abstract114.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th115.png', image: '/abstract/abstract115.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th116.png', image: '/abstract/abstract116.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th117.png', image: '/abstract/abstract117.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th118.png', image: '/abstract/abstract118.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th119.png', image: '/abstract/abstract119.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th120.png', image: '/abstract/abstract120.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th121.png', image: '/abstract/abstract121.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th122.png', image: '/abstract/abstract122.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th123.png', image: '/abstract/abstract123.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th124.png', image: '/abstract/abstract124.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th125.png', image: '/abstract/abstract125.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th126.png', image: '/abstract/abstract126.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th127.png', image: '/abstract/abstract127.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th128.png', image: '/abstract/abstract128.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th129.png', image: '/abstract/abstract129.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th130.png', image: '/abstract/abstract130.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th131.png', image: '/abstract/abstract131.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th132.png', image: '/abstract/abstract132.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th133.png', image: '/abstract/abstract133.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th134.png', image: '/abstract/abstract134.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th135.png', image: '/abstract/abstract135.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th136.png', image: '/abstract/abstract136.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th137.png', image: '/abstract/abstract137.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th138.png', image: '/abstract/abstract138.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th139.png', image: '/abstract/abstract139.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th140.png', image: '/abstract/abstract140.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th141.png', image: '/abstract/abstract141.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th142.png', image: '/abstract/abstract142.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th143.png', image: '/abstract/abstract143.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th144.png', image: '/abstract/abstract144.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th145.png', image: '/abstract/abstract145.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th146.png', image: '/abstract/abstract146.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th147.png', image: '/abstract/abstract147.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th148.png', image: '/abstract/abstract148.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th149.png', image: '/abstract/abstract149.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th150.png', image: '/abstract/abstract150.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th151.png', image: '/abstract/abstract151.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th152.png', image: '/abstract/abstract152.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th153.png', image: '/abstract/abstract153.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th154.png', image: '/abstract/abstract154.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th155.png', image: '/abstract/abstract155.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th156.png', image: '/abstract/abstract156.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th157.png', image: '/abstract/abstract157.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th158.png', image: '/abstract/abstract158.png' },
    { name: '', thumbnail: '/abstract/abstract-th/abstract-th159.png', image: '/abstract/abstract159.png' },
    { name: '', thumbnail: '/textures/texture1.png', image: '/textures/texture1.png' },
    { name: '', thumbnail: '/textures/texture2.png', image: '/textures/texture2.png' },
    { name: '', thumbnail: '/textures/texture3.png', image: '/textures/texture3.png' },
    { name: '', thumbnail: '/textures/texture4.png', image: '/textures/texture4.png' },
    { name: '', thumbnail: '/textures/texture5.png', image: '/textures/texture5.png' },
    { name: '', thumbnail: '/textures/texture6.png', image: '/textures/texture6.png' },
    { name: '', thumbnail: '/textures/texture7.png', image: '/textures/texture7.png' },
    { name: '', thumbnail: '/textures/texture8.png', image: '/textures/texture8.png' },
    { name: '', thumbnail: '/textures/texture9.png', image: '/textures/texture9.png' },
    { name: '', thumbnail: '/textures/texture10.png', image: '/textures/texture10.png' },
    { name: '', thumbnail: '/textures/texture11.png', image: '/textures/texture11.png' },
    { name: '', thumbnail: '/textures/texture12.png', image: '/textures/texture12.png' },
    { name: '', thumbnail: '/textures/texture13.png', image: '/textures/texture13.png' },
    { name: '', thumbnail: '/textures/texture14.png', image: '/textures/texture14.png' },
    { name: '', thumbnail: '/textures/texture15.png', image: '/textures/texture15.png' },
    { name: '', thumbnail: '/textures/texture16.png', image: '/textures/texture16.png' },
    { name: '', thumbnail: '/textures/texture17.png', image: '/textures/texture17.png' },
    { name: '', thumbnail: '/textures/texture18.png', image: '/textures/texture18.png' },
    { name: '', thumbnail: '/textures/texture19.png', image: '/textures/texture19.png' },
    { name: '', thumbnail: '/textures/texture20.png', image: '/textures/texture20.png' },
    { name: '', thumbnail: '/textures/texture21.png', image: '/textures/texture21.png' },
    { name: '', thumbnail: '/textures/texture22.png', image: '/textures/texture22.png' },
    { name: '', thumbnail: '/textures/texture23.png', image: '/textures/texture23.png' },
    { name: '', thumbnail: '/textures/texture24.png', image: '/textures/texture24.png' },
    { name: '', thumbnail: '/textures/texture25.png', image: '/textures/texture25.png' },
    { name: '', thumbnail: '/textures/texture26.png', image: '/textures/texture26.png' },
    { name: '', thumbnail: '/textures/texture27.png', image: '/textures/texture27.png' },
    { name: '', thumbnail: '/textures/texture28.png', image: '/textures/texture28.png' },
    { name: '', thumbnail: '/textures/texture29.png', image: '/textures/texture29.png' },
  ];

  export default backgroundOptionsData;