import { setDoc, doc } from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { createRandomId } from "RandomId";

export async function saveContentBoxTemplate(user, templateName, contentBox) {

    try {
        const docRef = doc(db, "users", user.uid);

        const userCopy = JSON.parse(JSON.stringify(user));

        const newContentBoxWithNewId = {...contentBox, contentBoxId: createRandomId()}

        userCopy.contentBoxTemplates.push({name: templateName, template: newContentBoxWithNewId})

        await setDoc(docRef, userCopy);
    } catch (error) {
        console.error('Error saving template:', error);
    }
}
