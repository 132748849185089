/*!

=========================================================
* Paper Dashboard React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useRef } from "react";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { setDoc, doc } from "firebase/firestore";
import backgroundOptionsData from "/Users/michaeltucker/Documents/coding-projects/client/src/BackgroundOptionsData.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { saveContentBoxTemplate } from "saveTemplate";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, CardFooter, Button, Modal, ModalBody, ModalHeader, ModalFooter, Container, Row, FormGroup, Label, Input, UncontrolledTooltip, ListGroup, ListGroupItem, Progress, CardTitle, CardHeader, InputGroup} from "reactstrap";
import ReactDatetime from "react-datetime";
import AgendaStep from "./AgendaStep";
import { createRandomId } from "RandomId";
import FontStylePicker from "./FontStylePicker";
import stampIcon from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/stamp-solid.svg";
import FontSize from "./FontSize";

function AgendaBox({content, classId, user, navbarMD, view, printActive, readOnly, clockKey}) {
  const [cardHeight, setCardHeight] = useState({card: "auto", editor: "auto"}); // Initially set the height to 'auto'
  const [showMainEditButton, setShowMainEditButton] = useState();
  const [showButtons, setShowButtons] = useState();
  const [alert, setAlert] = useState(null);
  const location = useLocation();
  const [localAgendaBoxData, setLocalAgendaBoxData] = useState();
  const [boxData, setBoxData] = useState();
  const [boxToCopyId, setBoxToCopyId] = useState()
  const [copyBoxModal, setCopyBoxModal] = useState(false)
  const [classesSelectedForContentBoxCopy, setClassesSelectedForContentBoxCopy] = useState([]);
  const today = new Date().toISOString().slice(0,10)

  useEffect(() => {
    setShowMainEditButton(content.steps.length === 0 ? true : false);
    setShowButtons(content.steps.length === 0 ? true : false);
    setLocalAgendaBoxData(content);
    setBoxData(content);
  }, []);

  useEffect(() => {
    // Function to update the card height based on window height
    const updateCardHeight = () => {
      const windowHeight = window.innerHeight;
      const desiredDistance = navbarMD?.clock === '0' && navbarMD?.marquee === '0' ? 93 : 188; // Desired distance from the bottom
      const cardHeight = windowHeight - desiredDistance;
      setCardHeight({card: `${cardHeight}px`, editor: `${cardHeight-175}px`});
    };

    // Call the updateCardHeight function when the window is resized
    window.addEventListener("resize", updateCardHeight);

    // Initial call to set the card height
    updateCardHeight();

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateCardHeight);
    };
  }, [navbarMD]);

  // to stop the warning of calling setState of unmounted component
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

const saveAgendaBoxData = async () => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    // Find the class index based on classId
    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    if (classIndex !== -1) {
      // Find the content box index based on contentBoxId
      const agendaBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
        (box) => box.contentBoxId === content.contentBoxId
      );

      if (agendaBoxIndex !== -1) {
        // Make a copy of the existing content box using the spread operator
        const updatedAgendaBox = { ...localAgendaBoxData };
        // console.log(updatedContentBox)

        // // Update the content box with the new data
        // updatedAgendaBox.startTime = localAgendaBoxData.startTime;
        // updatedAgendaBox.steps = localAgendaBoxData.steps;
        // updatedAgendaBox.heading = localAgendaBoxData.heading;

        // Create a new Date object
        var currentDate = new Date();

        // Get individual components of the date
        var year = currentDate.getFullYear();
        var month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
        var day = currentDate.getDate();

        // Create a formatted string
        var formattedDate = (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + '/' + year;

        updatedAgendaBox.lastEdited = formattedDate;

        // Update the content box in the document data
        userCopy.classes[classIndex].contentBoxes[agendaBoxIndex] = updatedAgendaBox;

        // Update the document in Firestore
        await setDoc(docRef, userCopy);

        setShowEditAgendaForm(!showEditAgendaForm);
      }
      setBoxData(localAgendaBoxData);
    }
  } catch (error) {
    console.error('Error updating document:', error);
  }
};

const [activeDatesModal, setActiveDatesModal] = useState(false)

  const toggleSetActiveDatesModal = () => {
    setActiveDatesModal(!activeDatesModal)
  }

  const [activeDates, setActiveDates] = useState({
    startDate: content?.startDate === undefined ? "" : content.startDate,
    endDate: content?.endDate === undefined ? "" : content.endDate
  });

  const handleActiveDateChange = (event) => {
    const { name, value } = event.target;
    setActiveDates(prevDates => ({
      ...prevDates,
      [name]: value
    }));
  };

  const addTemplateAlert = (contentBox) => {
    setAlert(
      <ReactBSAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title="What would you like to call this template?"
        onConfirm={(e) => addTemplateConfirmAlert(e, contentBox)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        btnSize=""
        defaultValue={contentBox.heading}
      />
    );
  };
  
  const addTemplateConfirmAlert = (e, contentBox) => {
    saveContentBoxTemplate(user, e, contentBox);
    setAlert(
      <ReactBSAlert
        style={{ display: "block", marginTop: "-100px" }}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
        title={
          <p>
            You entered: <b>{e}</b>
          </p>
        }
      />
    );
  };

  const saveContentBoxActiveDates = async (contentBoxId) => {
    try {
      const docRef = doc(db, "users", user.uid);
  
      const userCopy = JSON.parse(JSON.stringify(user));
  
      // Find the class index based on classId
      const classIndex = userCopy.classes.findIndex(
        (c) => c.classId === classId
      );
  
      if (classIndex !== -1) {
        // Find the content box index based on contentBoxId
        const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
          (box) => box.contentBoxId === contentBoxId
        );
  
        if (contentBoxIndex !== -1) {
          // Make a copy of the existing content box using the spread operator
          const updatedContentBox = { ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex]};
  
          // Update the content box with the new data
          updatedContentBox.startDate = activeDates.startDate;
          updatedContentBox.endDate = activeDates.endDate;
  
          // Update the content box in the document data
          userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;
  
          // Update the document in Firestore
          await setDoc(docRef, userCopy); // Replace with the correct path to your document
  
          // Update the local state with the updated document data
          setBoxes(userCopy.classes[classIndex].contentBoxes);
        }
        toggleSetActiveDatesModal();
      }
    } catch (error) {
      console.error('Error updating document:', error);
    }
};

const [backgroundContentBoxModal, setBackgroundContentBoxModal] = useState(false);
const [backgroundContentBoxIdToChange, setBackgroundContentBoxIdToChange] = useState('');

const toggleBackgroundContentBoxModal = (contentBoxId) => {
  setBackgroundContentBoxModal(!backgroundContentBoxModal);
  setBackgroundContentBoxIdToChange(contentBoxId);
};

const toggleSetShowEditAgendaFormModal = () => {
  setShowEditAgendaForm(!showEditAgendaForm)
}

const saveBackgroundContentBox = async (imageFileName) => {
  const thing = `url(${imageFileName})`
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
      (box) => box.contentBoxId === content.contentBoxId
    );

    userCopy.classes[classIndex].contentBoxes[contentBoxIndex].background = thing;

    // Update the document in Firestore
    await setDoc(docRef, userCopy);

  } catch (error) {
    console.error('Error updating document:', error);
  }
}

const sendBoxToStorage = async () => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
      (box) => box.contentBoxId === content.contentBoxId
    );

    userCopy.classes[classIndex].contentBoxes[contentBoxIndex].active = false;

    // Update the document in Firestore
    await setDoc(docRef, userCopy);

  } catch (error) {
    console.error('Error updating document:', error);
  }
}

const zoomContentBox = async (direction) => {
  const zoomIncrement = direction === 'up' ? 0.1 : -0.1;
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
      (box) => box.contentBoxId === content.contentBoxId
    );

    const previousZoom = userCopy.classes[classIndex].contentBoxes[contentBoxIndex].zoom || 1;

    userCopy.classes[classIndex].contentBoxes[contentBoxIndex].zoom = previousZoom + zoomIncrement;

    // Update the document in Firestore
    await setDoc(docRef, userCopy);

    // Update local state to trigger re-render
    setBoxData({
      ...boxData,
      zoom: previousZoom + zoomIncrement,
    });

  } catch (error) {
    console.error('Error updating document:', error);
  }
};

const warningWithConfirmAndCancelMessage = (id) => {
  setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title="Are you sure?"
      onConfirm={() => deleteContentBox(id)}
      onCancel={() => cancelDelete()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText="Yes, delete it!"
      cancelBtnText="Cancel"
      showCancel
      btnSize=""
    >
      You will not be able to recover this content box!
    </ReactBSAlert>
  );
};

const successDelete = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px" }}
      title="Deleted!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box has been deleted.
    </ReactBSAlert>
  );
};

const cancelDelete = () => {
  setAlert(
    <ReactBSAlert
      danger
      style={{ display: "block", marginTop: "-100px" }}
      title="Cancelled"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box is safe :)
    </ReactBSAlert>
  );
};

const deleteContentBox = async (contentBoxId) => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
      (box) => box.contentBoxId === contentBoxId
    );

    // Remove the content box at the specified index
    userCopy.classes[classIndex].contentBoxes.splice(contentBoxIndex, 1);

    // Update the document in Firestore
    await setDoc(docRef, userCopy);

    // // Update the local state with the updated document data
    // setStateContent(userCopy);

    successDelete()

    console.log('Content box deleted successfully!');
  } catch (error) {
    console.error('Error deleting content box:', error);
  }
}
  
const successAlert = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px" }}
      title="Saved!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
    </ReactBSAlert>
  );
};

const hideAlert = () => {
  setAlert(null);
};

const isMobile = false // /Mobi/.test(navigator.userAgent);

const manualStart = useRef(false);
const [isPaused, setIsPaused] = useState(true)
const isPausedRef = useRef(false);
const processStepRef = useRef(null);
const intervalIdRef = useRef(null);


// Define the processStep function using a ref to persist it across re-renders
processStepRef.current = (stepIndex = 0, subTaskStepIndex = 0) => {
  if (stepIndex >= localAgendaBoxData.steps.length) return;

  clearInterval(intervalIdRef.current);  // Clear previous interval

  const step = localAgendaBoxData.steps[stepIndex];
  const subTaskSteps = step.subTaskSteps || [];
  let subTaskActive = subTaskStepIndex < subTaskSteps.length;

  // Logic to process each step and substep
  intervalIdRef.current = setInterval(() => {
    if (isPausedRef.current === false) {
        setBoxData(currentData => {
          const updatedSteps = [...currentData.steps];
          const currentStep = {...updatedSteps[stepIndex]};
          const currentSubTaskSteps = [...currentStep.subTaskSteps];

        // Update timeElapsed for subTaskStep or main step
        if (subTaskActive) {
          let currentSubTaskStep = {...currentSubTaskSteps[subTaskStepIndex]};
          currentSubTaskStep.timeElapsed = (currentSubTaskStep.timeElapsed || 0) + 1;
          currentSubTaskStep.active = true;
          currentSubTaskSteps[subTaskStepIndex] = currentSubTaskStep;


          if (currentSubTaskStep.timeElapsed >= ((+currentSubTaskStep.minutes * 60) + (+currentSubTaskStep.seconds))) {
            currentSubTaskStep.active = false;
            currentSubTaskStep.completed = true;
            currentSubTaskSteps[subTaskStepIndex] = currentSubTaskStep; // Update the subtask array

            // Move to next subTaskStep or finish all subTaskSteps
            if (subTaskStepIndex + 1 < currentSubTaskSteps.length) {
              clearInterval(intervalIdRef.current);
              processStepRef.current(stepIndex, subTaskStepIndex + 1);
            } else {
              subTaskActive = false; // No more subtasks, proceed to update the main step
            }
          }
        }

        // Update main step only if all subtasks are completed or no subtasks exist
        currentStep.active = true
        currentStep.timeElapsed = (currentStep.timeElapsed || 0) + 1;
        if (currentStep.timeElapsed >= ((+currentStep.minutes * 60) + (+currentStep.seconds))) {
          currentStep.active = false;
          currentStep.completed = true;
          clearInterval(intervalIdRef.current);
          if (stepIndex + 1 < updatedSteps.length) {
            processStepRef.current(stepIndex + 1, 0); // Move to the next main step
          }
        }

        currentStep.subTaskSteps = currentSubTaskSteps; // Re-assign updated subtasks
        updatedSteps[stepIndex] = currentStep;
        return { ...currentData, steps: updatedSteps };
        });
      }
  }, 1000);
};

const calculateDelay = (startTimeStr) => {
  const now = new Date();
  const startTimeParts = startTimeStr.split(':');
  const startTime = new Date(now);
  startTime.setHours(parseInt(startTimeParts[0], 10), parseInt(startTimeParts[1], 10), 0);

  // Calculate the difference in milliseconds
  const difference = startTime - now;

  // Check if startTime is within the past 3 seconds
  if (difference <= 0 && difference >= -3000) {
    // If startTime was less than or equal to 3 seconds before now, run immediately
    return 0;
  } else if (startTime < now) {
    // If startTime is in the past beyond 3 seconds, set for the next day
    startTime.setDate(startTime.getDate() + 1);
    return startTime - now;
  }

  // If startTime is in the future, return the calculated delay
  return difference;
};

useEffect(() => {
  // Initially scheduled process (if necessary)
  if (localAgendaBoxData) {
    const initialDelay = manualStart.current ? 0 : calculateDelay(localAgendaBoxData.startTime);
    const timeoutId = setTimeout(() => {
      processStepRef.current(0, 0);  // Start from the initial or a specific step
    }, initialDelay);

    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalIdRef.current);
    };
  }
}, [localAgendaBoxData, manualStart.current, clockKey]); // Depend on needed props


function convertSecondsToMinutes(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
}

const [showEditAgendaForm, setShowEditAgendaForm] = useState(false);

const addAgendaStep = () => {
  const newStep = {
    stepId: createRandomId(),
    title: '', // Placeholder for step text
    minutes: 0,
    seconds: 0,
    subTask: false,
    subTaskSteps: []
  };
  // Add a new step object to the steps array
  setLocalAgendaBoxData(prevData => ({
    ...prevData,
    steps: [...prevData.steps, newStep]
  }));
};

const addAgendaSubTaskStep = (stepId) => {
  const stepIndex = localAgendaBoxData.steps.findIndex((step) => step.stepId === stepId);

  if (stepIndex !== -1) {
    const newSubTaskStep = {
      stepId: createRandomId(),
      title: '', // Placeholder for step text
      minutes: 0,
      seconds: 0,
    };

    setLocalAgendaBoxData(prevData => {
      const newSteps = [...prevData.steps]; // Create a copy of steps
      
      // Correcting how subTaskSteps are updated
      const updatedSubTaskSteps = newSteps[stepIndex].subTaskSteps 
        ? [...newSteps[stepIndex].subTaskSteps, newSubTaskStep] // If it exists, spread and add new
        : [newSubTaskStep]; // Otherwise, start a new array

      newSteps[stepIndex] = {...newSteps[stepIndex], subTaskSteps: updatedSubTaskSteps}; // Update the step object immutably

      return { ...prevData, steps: newSteps }; // Return new object with updated steps
    });
  }
};

function deleteAgendaStep(stepId) {
  const updatedData = localAgendaBoxData.steps.filter(step => step.stepId !== stepId);
  setLocalAgendaBoxData(prevData => ({
    ...prevData,
    steps: updatedData
  }));
}

function deleteAgendaSubTaskStep(stepIndex, subTaskStepId) {
  // Use a functional update to ensure you're working with the most up-to-date state
  setLocalAgendaBoxData(prevData => {
    // Create a deep copy of the steps to avoid any direct mutation
    let newSteps = prevData.steps.map(step => ({
      ...step,
      subTaskSteps: step.subTaskSteps ? [...step.subTaskSteps] : []
    }));

    // Filter the subTaskSteps array to remove the specified subTaskStep
    newSteps[stepIndex].subTaskSteps = newSteps[stepIndex].subTaskSteps.filter(subTaskStep => 
      subTaskStep.stepId !== subTaskStepId
    );

    // Return the updated state object
    return {
      ...prevData,
      steps: newSteps
    };
  });
}

const handleAgendaTitleChange = (e) => {
  setLocalAgendaBoxData(prevData => ({ ...prevData, heading: e.target.value }));
};

const handleAgendaStartTimeChange = (e) => {
  setLocalAgendaBoxData(prevData => ({ ...prevData, startTime: e._d.toTimeString() }));
};

const handleStepTextChange = (stepId, e) => {
  const updatedSteps = localAgendaBoxData.steps.map((step) =>
    step.stepId === stepId ? { ...step, title: e.target.value } : step
  );
  setLocalAgendaBoxData(prevData => ({ ...prevData, steps: updatedSteps }));
};

const handleSubTaskStepTextChange = (stepIndex, stepId, e) => {
  const updatedSubTaskSteps = localAgendaBoxData.steps[stepIndex].subTaskSteps.map((subTaskStep) =>
  subTaskStep.stepId === stepId ? { ...subTaskStep, title: e.target.value } : subTaskStep
  );
  setLocalAgendaBoxData(prevData => {
    const newSteps = [...prevData.steps]; // Create a copy of steps

    newSteps[stepIndex] = {...newSteps[stepIndex], subTaskSteps: updatedSubTaskSteps}; // Update the step object immutably

    return { ...prevData, steps: newSteps }; // Return new object with updated steps
  });
};

const handleStepDurationChange = (stepId, e, minute) => {
  let updatedSteps = [];
  if (minute) {
    updatedSteps = localAgendaBoxData.steps.map((step) =>
      step.stepId === stepId ? { ...step, minutes: e.target.value} : step
    );
  } else {
    updatedSteps = localAgendaBoxData.steps.map((step) =>
      step.stepId === stepId ? { ...step, seconds: e.target.value } : step
    );
  }
  setLocalAgendaBoxData(prevData => ({ ...prevData, steps: updatedSteps }));
};

const handleSubTaskStepDurationChange = (stepIndex, stepId, e, minute) => {
  const input = parseInt(e.target.value, 10) || 0; // Ensure input is correctly formatted as a number

  // Use a functional update to ensure handling the most recent state
  setLocalAgendaBoxData(prevData => {
    // Map over the steps to deeply copy and update the needed subtask step
    const newSteps = prevData.steps.map((step, index) => {
      if (index === stepIndex) {
        // Map over subtask steps to update the correct one
        const newSubTaskSteps = step.subTaskSteps.map(subTaskStep =>
          subTaskStep.stepId === stepId ? { ...subTaskStep, [minute ? 'minutes' : 'seconds']: input } : subTaskStep
        );

        // Calculate the total minutes and seconds from all subTaskSteps
        const totalMinutes = newSubTaskSteps.reduce((acc, curr) => acc + (curr.minutes || 0), 0);
        const totalSeconds = newSubTaskSteps.reduce((acc, curr) => acc + (curr.seconds || 0), 0);

        // Normalize total duration by converting excess seconds into minutes
        const normalizedMinutes = totalMinutes + Math.floor(totalSeconds / 60);
        const normalizedSeconds = totalSeconds % 60;

        // Update the parent step with the new total minutes and seconds
        return { 
          ...step, 
          subTaskSteps: newSubTaskSteps, 
          minutes: normalizedMinutes, 
          seconds: normalizedSeconds 
        };
      } else {
        return step;
      }
    });

    // Return the updated state object
    return { ...prevData, steps: newSteps };
  });
};

const handleAgendaFontChange = (e) => {
  setLocalAgendaBoxData(prevData => ({ ...prevData, [e.target.id]: e.target.value }));
}

const handleStart = () => {
  manualStart.current = true;
  setIsPaused(!isPaused)
  isPausedRef.current = false;
}

const handlePause = () => {
  if (isPausedRef.current === false) {
    isPausedRef.current = true
    setIsPaused(true)
  } else {
    // setManualStart(!manualStart)
    isPausedRef.current = false
    setIsPaused(false)
  }
}

const handleStartFromStep = (stepIndex, subTaskStepIndex) => {
  if (subTaskStepIndex) {
    handlePause()
    setBoxData(localAgendaBoxData)
    processStepRef.current(stepIndex, subTaskStepIndex);
  } else {
    handlePause()
    setBoxData(localAgendaBoxData)
    processStepRef.current(stepIndex, 0);
  }
}

const toggleCopyBoxModal = () => {
  setCopyBoxModal(!copyBoxModal)
  setClassesSelectedForContentBoxCopy([])
}

const handleCheckboxChange = (classId) => {
  setClassesSelectedForContentBoxCopy(prev => {
    if (prev.includes(classId)) {
      // If classId is already selected, remove it
      return prev.filter(id => id !== classId);
    } else {
      // If classId is not selected, add it
      return [...prev, classId];
    }
  });
};

const copyBoxToClasses = async () => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    // Find the class index based on classId
    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    let boxToCopy = userCopy.classes[classIndex].contentBoxes.find(box => box.contentBoxId === boxToCopyId);
    boxToCopy = { ...boxToCopy, contentBoxId: createRandomId() };

    let classesCopy = [...userCopy.classes];

    classesSelectedForContentBoxCopy.forEach(classId => {
      const classIndex = classesCopy.findIndex(c => c.classId === classId);

      if (classIndex !== -1) {
        // Copy the contentBoxes array and add the new box
        const updatedContentBoxes = [...classesCopy[classIndex].contentBoxes, boxToCopy];
        classesCopy[classIndex] = {
          ...classesCopy[classIndex],
          contentBoxes: updatedContentBoxes
        };
      } else {
        console.log(`Class with ID ${classId} not found.`);
      }
    });

    userCopy.classes = classesCopy;

    await setDoc(docRef, userCopy);

    toggleCopyBoxModal()

  } catch (error) {
    console.log("failed", error);
  }
};

const handleStepMove = (index, direction) => {
  let updatedSteps = [...localAgendaBoxData.steps];
  
  if (index > 0 && direction === "up") {
    // Swap the current item with the one above it
    [updatedSteps[index], updatedSteps[index - 1]] = [
      updatedSteps[index - 1],
      updatedSteps[index],
    ];
  }
  if (index < localAgendaBoxData.steps.length - 1 && direction === "down") {
    // Swap the current item with the one below it
    [updatedSteps[index], updatedSteps[index + 1]] = [
      updatedSteps[index + 1],
      updatedSteps[index],
    ];
  }

  setLocalAgendaBoxData({
    ...localAgendaBoxData,
    steps: updatedSteps,
    version: (localAgendaBoxData.version || 0) + 1 // Increment the version to force re-render
  });
};

const handleSubTaskStepMove = (stepIndex, subTaskStepIndex, direction) => {
  // Create a deep copy of the steps array
  const updatedSteps = localAgendaBoxData.steps.map((step, idx) => {
    if (idx === stepIndex) {
      return {
        ...step,
        subTaskSteps: [...step.subTaskSteps] // Ensure subTaskSteps is a new array
      };
    }
    return step;
  });

  // Perform the swap on the copied subTaskSteps array
  const updatedSubTaskSteps = updatedSteps[stepIndex].subTaskSteps;
  if (subTaskStepIndex > 0 && direction === "up") {
    [updatedSubTaskSteps[subTaskStepIndex], updatedSubTaskSteps[subTaskStepIndex - 1]] = [
      updatedSubTaskSteps[subTaskStepIndex - 1],
      updatedSubTaskSteps[subTaskStepIndex],
    ];
  } else if (subTaskStepIndex < updatedSubTaskSteps.length - 1 && direction === "down") {
    [updatedSubTaskSteps[subTaskStepIndex], updatedSubTaskSteps[subTaskStepIndex + 1]] = [
      updatedSubTaskSteps[subTaskStepIndex + 1],
      updatedSubTaskSteps[subTaskStepIndex],
    ];
  }

  // Update the state with the new steps array
  setLocalAgendaBoxData({
    ...localAgendaBoxData,
    steps: updatedSteps,
    version: (localAgendaBoxData.version || 0) + 1 // Increment the version to force re-render
  });
};

const [showAddResourceLink, setShowAddResourceLink] = useState(false);
const [indexOfStepToEditResource, setIndexOfStepToEditResource] = useState();
const [indexOfSubStepToEditResource, setIndexOfSubStepToEditResource] = useState();
const [stepOrSubStep, setStepOrSubStep] = useState();
const [resourceLink, setResourceLink] = useState();
const [isResourceLinkValid, setIsResourceLinkValid] = useState(true);


const handleAddResourceLinkClick = (stepIndex, subStepIndex, stepOrSubStep) => {
  if (stepOrSubStep?.length > 0) { //Opening Modal
    setShowAddResourceLink(!showAddResourceLink);
    const resourceLinkToEdit = stepOrSubStep === "step" ? localAgendaBoxData.steps[stepIndex]?.resourceLink || null : localAgendaBoxData.steps[stepIndex].subTaskSteps[subStepIndex]?.resourceLink || null;
    setResourceLink(resourceLinkToEdit);
    setIndexOfStepToEditResource(stepIndex);
    setIndexOfSubStepToEditResource(subStepIndex)
    setStepOrSubStep(stepOrSubStep);
  } else { //Closing Modal
    setShowAddResourceLink(!showAddResourceLink);
    setResourceLink(null);
    setIndexOfStepToEditResource(null);
    setIndexOfSubStepToEditResource(null);
    setStepOrSubStep(null);
  }
}

const handleResourceLinkChange = (value) => {
  if (!/^https?:\/\//i.test(value)) {
    value = `https://${value}`;
  }
  setResourceLink(value);
  setIsResourceLinkValid(validateUrl(value));
}

const validateUrl = (value) => {
  try {
    new URL(value);
    return true;
  } catch (_) {
    return false;
  }
};

const handleResourceChangeSubmit = () => {
  if (stepOrSubStep === "step") {
    let updatedSteps = [...localAgendaBoxData.steps];

    // Create a new object with the updated resourceLink
    let updatedStep = {
      ...updatedSteps[indexOfStepToEditResource],
      resourceLink: resourceLink
    };

    // Replace the old step with the new one
    updatedSteps[indexOfStepToEditResource] = updatedStep;

    // Update the state with the new steps array
    setLocalAgendaBoxData({
      ...localAgendaBoxData,
      steps: updatedSteps,
    });
  } else if (stepOrSubStep === "subStep") {
    let updatedSteps = [...localAgendaBoxData.steps];

    // Create a new object with the updated resourceLink for the subStep
    let updatedSubTaskSteps = [...updatedSteps[indexOfStepToEditResource].subTaskSteps];
    updatedSubTaskSteps[indexOfSubStepToEditResource] = {
      ...updatedSubTaskSteps[indexOfSubStepToEditResource],
      resourceLink: resourceLink
    };

    // Create a new object for the step that contains the updated subTaskSteps
    let updatedStep = {
      ...updatedSteps[indexOfStepToEditResource],
      subTaskSteps: updatedSubTaskSteps
    };

    // Replace the old step with the new one
    updatedSteps[indexOfStepToEditResource] = updatedStep;

    // Update the state with the new steps array
    setLocalAgendaBoxData({
      ...localAgendaBoxData,
      steps: updatedSteps,
    });
  } else {
    window.alert("error");
  }

  setShowAddResourceLink(!showAddResourceLink);
};



  return boxData && (
    <>
      <Modal isOpen={showAddResourceLink} toggle={handleAddResourceLinkClick} size="sm">
        <ModalHeader toggle={handleAddResourceLinkClick}></ModalHeader>
        <ModalBody>
          <div>
            Input your link.
            <Input
              type="text"
              onChange={(e) => handleResourceLinkChange(e.target.value)}
              placeholder={"Your link here..."}
              required={true}
              defaultValue={resourceLink}
              valid={isResourceLinkValid}
              invalid={!isResourceLinkValid}
              prefix="http://"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!isResourceLinkValid}
            onClick={() => handleResourceChangeSubmit()}
          >
            Add Link
          </Button>
        </ModalFooter>
      </Modal>

    <Modal isOpen={copyBoxModal} toggle={toggleCopyBoxModal}>
      <ModalBody>
        <h5>Copy box to:</h5>
        {user && user.classes && user.classes.map(x => 
          <FormGroup check>
            <Label check>
            <Input
              type="checkbox"
              defaultChecked={classesSelectedForContentBoxCopy.length > 0 && classesSelectedForContentBoxCopy.includes(x.classId)}
              onChange={() => handleCheckboxChange(x.classId)}
            />                
            <span className="form-check-sign" />
            {x.className}
            </Label>
          </FormGroup>
        )}
      </ModalBody>
      <ModalFooter>
        <Button disabled={classesSelectedForContentBoxCopy.length < 1} onClick={() => copyBoxToClasses()}>Copy</Button>
        <Button onClick={toggleCopyBoxModal}>Cancel</Button>
      </ModalFooter>
    </Modal>

    { !isMobile ? (
    <Col style={{ flex: "1", minHeight: "0", paddingLeft: "6px", paddingRight: '6px', height: '100%' }}>
    {/* <NotificationAlert className="content-box-save-alert" ref={notificationAlert} zIndex={9999} style={{width: '100px'}}/> */}
        {alert}
        <Card 
          key={content?.contentBoxId}
          style={{ height: location.pathname === '/print' ? '800px' : cardHeight.card, backgroundImage: location.pathname !== '/print' && content?.background || '', zoom: location.pathname === '/print' ? '.6' : '1', border: printActive === true ? '4px solid blue' : location.pathname === '/print' ? 'solid 1px' : 'none'}}
          onMouseEnter={() => content.steps.length !== 0 && setShowMainEditButton(!showMainEditButton)}
          onMouseLeave={() => content.steps.length !== 0 && setShowMainEditButton(!showMainEditButton)}
        >
          {showMainEditButton && location.pathname !== '/add-edit-classes' && location.pathname !== '/print' && !location.pathname.includes('/share/') && !readOnly && (
            <>
              <Button
                // className="btn-round btn-icon"
                id="options"
                type="button"
                style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(-60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '7px 11px', zIndex: '8888', overflow: 'visible'}}
                size="sm"
                onClick={() => setShowButtons(!showButtons)}
              >
                <i className={showButtons === false ? "fa fa-caret-down" : "fa fa-caret-up"} />
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="options"
                  placement="left"
                >
                    Options
                </UncontrolledTooltip>
                {showButtons && location.pathname !== '/add-edit-classes' && (
                  <>
                    <Button
                      className={content.steps.length === 0 ? "empty-agenda-box-alert" : null}
                      id="editAgendaForm"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '9999', overflow: 'visible'}}
                      size="sm"
                      onClick={() => setShowEditAgendaForm(!showEditAgendaForm)}
                    >
                      <i className="fa fa-pencil" />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="editAgendaForm"
                        placement="left"
                      >
                          Edit Agenda
                      </UncontrolledTooltip>
                    <Button
                    // className="btn-round btn-icon"
                    id="changeContentBoxBackground"
                    type="button"
                    style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(180%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 8px', zIndex: '9999'}}
                    size="sm"
                    onClick={() => toggleBackgroundContentBoxModal()}
                    >
                      <i class="nc-icon nc-palette"></i>             
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="changeContentBoxBackground"
                        placement="left"
                      >
                          Change Background
                      </UncontrolledTooltip>
                    <Button
                      // className="btn-round btn-icon"
                      id="agendaBoxBigger"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(300%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '9999'}}
                      size="sm"
                      onClick={() => zoomContentBox('up')}
                    >
                      <i class="fa fa-plus"></i>             
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="agendaBoxBigger"
                        placement="left"
                      >
                        Bigger
                      </UncontrolledTooltip>
                    <Button
                      // className="btn-round btn-icon"
                      id="agendaBoxSmaller"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(420%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '9999'}}
                      size="sm"
                      onClick={() => zoomContentBox('down')}
                    >
                      <i class="fa fa-minus"></i>             
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="agendaBoxSmaller"
                        placement="left"
                      >
                        Smaller
                      </UncontrolledTooltip>
                      {user.settings?.useActiveDates ? (
                        <>
                        <Button
                        // className="btn-round btn-icon"
                        id="setContentBoxActiveDates"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(540%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '9999'}}
                        size="sm"
                        onClick={() => toggleSetActiveDatesModal()}
                        // onClick={null}
                        >
                          <i className="fa fa-calendar" />
                        </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="setContentBoxActiveDates"
                            placement="left"
                          >
                              Set Active Dates
                          </UncontrolledTooltip>
                        </>
                      ) :
                      (
                        <>
                        <Button
                        // className="btn-round btn-icon"
                        id="sendBoxToStorage"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(540%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '9999'}}
                        size="sm"
                        onClick={() => sendBoxToStorage()}
                        // onClick={null}
                        >
                          <i className="nc-icon nc-box" />
                        </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="sendBoxToStorage"
                            placement="left"
                          >
                              Send Box to Storage
                          </UncontrolledTooltip>
                        </>
                      )}
                    <Button
                      // className="btn-round btn-icon"
                      id="copyBox"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(660%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '9999'}}
                      size="sm"
                      onClick={() => {setBoxToCopyId(content.contentBoxId); toggleCopyBoxModal()}}
                      >
                        <i className="fa fa-copy" />
                      </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="copyBox"
                          placement="left"
                        >
                            Duplicate Box
                        </UncontrolledTooltip>

                    <Button
                      // className="btn-round btn-icon"
                      id="saveContentBoxAsTemplate"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(780%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 8px', zIndex: '9999'}}
                      size="sm"
                      onClick={() => addTemplateAlert(content)}
                    >
                      <img
                          src={stampIcon}
                          alt="stamp"
                          style={{ height: '13px'}}
                      />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="saveContentBoxAsTemplate"
                        placement="left"
                      >
                          Save as Template
                      </UncontrolledTooltip>
                    {/* <Button
                    // className="btn-round btn-icon"
                    id="saveContentBoxAsTemplate"
                    type="button"
                    style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(420%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 8px', zIndex: '9999'}}
                    size="sm"
                    onClick={() => addTemplateAlert(content)}
                    >
                      <i className="fa fa-copy" />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="saveContentBoxAsTemplate"
                        placement="left"
                      >
                          Save as Template
                      </UncontrolledTooltip> */}
                    <Button
                    // className="btn-round btn-icon"
                    id="deleteContentBox"
                    type="button"
                    style={{ backgroundColor: 'lightcoral', color: 'white', position: 'absolute', transform: 'translateY(900%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '9999'}}
                    size="sm"
                    onClick={() => warningWithConfirmAndCancelMessage(content.contentBoxId)}
                    >
                      <i className="fa fa-trash" />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="deleteContentBox"
                        placement="left"
                      >
                          Delete
                      </UncontrolledTooltip>
                  </>
                  )}
            </>
          )}


          <>
            <CardHeader>
              <h1 style={{marginBottom: '0px', fontFamily: boxData.titleFont, color: location.pathname !== '/print' ? boxData.titleColor : null, zoom: boxData.zoom || '1.1'}}>
                {boxData.heading}
              </h1>
            </CardHeader>
            <CardBody style={{paddingTop: '0px', overflowY: 'auto'}}>
              <div style={{ minHeight: cardHeight.editor, overflowY: 'auto', zoom: boxData.zoom || '1'}}>
                <ListGroup flush>
                { boxData && boxData.steps.map((step, index) => (
                    <>
                      <ListGroupItem
                        key={index}
                        active={step.active}
                        style={{borderRadius: step.active && '8px', backgroundImage: !step.active && location.pathname !== '/print' ? content?.background : null || ''}}
                      >
                        <Row>
                          <Col style={{display: "flex"}}>
                            {step.resourceLink ? (
                              <>
                                <div 
                                  style={{marginBottom: '0px', 
                                  fontWeight: step.active ? 'bold' : null, 
                                  fontFamily: boxData.stepFont, 
                                  cursor: 'pointer',
                                  fontSize: '36px',
                                  color: location.pathname === '/print' ? 'black' : boxData.stepColor !== null ? boxData.stepColor : 'black',
                                  }}
                                  onClick={() => handleStartFromStep(index)}
                                >
                                {step.title}
                                </div>
                                <a 
                                  href={step.resourceLink} 
                                  target="_blank" 
                                  style={{
                                    color: location.pathname === '/print' ? 'black' : boxData.stepColor !== null ? boxData.stepColor || 'black' : 'black',
                                    textDecoration: 'none' // Optional, to remove underline from the link
                                  }}
                                >
                                  <i
                                    className="fa fa-link"
                                    style={{margin: '12px', fontSize: '20px', cursor: 'pointer'}}
                                  />
                                </a>
                                {' '}
                                <i className={step.completed ? "fa fa-check" : null} />
                              </>
                            )
                            :
                            (
                              <>
                                <div 
                                  style={{marginBottom: '0px', 
                                  fontWeight: step.active ? 'bold' : null, 
                                  fontFamily: boxData.stepFont, 
                                  cursor: 'pointer',
                                  fontSize: '36px',
                                  color: location.pathname === '/print' ? 'black' : boxData.stepColor !== null ? boxData.stepColor : 'black',
                                  }}
                                  onClick={() => handleStartFromStep(index)}
                                >
                                {step.title}
                                </div>
                                {' '}
                                <i className={step.completed ? "fa fa-check" : null} />
                              </>                            )
                            }
                          </Col>
                        {step.active && (
                          <Col>
                            <h4 style={{marginTop: '4px', marginBottom: '0px', textAlign: 'right'}}>{convertSecondsToMinutes((step.minutes*60+step.seconds)-step.timeElapsed)}</h4>
                          </Col>
                        )}
                        </Row>
                        {step.active && (
                          <Progress
                          animated
                          striped
                          value={step.timeElapsed}
                          style={{height: '40px'}}
                          max={step.minutes*60 + step.seconds}
                        >
                        </Progress>
                        )}
                        {step.subTaskSteps && step.subTaskSteps.map((subTaskStep, subTaskStepIndex) => (
                          <ListGroup flush>
                          <ListGroupItem 
                            disabled={step.completed}
                            active={subTaskStep.active}
                            style={{borderRadius: subTaskStep.active && '8px', backgroundImage: !subTaskStep.active && location.pathname !== '/print' ? content?.background : null || '', paddingTop: '6px', paddingBottom: '6px', backgroundColor: step.active ? '#007bff' : null}}
                          >
                            <Row>
                              <Col style={{display: "flex"}}>
                                {subTaskStep.resourceLink ? (
                                  <>
                                    <div 
                                      style={{marginBottom: '0px', 
                                      fontWeight: subTaskStep.active ? 'bold' : null, 
                                      fontFamily: boxData.subTaskStepFont, 
                                      cursor: 'pointer',
                                      fontSize: '28px',
                                      color: location.pathname === '/print' ? 'black' : boxData.subTaskStepColor !== null ? boxData.subTaskStepColor : 'black',
                                      }}
                                      onClick={() => handleStartFromStep(subTaskStepIndex)}
                                    >
                                    {subTaskStep.title}
                                    </div>
                                    <a 
                                      href={subTaskStep.resourceLink} 
                                      target="_blank" 
                                      style={{
                                        color: location.pathname === '/print' ? 'black' : boxData.subTaskStepColor !== null ? boxData.subTaskStepColor || 'black' : 'black',
                                        textDecoration: 'none' // Optional, to remove underline from the link
                                      }}
                                    >
                                      <i
                                        className="fa fa-link"
                                        style={{margin: '12px', fontSize: '16px', cursor: 'pointer'}}
                                      />
                                    </a>
                                    {' '}
                                    <i className={subTaskStep.completed ? "fa fa-check" : null} />
                                  </>
                                )
                                :
                                (
                                  <>
                                    <div 
                                      style={{marginBottom: '0px', 
                                      fontWeight: subTaskStep.active ? 'bold' : null, 
                                      fontFamily: boxData.stepFont, 
                                      cursor: 'pointer',
                                      fontSize: '28px',
                                      color: location.pathname === '/print' ? 'black' : boxData.subTaskStepColor !== null ? boxData.subTaskStepColor : 'black',
                                      }}
                                      onClick={() => handleStartFromStep(index)}
                                    >
                                    {subTaskStep.title}
                                    </div>
                                    {' '}
                                    <i className={subTaskStep.completed ? "fa fa-check" : null} />
                                  </>
                                )
                                }
                              </Col>
                            {subTaskStep.active && (
                              <Col>
                                <h4 style={{marginTop: '4px', marginBottom: '0px', textAlign: 'right'}}>{convertSecondsToMinutes((subTaskStep.minutes*60+subTaskStep.seconds)-subTaskStep.timeElapsed)}</h4>
                              </Col>
                            )}
                            </Row>
                            {subTaskStep.active && (
                              <Progress
                                animated
                                striped
                                value={subTaskStep.timeElapsed}
                                style={{height: '20px'}}
                                max={subTaskStep.minutes*60 + subTaskStep.seconds}
                              />
                            )}
                          </ListGroupItem>
                          </ListGroup>
                        ))}
                      </ListGroupItem>
                    </>
                  )
                )}
                </ListGroup>
              </div>
                {location.pathname !== '/print' && (
                  <>
                  <Button
                  // className="btn-round btn-icon"
                  id="manualPause"
                  type="button"
                  style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', left: '8px', bottom: '0px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', overflow: 'visible', zIndex: '9999'}}
                  size="sm"
                  onClick={() => handleStartFromStep(0)}
                >
                  <i className={isPaused ? "fa fa-play" : "fa fa-stop"} />
                </Button>
                  <UncontrolledTooltip
                    delay={0}
                    target="manualPause"
                    placement="left"
                  >
                      Manual Start
                  </UncontrolledTooltip>
                  </>
                )}
            </CardBody>
          </>
        </Card>
        <Modal 
            isOpen={activeDatesModal}
            toggle={toggleSetActiveDatesModal}
            scrollable
            style={{padding: '20px'}}
          >
            <ModalHeader toggle={toggleSetActiveDatesModal}>Set Active Dates</ModalHeader>
            <br></br>
            <ModalBody>
              <Container>
                <Row xs="2">
                  <FormGroup>
                    <Label for="startDate">
                      Start Date
                    </Label>
                    <Input
                        id="startDate"
                        name="startDate"
                        type="date"
                        onChange={handleActiveDateChange}
                        // defaultValue={"2024-02-02"}
                        value={activeDates.startDate || today}
                    >
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="endDate">
                      End Date
                    </Label>
                    <Input
                        id="endDate"
                        name="endDate"
                        type="date"
                        onChange={handleActiveDateChange}
                        value={activeDates.endDate || today}
                    >
                    </Input>
                  </FormGroup>
                </Row>
                <Row>
                  <a href="https://lessonlaunchpad.com/faq#active-dates">What are Active Dates?</a>
                </Row>
              </Container>
            </ModalBody>
            <Button onClick={() => saveContentBoxActiveDates(content.contentBoxId)}>Save Active Dates</Button>
          </Modal>
          <Modal 
            isOpen={backgroundContentBoxModal}
            toggle={toggleBackgroundContentBoxModal}
            backdrop={false}
            scrollable
          >
            <ModalHeader toggle={toggleBackgroundContentBoxModal} />
            <ModalBody style={{padding: '0'}}>
              <Container>
                <Row xs="6">
                  {backgroundOptionsData.map((option, index) => (
                    <Col key={index} onClick={() => saveBackgroundContentBox(option.image)} style={{ padding: '0px' }}>
                      <div className="image-container">
                        <img src={option.thumbnail} alt={`Background option ${index + 1}`} />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Modal
            className="agenda-edit-modal"
            isOpen={showEditAgendaForm}
            toggle={toggleSetShowEditAgendaFormModal}
            backdrop={true}
            scrollable
          >
            <ModalHeader toggle={toggleSetShowEditAgendaFormModal}>Agenda Editor</ModalHeader>
            <div style={{margin: '20px', overflowY: 'auto'}}             
              key={localAgendaBoxData.version} // Use the version to force re-render
            >
              <Row>
                <Col md="8">
                  <FormGroup>
                    <Label>Title</Label>
                    <Input
                      defaultValue={localAgendaBoxData.heading}
                      placeholder="Ex: Agenda"
                      onChange={(e) => handleAgendaTitleChange(e)}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Start Time</Label>
                    <ReactDatetime
                      dateFormat={false}
                      // timeFormat={"h:mm:a"}
                      // value={localAgendaBoxData?.startTime}
                      initialValue={localAgendaBoxData?.startTime}
                      inputProps={{
                        className: "form-control",
                        placeholder: "Time Picker Here"
                      }}
                      onChange={(e) => handleAgendaStartTimeChange(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <FormGroup>
                    <Label>Title Font</Label>
                    <FontStylePicker 
                      id={"titleFont"}
                      onChangeFunction={handleAgendaFontChange}
                      selectedFont={localAgendaBoxData.titleFont}
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label>Title Font Color</Label>
                    <Input
                      type="color"
                      placeholder=""
                      id="titleColor"
                      style={{height: '39px'}}
                      onChange={(e) => handleAgendaFontChange(e)}
                      value={localAgendaBoxData.titleColor}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label>Step Font</Label>
                    <FontStylePicker
                      id={"stepFont"}
                      onChangeFunction={handleAgendaFontChange}
                      selectedFont={localAgendaBoxData.stepFont}
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label>Step Font Color</Label>
                    <Input
                      type="color"
                      placeholder=""
                      id="stepColor"
                      style={{height: '39px'}}
                      onChange={(e) => handleAgendaFontChange(e)}
                      value={localAgendaBoxData.stepColor}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label>Sub-step Font</Label>
                    <FontStylePicker
                      id={"subTaskStepFont"}
                      onChangeFunction={handleAgendaFontChange}
                      selectedFont={localAgendaBoxData.subTaskStepFont}
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label>Sub-step Font Color</Label>
                    <Input
                      type="color"
                      placeholder=""
                      id="subTaskStepColor"
                      style={{height: '39px'}}
                      onChange={(e) => handleAgendaFontChange(e)}
                      value={localAgendaBoxData.subTaskStepColor}
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>
              {localAgendaBoxData && localAgendaBoxData.steps?.map((step, index) => (
                <AgendaStep
                  key={index}
                  step={step}
                  stepsLength={localAgendaBoxData.steps?.length}
                  index={index}
                  deleteAgendaStep={deleteAgendaStep}
                  handleStepTextChange={handleStepTextChange}
                  handleStepDurationChange={handleStepDurationChange}
                  addAgendaSubTaskStep={addAgendaSubTaskStep}
                  deleteAgendaSubTaskStep={deleteAgendaSubTaskStep}
                  handleSubTaskStepTextChange={handleSubTaskStepTextChange}
                  handleSubTaskStepDurationChange={handleSubTaskStepDurationChange}
                  handleStepMove={handleStepMove}
                  handleSubTaskStepMove={handleSubTaskStepMove}
                  handleAddResourceLinkClick={handleAddResourceLinkClick}
                />
              ))}
              <hr></hr>
              <Row>
                <Col md="3" style={{left: '100px'}}>
                  <p onClick={() => addAgendaStep()} style={{textDecoration: 'underline', cursor: 'pointer'}}><i className="fa fa-plus" />{' '}Add Step</p>
                </Col>
                <Col md="9">
                </Col>
              </Row>
              <Button
                // className="btn-round btn-icon"
                id="submitAgenda"
                type="button"
                size="sm"
                onClick={() => saveAgendaBoxData()}
              >
              Save Agenda
              </Button>
            </div>
          </Modal>


        </Col>

    ) : (

      <Col style={{zoom: '1' }}>
        <Card 
          key={content.contentBoxId}
          style={{ height: '95vh'}}
        >
          <CardBody>
            <div id="editable" style={{ overflowY: 'auto' }}>
 
            </div>
          </CardBody>
        </Card>
        </Col>

    )}
    </>
  );
}

export default AgendaBox;