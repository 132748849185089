import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input
} from 'reactstrap';

function FontAlignPicker({id, onChangeFunction, selectedAlign, fromEditorToolbar}) {

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const alignList = [
        {
            icon: <i className="fa fa-align-left"></i>,
            align: 'left',
            name: "Left"
        },
        {
            icon: <i className="fa fa-align-center"></i>,
            align: 'center',
            name: "Center"
        },
        {
            icon: <i className="fa fa-align-right"></i>,
            align: 'right',
            name: "Right"
        }
    ];
      
    const toggle = () => setDropdownOpen((prevState) => !prevState); 

    return (
        <Dropdown className="line-height-picker" isOpen={dropdownOpen} toggle={toggle} direction='down'>
            <DropdownToggle caret >{selectedAlign === 'left' ? alignList[0].icon : selectedAlign === 'center' ? alignList[1].icon : alignList[2].icon}</DropdownToggle>
            <DropdownMenu className="font-align-picker-menu">
                {alignList.map((align, index) => (
                    <DropdownItem key={index} id={align.align} value={align.align} onClick={(e) => onChangeFunction(e)}>
                        {align.icon}{' '}{align.name}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    )
}

export default FontAlignPicker;