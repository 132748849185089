import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';
import macbook7 from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/macbook-wide7.svg";
import macbook9 from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/macbook-wide9.svg";
import macbook10 from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/macbook-wide10.svg";
import macbook11 from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/macbook-wide11.svg";
import macbook12 from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/macbook-wide12.svg";
import macbook13 from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/macbook-wide13.svg";


const items = [
  {
    src: macbook11,
    altText: 'Slide 1',
    key: 1,
    className: 'carousel-img'
  },
  {
    src: macbook9,
    altText: 'Slide 2',
    key: 2,
    className: 'carousel-img'
  },
  {
    src: macbook10,
    altText: 'Slide 3',
    key: 3,
    className: 'carousel-img'
  },
  {
    src: macbook7,
    altText: 'Slide 4',
    key: 4,
    className: 'carousel-img'
  },
  {
    src: macbook12,
    altText: 'Slide 5',
    key: 5,
    className: 'carousel-img'
  },
  {
    src: macbook13,
    altText: 'Slide 6',
    key: 6,
    className: 'carousel-img'
  },
];

function LandingCarousel(args) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
        className='landing-page-macbook'
        slide={false}
      >
        <img src={item.src} alt={item.altText} className={item.className}/>
        <CarouselCaption
          captionText={item.caption}
          captionHeader={item.caption}
        />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      fade={false}
      ride='carousel'
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
}

export default LandingCarousel;