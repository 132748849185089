import React, { useState } from "react";
import ReactGoogleSlides from "react-google-slides";

function GoogleSlidesWrapper({ url, width, height, containerStyle, autoplay, slideDuration, loop, position }) {
  const [error, setError] = useState(false);

  const handleSlidesError = () => {
    setError(true);
  };

  return (
    <>
      {error ? (
        // Render an error message when an error occurs
        <div>Failed to load Google Slides presentation or invalid URL.</div>
      ) : (
        // Render the ReactGoogleSlides component with error handling
        <ReactGoogleSlides
          key={height}
          width={width}
          height={height}
          slidesLink={url}
          position={position}
          containerStyle={containerStyle}
          onError={handleSlidesError}
          loop={autoplay ? loop : null}
          slideDuration={autoplay ? slideDuration : null}
          // showControls
        />
      )}
    </>
  );
}

export default GoogleSlidesWrapper;
