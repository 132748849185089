import React, { useState } from 'react'
import { Row, Card, CardBody, Input, Col, CardFooter} from "reactstrap";
import TipTapEditor from "./TipTapEditor";

export default function Whiteboard({props}) { 

    const [whiteboardContent, setWhiteboardContent] = useState(localStorage.getItem('whiteboardContent-' + props.current) || "")

    const [editorActive, setEditorActive] = useState(true)

  return (    
      <>
      {!editorActive ? (
        <Row onClick={() => setEditorActive(!editorActive)} style={{ cursor: 'pointer', justifyContent: 'center', alignItems: 'flex-end', position: 'absolute', bottom: '0', width: '100%' }}>
        Click Here for Text Editor
        </Row>
      ):(
        <>
          {/* <TipTapEditor setEditedContent={null} initialValue={null} handleOnBlur={null} readOnly={null}/> */}
        </>

      )}
    </> 
  )
}
