import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import logo from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/lesson-launchpad.svg";
import ReactBSAlert from "react-bootstrap-sweetalert";

function ShowRegisterPay() {
    const [alert, setAlert] = useState(null);

    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailError, setEmailError] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");

    const handleEmailChange = (event) => {
        const value = event.target.value;
        setEmail(value);
    
        // Perform email validation here and set emailError accordingly
        // For example, check if the email is in a valid format
        if (!isValidEmail(value)) {
          setEmailError("Please enter a valid email address.");
        } else {
          setEmailError("");
        }
    };
    
    const handleFirstNameChange = (event) => {
        const value = event.target.value;
        setFirstName(value);
    
        // Perform validation for first name, and update firstNameError
    };
    
    const handleLastNameChange = (event) => {
        const value = event.target.value;
        setLastName(value);
    
        // Perform validation for last name, and update lastNameError
    };
    
      // Add a validation function for email
    const isValidEmail = (email) => {
        // Regular expression pattern for a valid email address
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        
        // Test the email against the pattern
        return emailPattern.test(email);
    };

    useEffect(() => {
        // Load the Mailchimp script and initialize variables
        const script = document.createElement("script");
        script.src = "//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js";
        script.async = true;
        document.body.appendChild(script);
    
        // Initialize Mailchimp variables
        window.fnames = new Array();
        window.ftypes = new Array();
        fnames[0] = 'EMAIL';
        ftypes[0] = 'email';
        fnames[1] = 'FNAME';
        ftypes[1] = 'text';
        fnames[2] = 'LNAME';
        ftypes[2] = 'text';
        fnames[3] = 'ADDRESS';
        ftypes[3] = 'address';
        fnames[4] = 'PHONE';
        ftypes[4] = 'phone';
        fnames[5] = 'BIRTHDAY';
        ftypes[5] = 'birthday';
    }, []);

    const isMobile = /Mobi/.test(navigator.userAgent);

    const handleFormSubmit = () => {
        // Wait for 2 seconds before executing the rest of the code
        setTimeout(() => {
          // Check if the document contains "Thank you for subscribing!"
          const documentContainsMessage = document.body.textContent.includes("Thank you for subscribing!");
      
          if (documentContainsMessage) {
            // Form submission was successful
            successAlert();
            setTimeout(() => {
              location.reload(); // Refresh the page after 2 seconds
            }, 2000);
          } else {
            // Handle other cases or errors
            console.log("Form submission failed");
          }
        }, 2000); // 2-second delay
      };

    const successAlert = () => {
        setAlert(
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Thanks! Check your email!"
            confirmBtnBsStyle="info"
            btnSize=""
          >
          </ReactBSAlert>
        );
      };

    return(
        <>
        { !isMobile ? (
        <div style={{ height: '100vh', backgroundColor: '#f0f0f0' }}>
            {alert}
            <Row style={{ height: '100%' }}>
                <Col md="6" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div>
                        <img src={logo} alt="lesson-launchpad-logo" style={{ width: '500px', height: 'auto' }}/>
                    </div>
                </Col>
                <Col md="6">
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginLeft: '20%', marginRight: '20%' }}>
                    <div id="mc_embed_shell">
                            <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css"/>
                        <div id="mc_embed_signup" style={{background:'#fff', clear: 'left', font: '14px Helvetica,Arial,sans-serif', borderRadius: '8px'}}>
                            <form action="https://gmail.us21.list-manage.com/subscribe/post?u=d6aaa500bb03c2821bd199093&amp;id=e19ccc0b10&amp;f_id=0022dde6f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
                                <br></br>
                                <div id="mc_embed_signup_scroll">
                                    <div class="mc-field-group"><label for="mce-FNAME">First Name <span class="asterisk">*</span></label><input type="text" name="FNAME" class="required text" id="mce-FNAME" required="" value={firstName} onChange={handleFirstNameChange}/></div><div class="mc-field-group"><label for="mce-LNAME">Last Name <span class="asterisk">*</span></label><input type="text" name="LNAME" class="required text" id="mce-LNAME" required="" value={lastName} onChange={handleLastNameChange}/></div><div class="mc-field-group"><label for="mce-EMAIL">Email Address <span class="asterisk">*</span></label><input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value={email} onChange={handleEmailChange}/></div>
                                <div hidden=""><input type="hidden" name="tags" value="2988638"/></div>
                                <div id="mce-responses" class="clear foot">
                                    <div class="response" id="mce-error-response" style={{display: 'none'}}></div>
                                    <div class="response" id="mce-success-response" style={{display: 'none'}}></div>
                                </div>
                                <div aria-hidden="true" style={{position: 'absolute', left: '-5000px'}}>
                                    /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
                                    <input type="text" name="b_d6aaa500bb03c2821bd199093_e19ccc0b10" tabindex="-1" value=""/>
                                </div>
                                    <div class="optionalParent">
                                        <div class="clear foot">
                                            <input type="submit" onClick={handleFormSubmit} name="subscribe" id="mc-embedded-subscribe" class="button" value="Submit"/>
                                            <p style={{margin: '0px auto'}}><a href="http://eepurl.com/iKJwdM" title="Mailchimp - email marketing made easy and fun"><span style={{display: 'inline-block', backgroundColor: 'transparent', borderRadius: '4px'}}><img class="refferal_badge" src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg" alt="Intuit Mailchimp" style={{width: '220px', height: '40px', display: 'flex', padding: '2px 0px', justifyContent: 'center', alignItems: 'center'}}/></span></a></p>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    </div>
                </Col>
            </Row>
        </div>
        ) : (
        <div style={{ height: '100vh', backgroundColor: '#f0f0f0' }}>
                <Row style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <div>
                        <img src={logo} alt="lesson-launchpad-logo" style={{ width: '1000px', height: 'auto' }}/>
                    </div>
                </Row>
                <Row >
                    <div style={{justifyContent: 'center', alignItems: 'center', width: '90%', marginLeft: '5%', marginRight: '5%' }}>
                    <div id="mc_embed_shell">
                            <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css"/>
                        <div id="mc_embed_signup" style={{background:'#fff', clear: 'left', font: '70px Helvetica,Arial,sans-serif', borderRadius: '30px'}}>
                            <form action="https://gmail.us21.list-manage.com/subscribe/post?u=d6aaa500bb03c2821bd199093&amp;id=e19ccc0b10&amp;f_id=0022dde6f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" onSubmit={handleFormSubmit}>
                                <br></br>
                                <div id="mc_embed_signup_scroll" style={{marginLeft: '3%'}}>
                                    <div class="mc-field-group"><label for="mce-FNAME">First Name <span class="asterisk">*</span></label><input type="text" name="FNAME" class="required text" id="mce-FNAME" required="" value={firstName} onChange={handleFirstNameChange}/></div><div class="mc-field-group"><label for="mce-LNAME">Last Name <span class="asterisk">*</span></label><input type="text" name="LNAME" class="required text" id="mce-LNAME" required="" value={lastName} onChange={handleLastNameChange}/></div><div class="mc-field-group"><label for="mce-EMAIL">Email Address <span class="asterisk">*</span></label><input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value={email} onChange={handleEmailChange}/></div>
                                <div hidden=""><input type="hidden" name="tags" value="2988638"/></div>
                                <div id="mce-responses" class="clear foot">
                                    <div class="response" id="mce-error-response" style={{display: 'none', fontSize: '50px'}}></div>
                                    <div class="response" id="mce-success-response" style={{display: 'none', fontSize: '50px'}}></div>
                                </div>
                                <div aria-hidden="true" style={{position: 'absolute', left: '-5000px'}}>
                                    /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
                                    <input type="text" name="b_d6aaa500bb03c2821bd199093_e19ccc0b10" tabindex="-1" value=""/>
                                </div>
                                    <div class="optionalParent">
                                        <div class="clear foot">
                                            <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Submit" style={{ fontSize: '60px', height: '120px', borderRadius: '30px', backgroundColor: 'rgb(11, 192, 223)'}}/>
                                            <p style={{margin: '0px auto'}}><a href="http://eepurl.com/iKJwdM" title="Mailchimp - email marketing made easy and fun"><span style={{display: 'inline-block', backgroundColor: 'transparent', borderRadius: '4px'}}><img class="refferal_badge" src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg" alt="Intuit Mailchimp" style={{width: '220px', height: '40px', display: 'flex', padding: '2px 0px', justifyContent: 'center', alignItems: 'center'}}/></span></a></p>
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                            </form>
                        </div>
                    </div>
                    </div>
                </Row>
        </div>
        )
        }
    </>
    )
}

export default ShowRegisterPay;

