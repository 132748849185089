import React from 'react';
import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer, NodeViewWrapper } from '@tiptap/react';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';

const ResizableYouTube = ({ node, updateAttributes }) => {
  const { src, width, height } = node.attrs;

  const handleResizeStop = (event, { size }) => {
    updateAttributes({ width: size.width, height: size.height });
  };

  return (
    <NodeViewWrapper style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <ResizableBox
        width={width}
        height={height}
        lockAspectRatio={false}
        onResizeStop={handleResizeStop}
        
      >
        <iframe
          width="100%"
          height="100%"
          src={src}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </ResizableBox>
    </NodeViewWrapper>
  );
};

const ResizableYouTubeNode = Node.create({
  name: 'resizableYouTube',

  group: 'block',

  atom: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      width: {
        default: 400,
      },
      height: {
        default: 300,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-type="resizable-youtube"]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { 'data-type': 'resizable-youtube' })];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ResizableYouTube);
  },
});

export default ResizableYouTubeNode;
