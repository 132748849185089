import React, { useState } from 'react';
import {
  Col,
  Row,
  Card,
  CardBody,
  Input,
  FormGroup,
  Form,
  CardFooter,
  Button
} from 'reactstrap';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  // Netlify form handling - https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formData })
    })
      .then(() => alert("Success!"))
      .catch((error) => alert(error));
  };

  return (
    <Row style={{display: 'flex', justifyContent: 'center', marginTop: '10vh', marginBottom: '10vh'}}>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%' }}>
      <h2>Contact Lesson Launchpad</h2>
    </div>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%' }}>
      <div style={{ flex: 1 }}>
                <li>Need help with Lesson Launchpad?</li>
                <li>Have an idea you would like to see?</li>
                <li>Need to report a bug?</li>
                <br></br>
                <p>We'd love to hear from you.</p>
                <p>Fill out the contact form and we'll get back to you soon.</p>
                <br></br>
                <h3>Find us on Social Media</h3>
                    {/* <Button color="twitter">
                      <i className="fa fa-twitter" />
                      Connect with Twitter
                    </Button> */}
                    <a href="https://www.facebook.com/lessonlauchpad" target="_blank">
                    <Button color="facebook">
                        <i className="fa fa-facebook-square" />
                        Share on Facebook
                    </Button>
                    </a>
            </div>
            <div style={{ flex: 1, width: '20%'}}>
                <Card>
                {/* <CardHeader>
                    <CardTitle tag="h4">Contact Us</CardTitle>
                </CardHeader> */}
                <CardBody>
                    <Form action="#" method="#" netlify={true}>
                    {/* //This is for Netlify form handling */}
                    <input type="hidden" name="form-name" value="contact" />
                    {/* //This is for Netlify form handling */}
                    <label>Name (required)</label>
                    <FormGroup>
          <Input
            placeholder="Enter name"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Input
            placeholder="Enter email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Input
            placeholder="Enter subject"
            type="text"
            name="subject"
            value={formData.subject}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Input
            placeholder="Enter message"
            type="textarea"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
          />
        </FormGroup>
      </Form>
    </CardBody>
    <CardFooter>
      <Button
        className="btn-round"
        color="info"
        type="submit"
        onClick={handleSubmit}
      >
        Send
      </Button>
    </CardFooter>
                </Card>
            </div>
    </div>
    </Row>
  )
}

export default Contact