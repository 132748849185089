import React from 'react'
import { selectUser } from "features/userSlice";
import { useSelector } from "react-redux";
import UseAutoCountdown from "AutoCountdown";
import UseAutoReminder from "AutoReminder";
import UseAutoNavigate from "AutoNavigate";
import { Card, CardBody, Row, Col, CardHeader } from "reactstrap";

const AutoFeaturesStatus = () => {

    const user = useSelector(selectUser);

    const daysOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

    const week = user.settings.currentWeek === "A" ? 0 : user.settings.currentWeek === "B" ? 1 : user.settings.currentWeek === "C" ? 2 : user.settings.currentWeek === "D" ? 3 : null;
    const dayForDayOfTheWeek = new Date().getDay()
    const dayOfTheWeek = dayForDayOfTheWeek === 1 ? 0 : dayForDayOfTheWeek === 2 ? 1 : dayForDayOfTheWeek === 3 ? 2 : dayForDayOfTheWeek === 4 ? 3 : dayForDayOfTheWeek === 5 ? 4 : dayForDayOfTheWeek === 6 ? 5 : dayForDayOfTheWeek === 7 ? 6 : null;

    function convertTo12HourFormat(time24) {
        // Split the time into hours and minutes
        const [hours, minutes] = time24.split(':').map(Number);
      
        // Determine whether it's AM or PM
        const period = hours >= 12 ? 'PM' : 'AM';
      
        // Convert hours to 12-hour format
        const hours12 = hours % 12 || 12;
      
        // Format the time as "hh:mm AM/PM"
        const time12 = `${hours12}:${String(minutes).padStart(2, '0')} ${period}`;
      
        return time12;
      }

    return (
        <>
            <div className="content">
                {user.settings.alteredSchedule ? <h3>*** You have Altered Schedule mode turned on. This means none of your automations will be active. ***</h3> : null}
                <Row>
                    <Col>
                        <UseAutoNavigate />
                        <UseAutoCountdown />
                    </Col>
                    <Col>
                        <UseAutoReminder />
                        <Card>
                            <CardHeader className='h3 mb-0'>User Settings</CardHeader>
                            <CardBody>
                            <b>Altered Schedule:</b> {user.settings.alteredSchedule ? 'On' : 'Off'}{'    <- If "On", all automation features will be turned off.'}
                            <br></br>
                            <b>Multi-Week Schedule:</b> {user.settings.multiWeek ? 'Yes' : 'No'}
                            <br></br>
                            <b>How many different weeks does your schedule contain?</b> {user.settings.numberOfWeeks}
                            <br></br>
                            <b>Current Week:</b> {user.settings.currentWeek}
                            <br></br>
                            <b>Do you need different times for the same class?</b> {user.settings.differentTimes ? 'Yes' : 'No'}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <hr></hr>

                <br></br>
                <h1>Classes</h1>
                {user.classes && user.classes.map(classMap => (
                    <Card>
                        <CardBody>
                        <h3 style={{marginBottom: '10px'}}>{classMap.className}</h3>
                        <br></br>
                        <b>Active:</b>{' '}<span style={{color: classMap.active ? 'green' : 'red'}}>{classMap.active ? 'True' : 'False'}</span>
                        <br></br>
                        {Object.keys(classMap.schedule).sort().slice(0, user.settings.numberOfWeeks).map((level, levelIndex) => (
                            <>
                            <h4><b>Week {level}</b></h4>
                            <div key={level} style={{display: 'flex'}}>
                            {daysOrder.map((day, dayIndex) => (
                                <div key={day} style={{paddingLeft: '20px', paddingRight: '20px', borderRadius: '10px', border: levelIndex === week && dayIndex === dayOfTheWeek ? '2px solid green' : null}}>
                                <h5><b>{day}</b></h5>
                                <p> <b>Start Time:</b>{' '}{convertTo12HourFormat(classMap.schedule[level][day].startTime)}</p>
                                <p> <b>End Time:</b>{' '}{convertTo12HourFormat(classMap.schedule[level][day].endTime)}</p>
                                <p> <b>Active:</b>{' '}<span style={{color: classMap.schedule[level][day].active ? 'green' : 'red'}}>{classMap.schedule[level][day].active ? 'True' : 'False'}</span></p>
                                </div>
                            ))}
                            </div>
                            </>
                        ))}
                        </CardBody>
                    </Card>
                ))}
                <hr></hr>
            </div>
        </>
    )
}

export default AutoFeaturesStatus;
