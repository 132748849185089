import React, { useState, useEffect, useRef } from "react";
import { selectUser } from "features/userSlice";
import { Card, CardBody, Col, CardFooter, Button, Modal, ModalBody, ModalHeader, ModalFooter, Container, Row, FormGroup, Label, Input, UncontrolledTooltip} from "reactstrap";
import { getStorage, ref, listAll, getDownloadURL, uploadBytes, deleteObject } from "firebase/storage";
import { useSelector } from "react-redux";
import featurePdfBox from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-pdf-box.svg";
import ReactBSAlert from "react-bootstrap-sweetalert";

export default function FilePicker({toggleFilePickerModal, fileType, isOpen, onOpenClick}) {

    const user = useSelector(selectUser);
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const storage = getStorage();
    const fileInputRef = useRef(null);

    // useEffect(() => {
    //     // List of valid image extensions
    //     const validImageExtensions = fileType === 'images' ? ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'] : fileType === 'pdf' ? ['pdf'] : [''];   

    //     // Create a reference under which you want to list
    //     const listRef = ref(storage, `users/${user.uid}`);

    //     // Find all the prefixes and items.
    //     listAll(listRef)
    //         .then((res) => {
    //             res.prefixes.forEach((folderRef) => {
    //                 // All the prefixes under listRef.
    //                 // You may call listAll() recursively on them.
    //             });
    //             res.items.forEach((itemRef) => {
    //                 // All the items under listRef.
    //                 const extension = itemRef.name.split('.').pop().toLowerCase();
    //                 if (validImageExtensions.includes(extension)) {
    //                     getDownloadURL(itemRef).then((url) => {
    //                         const itemRefMap = { name: itemRef.name, path: url };
    //                         setFiles(prev => [...prev, itemRefMap]);
    //                     });
    //                 }
    //             });
    //         }).catch((error) => {
    //             console.log(error);
    //         });
    // }, [user]);

    const [alert, setAlert] = useState(null);
    
    const deleteFileAlert = (file) => {
        setAlert(
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => deleteFile(file)}
            onCancel={() => cancelFileDelete()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You will not be able to recover this file.
          </ReactBSAlert>
        );
    };
    
    const successFileDelete = () => {
        setAlert(
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Deleted!"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            btnSize=""
          >
            Your file has been deleted.
          </ReactBSAlert>
        );
    };
    
    const cancelFileDelete = () => {
        setAlert(
            <ReactBSAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title="Cancelled"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            btnSize=""
            >
            Your template is safe :)
            </ReactBSAlert>
        );
    };

    const hideAlert = () => {
        setAlert(null);
    };

    const deleteFile = async (file) => {
        const fileRef = ref(storage, `users/${user.uid}/${file.name}`);
    
        // Delete the file from Firebase storage
        deleteObject(fileRef)
            .then(() => {
                // File deleted successfully
                successFileDelete();
    
                // Remove the file from the state
                setFiles(prev => prev.filter(f => f.name !== file.name));
            })
            .catch((error) => {
                window.alert("Error occurred: " + error);
            });
    };

    useEffect(() => {
        // List of valid image extensions
        const validImageExtensions = fileType === 'images' ? ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'] : fileType === 'pdf' ? ['pdf'] : [''];   
    
        // Create a reference under which you want to list
        const listRef = ref(storage, `users/${user.uid}`);
    
        // Find all the prefixes and items.
        listAll(listRef)
            .then((res) => {
                res.prefixes.forEach((folderRef) => {
                    // All the prefixes under listRef.
                    // You may call listAll() recursively on them.
                });
                res.items.forEach((itemRef) => {
                    // All the items under listRef.
                    const extension = itemRef.name.split('.').pop().toLowerCase();
                    if (validImageExtensions.includes(extension)) {
                        getDownloadURL(itemRef).then((url) => {
                            const itemRefMap = { name: itemRef.name, path: url };
                            
                            // Check if the file already exists in the state
                            setFiles(prev => {
                                const isDuplicate = prev.some(file => file.name === itemRefMap.name);
                                if (!isDuplicate) {
                                    return [...prev, itemRefMap];
                                }
                                return prev;
                            });
                        });
                    }
                });
            }).catch((error) => {
                console.log(error);
            });
    }, [user, successFileDelete]);

    const truncateFileName = (name, maxLength = 17) => {
        if (name.length <= maxLength) return name;
        const start = name.substring(0, 6);
        const end = name.substring(name.length - 8, name.length);
        return `${start}...${end}`;
    };

    const handleFileClick = (file) => {
        setSelectedFile(file);
    };

    const handleUploadImageButtonClick = () => {
        fileInputRef.current.click();
    };

    const allowedTypes = fileType === 'images' ? 
        ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'] : 
        fileType === 'pdf' ? 
        ['application/pdf'] : 
        [''];    
    const maxSize = 10 * 1024 * 1024; // 10MB

    const handleUploadImageFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
        if (!allowedTypes.includes(file.type)) {
            window.alert('Please select a valid image file (JPEG, PNG, GIF, BMP, WEBP).');
            return;
        }

        if (file.size > maxSize) {
            window.alert('File size exceeds the 10MB limit.');
            return;
        }

        writeImageFileToFirebase(file);
        }
    };

    const writeImageFileToFirebase = async (imageFile) => {
        if (imageFile == null) return;
        const imageRef = ref(storage, `users/${user.uid}/${imageFile.name}`);
        uploadBytes(imageRef, imageFile).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setFiles(prev => [...prev, {name: imageFile.name, path: url}]);
            });
        });
    };

    return (
        <Modal isOpen={isOpen} scrollable toggle={toggleFilePickerModal}>
        {alert}
            <ModalHeader></ModalHeader>
            <ModalBody style={{padding: "10px 15px"}}>
            <Row style={{ height: '50vh', margin: '15px' }}>
                {files.length > 0 ? files.map((file, index) => 
                    <div 
                        key={index} 
                        className={`file-container ${selectedFile === file ? 'selected' : ''}`} 
                        onClick={() => handleFileClick(file)}
                    >
                        <Button
                            // className="btn-round btn-icon"
                            id="deleteFile"
                            type="button"
                            style={{ backgroundColor: 'lightcoral', color: 'white', position: 'absolute', transform: 'translateY(-60%) translateX(130%)', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', overflow: 'visible', zIndex: '5000'}}
                            size="sm"
                            onClick={() => deleteFileAlert(file)}
                        >
                            <i className="fa fa-trash" style={{fontSize: '15px'}}/>
                        </Button>
                        <div className="file-image">
                            {fileType === "images" ? <img src={file.path} alt={file.name} />
                                                   : <img src={featurePdfBox} alt={file.name} />
                            }
                        </div>
                        <div className="file-name">{truncateFileName(file.name)}</div>
                    </div>
                ) :
                    <div>No Files...</div>
                }
            </Row>
            </ModalBody>
            <ModalFooter>
                <Row style={{ width: '100%' }}>
                    <Col className="text-left">
                        <Button onClick={handleUploadImageButtonClick}>Upload <i className="fa fa-upload"></i></Button>
                    </Col>
                    <Col className="text-right">
                        <Button onClick={toggleFilePickerModal}>Cancel</Button>
                        <Button disabled={!selectedFile} onClick={() => onOpenClick(selectedFile.path)}>Open</Button>
                    </Col>
                </Row>
                <input
                    type="file"
                    accept={fileType === 'images' ? ".jpg,.jpeg,.png,.gif" : fileType === 'pdf' ? '.pdf' : ''}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleUploadImageFileChange}
                />
            </ModalFooter>
        </Modal>
    );
}
