import React, { useEffect, useState } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { Label, FormGroup, FormFeedback, Badge, Card, CardHeader, CardBody, Container, Input, CardTitle, Row, Col, UncontrolledTooltip, ButtonDropdown, Button, ButtonGroup, DropdownToggle, DropdownMenu, DropdownItem, Spinner, Dropdown, Modal, ModalBody, ModalHeader } from "reactstrap";
import AgendaBox from './AgendaBox';

export default function PanelsTest() {



  return (
    <>
      <div className="content" style={{marginTop: '0px' }}>
      <PanelGroup direction="horizontal">
        <Panel defaultSize={30} minSize={20}>
          <AgendaBox />
        </Panel>
        <PanelResizeHandle />
        <Panel>
          <PanelGroup direction="vertical">
            <Panel defaultSize={30} minSize={20}>
              <Card style={{margin: '10px', height: '100%'}}>
              middle
              </Card>
            </Panel>
            <PanelResizeHandle />
            <Panel defaultSize={30} minSize={20}>
              <Card style={{margin: '10px', height: '100%'}}>
              right
              </Card>
            </Panel>
          </PanelGroup>
        </Panel>
      </PanelGroup>
      </div>
    </>
  );
}

<>
      <div className="content" style={{marginTop: '0px' }}>
      <PanelGroup direction="horizontal">
        <Panel defaultSize={30} minSize={20}>
          <Card style={{margin: '10px', height: '500px'}}>
          left
          </Card>
        </Panel>
        <PanelResizeHandle />
        <Panel defaultSize={30} minSize={20}>
          <Card style={{margin: '10px', height: '500px'}}>
          middle
          </Card>
        </Panel>
        <PanelResizeHandle />
        <Panel defaultSize={30} minSize={20}>
          <Card style={{margin: '10px', height: '500px'}}>
          right
          </Card>
        </Panel>
      </PanelGroup>
      </div>
    </>