
import React, { useState, useEffect, useRef } from "react";
import { Editor } from '@tinymce/tinymce-react';
import { auth, db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { setDoc, doc } from "firebase/firestore";
import NotificationAlert from "react-notification-alert";
import backgroundOptionsData from "/Users/michaeltucker/Documents/coding-projects/client/src/BackgroundOptionsData.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { saveContentBoxTemplate } from "saveTemplate";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, CardFooter, Button, Modal, ModalBody, ModalHeader, ModalFooter, Container, Row, FormGroup, Label, Input, UncontrolledTooltip, FormFeedback} from "reactstrap";
import canvaShare from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/canva-share-link.gif";
import { createRandomId } from "RandomId";
import FilePicker from './FilePicker';
import stampIcon from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/stamp-solid.svg";

function PdfBox({content, setHeading, updateContentBox, classId, user, navbarMD, view, addContentBox, multiBox, updateContentBoxWithinMultiBox, printActive, readOnly}) {
  const [editedContent, setEditedContent] = useState();
  const [cardHeight, setCardHeight] = useState({card: "auto", editor: "auto"}); // Initially set the height to 'auto'
  // const [showSave, setShowSave] = useState(false);
  const [headingInsideContentBox, setHeadingInsideContentBox] = useState()
  const [contentPreview, setContentPreview] = useState();
  const [showMainEditButton, setShowMainEditButton] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [alert, setAlert] = useState(null);
  const location = useLocation()
  const date = new Date().toLocaleDateString('en-CA')
  const [boxToCopyId, setBoxToCopyId] = useState()
  const [copyBoxModal, setCopyBoxModal] = useState(false)
  const [classesSelectedForContentBoxCopy, setClassesSelectedForContentBoxCopy] = useState([]);
  const [filePickerModal, setFilePickerModal] = useState(false);

  useEffect(() => {
    // Function to update the card height based on window height
    const updateCardHeight = () => {
      const windowHeight = window.innerHeight;
      const desiredDistance = navbarMD?.clock === '0' && navbarMD?.marquee === '0' ? 93 : 188; // Desired distance from the bottom
      const cardHeight = windowHeight - desiredDistance;
      setCardHeight({card: `${cardHeight}px`, editor: `${cardHeight-25}px`});
    };

    // Call the updateCardHeight function when the window is resized
    window.addEventListener("resize", updateCardHeight);

    // Initial call to set the card height
    updateCardHeight();

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateCardHeight);
    };
  }, [navbarMD]);

  // to stop the warning of calling setState of unmounted component
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  const handleSave = () => {
    if (!multiBox) {
      updateContentBox(content.contentBoxId, {...editedContent})
    } else {

    }
    setEditedContent()
    setShowSave(false)
  }

  const autoSaveContentBox = async (contentBoxId, updatedContent) => {
    try {
      const docRef = doc(db, "users", user.uid);
  
      const userCopy = JSON.parse(JSON.stringify(user));
  
      // Find the class index based on classId
      const classIndex = userCopy.classes.findIndex(
        (c) => c.classId === classId
      );
  
      if (classIndex !== -1) {
        // Find the content box index based on contentBoxId
        const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
          (box) => box.contentBoxId === contentBoxId
        );
  
        if (contentBoxIndex !== -1) {
          // Make a copy of the existing content box using the spread operator
          const updatedContentBox = { ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex] };
          // console.log(updatedContentBox)
  
          // Update the content box with the new data
          updatedContentBox.content = updatedContent.content;
          if (headingInsideContentBox) {
            if (headingInsideContentBox.length > 9) {
              updatedContentBox.heading = headingInsideContentBox.substring(0, 9) + "..."
            } else if (headingInsideContentBox.length === 0) {
              updatedContentBox.heading = "Type here..."
            } else {
              updatedContentBox.heading = headingInsideContentBox
            }
          }

          updatedContentBox.contentPreview = contentPreview?.substring(0, 30) + "..."

          // Create a new Date object
          var currentDate = new Date();

          // Get individual components of the date
          var year = currentDate.getFullYear();
          var month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
          var day = currentDate.getDate();

          // Create a formatted string
          var formattedDate = (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + '/' + year;

          updatedContentBox.lastEdited = formattedDate;
  
          // Update the content box in the document data
          userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;
  
          // Update the document in Firestore
          await setDoc(docRef, userCopy);
          showAlertForContentBoxSave();
        }
      }
    } catch (error) {
      console.error('Error updating document:', error);
      // Check if the error message does not include the specific error you want to exclude
      if (!error.message.includes("Cannot read properties of undefined (reading 'content')")) {
        showAlertForContentBoxSaveFailure();
      }
    }
};

const [activeDatesModal, setActiveDatesModal] = useState(false)

  const toggleSetActiveDatesModal = () => {
    setActiveDatesModal(!activeDatesModal)
  }

  const [activeDates, setActiveDates] = useState({
    startDate: content.startDate === undefined ? "" : content.startDate,
    endDate: content.endDate === undefined ? "" : content.endDate
  });

  const handleActiveDateChange = (event) => {
    const { name, value } = event.target;
    setActiveDates(prevDates => ({
      ...prevDates,
      [name]: value
    }));
  };

  const saveContentBoxActiveDates = async (contentBoxId) => {
    try {
      const docRef = doc(db, "users", user.uid);
  
      const userCopy = JSON.parse(JSON.stringify(user));
  
      // Find the class index based on classId
      const classIndex = userCopy.classes.findIndex(
        (c) => c.classId === classId
      );
  
      if (classIndex !== -1) {
        // Find the content box index based on contentBoxId
        const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
          (box) => box.contentBoxId === contentBoxId
        );
  
        if (contentBoxIndex !== -1) {
          // Make a copy of the existing content box using the spread operator
          const updatedContentBox = { ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex]};
  
          // Update the content box with the new data
          updatedContentBox.startDate = activeDates.startDate;
          updatedContentBox.endDate = activeDates.endDate;
  
          // Update the content box in the document data
          userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;
  
          // Update the document in Firestore
          await setDoc(docRef, userCopy);
  
          // Update the local state with the updated document data
          setBoxes(userCopy.classes[classIndex].contentBoxes);
        }
        toggleSetActiveDatesModal();
      }
    } catch (error) {
      console.error('Error updating document:', error);
    }
};

const sendBoxToStorage = async () => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
      (box) => box.contentBoxId === content.contentBoxId
    );

    userCopy.classes[classIndex].contentBoxes[contentBoxIndex].active = false;

    // Update the document in Firestore
    await setDoc(docRef, userCopy);

  } catch (error) {
    console.error('Error updating document:', error);
  }
}

const [backgroundContentBoxModal, setBackgroundContentBoxModal] = useState(false);
const [backgroundContentBoxIdToChange, setBackgroundContentBoxIdToChange] = useState('');

const toggleBackgroundContentBoxModal = (contentBoxId) => {
  setBackgroundContentBoxModal(!backgroundContentBoxModal);
  setBackgroundContentBoxIdToChange(contentBoxId);
};

const saveBackgroundContentBox = async (imageFileName) => {
  const thing = `url(${imageFileName})`
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
      (box) => box.contentBoxId === content.contentBoxId
    );

    userCopy.classes[classIndex].contentBoxes[contentBoxIndex].background = thing;

    // Update the document in Firestore
    await setDoc(docRef, userCopy);

  } catch (error) {
    console.error('Error updating document:', error);
  }
}

const notificationAlert = useRef();

function showAlertForContentBoxSave() {
  var options = {};
  options = {
    place: 'tc',
    message: (
      <div>
        <div>
          <b>Content Box Saved!</b>
        </div>
      </div>
    ),
    type: 'info',
    icon: "now-ui-icons ui-1_bell-53",
    autoDismiss: 3,
    closeButton: false,
  };
  notificationAlert.current.notificationAlert(options);
}

function showAlertForContentBoxSaveFailure() {
  var options = {};
  options = {
    place: 'tc',
    message: (
      <div>
        <div>
          <b>Content Box NOT Saved!</b>
        </div>
      </div>
    ),
    type: 'danger',
    icon: "now-ui-icons ui-1_bell-53",
    autoDismiss: 3,
    closeButton: false,
  };
  notificationAlert.current.notificationAlert(options);
}

const editBoxNameAlert = (contentBoxId) => {
  setAlert(
    <ReactBSAlert
      input
      showCancel
      style={{ display: "block", marginTop: "-100px" }}
      title="What would you like to name this box?"
      onConfirm={(e) => updateContentBoxHeading(contentBoxId, e)}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      btnSize=""
      defaultValue={content.heading}
    />
  );
};

const updateContentBoxHeading = async (contentBoxId, updatedHeading) => {
  hideAlert()
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    // Find the class index based on classId
    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    if (classIndex !== -1) {
      // Find the content box index based on contentBoxId
      const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
        (box) => box.contentBoxId === contentBoxId
      );

      if (contentBoxIndex !== -1) {
        // Make a copy of the existing content box using the spread operator
        const updatedContentBox = { ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex], userHeading: true };

        // Update the content box with the new data
        if (updatedHeading.length > 9) {
          updatedContentBox.heading = updatedHeading.substring(0, 9) + "..."
        } else if (updatedHeading.length === 0) {
          updatedContentBox.heading = "Type here..."
        } else {
          updatedContentBox.heading = updatedHeading
        }

        // Update the content box in the document data
        userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;

        // Update the document in Firestore
        await setDoc(docRef, userCopy); // Replace with the correct path to your document

        // Update the local state with the updated document data
        setBoxes(userCopy.classes[classIndex].contentBoxes);

        successAlert()
      }
    }
  } catch (error) {
    console.error('Error updating document:', error);
  }
};

const warningWithConfirmAndCancelMessage = (id) => {
  setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title="Are you sure?"
      onConfirm={() => deleteContentBox(id)}
      onCancel={() => cancelDelete()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText="Yes, delete it!"
      cancelBtnText="Cancel"
      showCancel
      btnSize=""
    >
      You will not be able to recover this content box!
    </ReactBSAlert>
  );
};

const successDelete = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px" }}
      title="Deleted!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box has been deleted.
    </ReactBSAlert>
  );
};

const cancelDelete = () => {
  setAlert(
    <ReactBSAlert
      danger
      style={{ display: "block", marginTop: "-100px" }}
      title="Cancelled"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box is safe :)
    </ReactBSAlert>
  );
};

const deleteContentBox = async (contentBoxId) => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
      (box) => box.contentBoxId === contentBoxId
    );

    // Remove the content box at the specified index
    userCopy.classes[classIndex].contentBoxes.splice(contentBoxIndex, 1);

    // Update the document in Firestore
    await setDoc(docRef, userCopy);

    // // Update the local state with the updated document data
    // setStateContent(userCopy);

    successDelete()

    console.log('Content box deleted successfully!');
  } catch (error) {
    console.error('Error deleting content box:', error);
  }
}


const addTemplateAlert = (contentBox) => {
  setAlert(
    <ReactBSAlert
      input
      showCancel
      style={{ display: "block", marginTop: "-100px" }}
      title="What would you like to call this template?"
      onConfirm={(e) => addTemplateConfirmAlert(e, contentBox)}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      btnSize=""
      defaultValue={contentBox.heading}
    />
  );
};

const addTemplateConfirmAlert = (e, contentBox) => {
  saveContentBoxTemplate(user, e, contentBox);
  setAlert(
    <ReactBSAlert
      style={{ display: "block", marginTop: "-100px" }}
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
      title={
        <p>
          You entered: <b>{e}</b>
        </p>
      }
    />
  );
};
  
const successAlert = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px" }}
      title="Saved!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
    </ReactBSAlert>
  );
};

const hideAlert = () => {
  setAlert(null);
};

  const [showCanvaBoxAlert, setShowCanvaBoxAlert] = useState(false);
  const [canvaLink, setCanvaLink] = useState();
  const [isCanvaLinkValid, setIsCanvaLinkValid] = useState(true);

  const handleCanvaLinkChange = (value) => {
    const isValidInput = /^https:\/\/www\.canva\.com\/design\/[^\/]+\/[^\/]+\/view$/.test(value);
    const appendedValue = value + "?embed"
    setIsCanvaLinkValid(isValidInput);
    setCanvaLink(appendedValue);
  }

  const toggleCanvaBoxAlert = () => {
    setShowCanvaBoxAlert(prev => !prev)
    setCanvaLink("");
  }

  const saveFirestoreUrlForPdfBox = (firestoreLink) => {
    if (!multiBox) {
      updateContentBox(
        content.contentBoxId, 
        {...content, content: firestoreLink}
      )
    } else {
      updateContentBoxWithinMultiBox({
        ...content,
        content: firestoreLink,
      })
    }
    toggleFilePickerModal()
  }

  const addCanvaBoxConfirmAlert = (e) => {
    if (/^https:\/\/www\.canva\.com\/design\/[^\/]+\/[^\/]+\/view$/.test(canvaLink.replace(/\?embed$/, ''))) {
      if (!multiBox) {
        updateContentBox(
          content.contentBoxId, 
          {...content, content: canvaLink}
        )
      } else {
        updateContentBoxWithinMultiBox({
          ...content,
          content: canvaLink
        })
      }
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Canva Box Updated!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
        </ReactBSAlert>
      );
      toggleCanvaBoxAlert()
    } else {
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Invalid Canva Link!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          <p><b>You entered:</b> {canvaLink.replace(/\?embed$/, '')}</p>
          <br></br>
          <p>A valid URL will start with 'https://www.canva.com/design' and end with '/view'.</p>
        </ReactBSAlert>
      );
    }
  };

const isMobile = false // /Mobi/.test(navigator.userAgent);

const today = new Date().toISOString().slice(0,10)

const toggleCopyBoxModal = () => {
  setCopyBoxModal(!copyBoxModal)
  setClassesSelectedForContentBoxCopy([])
}

const handleCheckboxChange = (classId) => {
  setClassesSelectedForContentBoxCopy(prev => {
    if (prev.includes(classId)) {
      // If classId is already selected, remove it
      return prev.filter(id => id !== classId);
    } else {
      // If classId is not selected, add it
      return [...prev, classId];
    }
  });
};

const copyBoxToClasses = async () => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    // Find the class index based on classId
    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    let boxToCopy = userCopy.classes[classIndex].contentBoxes.find(box => box.contentBoxId === boxToCopyId);
    boxToCopy = { ...boxToCopy, contentBoxId: createRandomId() };

    let classesCopy = [...userCopy.classes];

    classesSelectedForContentBoxCopy.forEach(classId => {
      const classIndex = classesCopy.findIndex(c => c.classId === classId);

      if (classIndex !== -1) {
        // Copy the contentBoxes array and add the new box
        const updatedContentBoxes = [...classesCopy[classIndex].contentBoxes, boxToCopy];
        classesCopy[classIndex] = {
          ...classesCopy[classIndex],
          contentBoxes: updatedContentBoxes
        };
      } else {
        console.log(`Class with ID ${classId} not found.`);
      }
    });

    userCopy.classes = classesCopy;

    await setDoc(docRef, userCopy);

    toggleCopyBoxModal()

  } catch (error) {
    console.log("failed", error);
  }
};

const toggleFilePickerModal = () => {
  setFilePickerModal(!filePickerModal);
};

  return (
    <>
    {showCanvaBoxAlert && (
          <ReactBSAlert
          showCancel
          title="Add Canva Box"
          style={{ display: "block", marginTop: "-100px" }}
          onConfirm={(e) => addCanvaBoxConfirmAlert(e)}
          onCancel={() => toggleCanvaBoxAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          btnSize=""
          validationRegex={/^https:\/\/www\.canva\.com\/design\/[^\/]+\/[^\/]+\/view$/}
          validationMsg={"Invalid URL. A valid URL will start with 'https://www.canva.com/design' and end with '/view'."}
        >
        <img src={canvaShare} alt="canva-share-gif" style={{ width: '400px', height: 'auto', marginBottom: '20px', border: '2px solid black' }}/>
        <div>
          <hr />
          <br></br>
          <div>
            Input the smart embed link from your Canva Project:
            <ol>
              <li>In your Canva project, click Share.</li>
              <li>Click More.</li>
              <li>Click Embed.</li>
              <li>Copy the smart embed link.</li>
            </ol>
            <Input
              type="text"
              onChange={(e) => handleCanvaLinkChange(e.target.value)}
              placeholder={"Your Canva embed link here..."}
              required={true}
              valid={isCanvaLinkValid}
              invalid={!isCanvaLinkValid}
            />
            <FormFeedback type="invalid">
              {/* Validation error message */}
              {isCanvaLinkValid ? null : 'Invalid input!'}
            </FormFeedback>
          </div>
        </div>
        </ReactBSAlert>
    )}

    <Modal isOpen={copyBoxModal} toggle={toggleCopyBoxModal}>
      <ModalBody>
        <h5>Copy box to:</h5>
        {user && user.classes && user.classes.map(x => 
          <FormGroup check>
            <Label check>
            <Input
              type="checkbox"
              defaultChecked={classesSelectedForContentBoxCopy.length > 0 && classesSelectedForContentBoxCopy.includes(x.classId)}
              onChange={() => handleCheckboxChange(x.classId)}
            />                
            <span className="form-check-sign" />
            {x.className}
            </Label>
          </FormGroup>
        )}
      </ModalBody>
      <ModalFooter>
        <Button disabled={classesSelectedForContentBoxCopy.length < 1} onClick={() => copyBoxToClasses()}>Copy</Button>
        <Button onClick={toggleCopyBoxModal}>Cancel</Button>
      </ModalFooter>
    </Modal>

    <Col style={{ flex: "1", minHeight: "0", paddingLeft: "6px", paddingRight: '6px', height: '100%' }}>
      {alert}
        <Card 
          key={content.contentBoxId}
          style={{ height: multiBox ? '100%' : cardHeight.card, backgroundImage: content.background || '', zoom: location.pathname === '/print' ? '.6' : '1', border: printActive === true ? '4px solid blue' : location.pathname === '/print' ? 'solid 1px' : 'none'}}
          onMouseEnter={() => setShowMainEditButton(!showMainEditButton)}
          onMouseLeave={() => setShowMainEditButton(!showMainEditButton)}
        >
          {showMainEditButton && location.pathname !== '/add-edit-classes' && !multiBox && location.pathname !== '/print' && !location.pathname.includes('/share/') && !readOnly && (
            <>
              <Button
                // className="btn-round btn-icon"
                id="options"
                type="button"
                style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(-60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '7px 11px', zIndex: '9999', overflow: 'visible'}}
                size="sm"
                onClick={() => setShowButtons(!showButtons)}
              >
                <i className={showButtons === false ? "fa fa-caret-down" : "fa fa-caret-up"} />
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="options"
                  placement="left"
                >
                    Options
                </UncontrolledTooltip>
                {showButtons && location.pathname !== '/add-edit-classes' && (
                  <>
                    <Button
                      // className="btn-round btn-icon"
                      id="editContentBoxHeading"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '9999', overflow: 'visible'}}
                      size="sm"
                      onClick={() => editBoxNameAlert(content.contentBoxId)}
                    >
                      <i className="fa fa-pencil" />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="editContentBoxHeading"
                        placement="left"
                      >
                          Edit Heading
                      </UncontrolledTooltip>
                      <Button
                      // className="btn-round btn-icon"
                      id="changePdfFile"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(180%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 9px', zIndex: '9999', overflow: 'visible'}}
                      size="sm"
                      onClick={() => toggleFilePickerModal()}
                    >
                      <i className="fa fa-folder" />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="changePdfFile"
                        placement="left"
                      >
                          Change PDF File
                      </UncontrolledTooltip>
                    <Button
                    // className="btn-round btn-icon"
                    id="changeContentBoxBackground"
                    type="button"
                    style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(300%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 8px', zIndex: '9999'}}
                    size="sm"
                    onClick={() => toggleBackgroundContentBoxModal()}
                    >
                      <i class="nc-icon nc-palette"></i>             
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="changeContentBoxBackground"
                        placement="left"
                      >
                          Change Background
                      </UncontrolledTooltip>
                      {user.settings.useActiveDates ? (
                        <>
                        <Button
                        // className="btn-round btn-icon"
                        id="setContentBoxActiveDates"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(420%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '9999'}}
                        size="sm"
                        onClick={() => toggleSetActiveDatesModal()}
                        // onClick={null}
                        >
                          <i className="fa fa-calendar" />
                        </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="setContentBoxActiveDates"
                            placement="left"
                          >
                              Set Active Dates
                          </UncontrolledTooltip>
                        </>
                      ) :
                      (
                        <>
                        <Button
                        // className="btn-round btn-icon"
                        id="sendBoxToStorage"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(420%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '9999'}}
                        size="sm"
                        onClick={() => sendBoxToStorage()}
                        // onClick={null}
                        >
                          <i className="nc-icon nc-box" />
                        </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="sendBoxToStorage"
                            placement="left"
                          >
                              Send Box to Storage
                          </UncontrolledTooltip>
                        </>
                      )}
                    <Button
                      // className="btn-round btn-icon"
                      id="copyBox"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(540%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '9999'}}
                      size="sm"
                      onClick={() => {setBoxToCopyId(content.contentBoxId); toggleCopyBoxModal()}}
                      // onClick={null}
                      >
                        <i className="fa fa-copy" />
                      </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="copyBox"
                          placement="left"
                        >
                            Duplicate Box
                        </UncontrolledTooltip>
                    <Button
                      // className="btn-round btn-icon"
                      id="saveContentBoxAsTemplate"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(660%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 8px', zIndex: '9999'}}
                      size="sm"
                      onClick={() => addTemplateAlert(content)}
                    >
                      <img
                          src={stampIcon}
                          alt="stamp"
                          style={{ height: '13px'}}
                      />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="saveContentBoxAsTemplate"
                        placement="left"
                      >
                          Save as Template
                      </UncontrolledTooltip>
                    {/* <Button
                    // className="btn-round btn-icon"
                    id="saveContentBoxAsTemplate"
                    type="button"
                    style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(420%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 8px', zIndex: '9999'}}
                    size="sm"
                    onClick={() => addTemplateAlert(content)}
                    >
                      <i className="fa fa-copy" />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="saveContentBoxAsTemplate"
                        placement="left"
                      >
                          Save as Template
                      </UncontrolledTooltip> */}
                    <Button
                    // className="btn-round btn-icon"
                    id="deleteContentBox"
                    type="button"
                    style={{ backgroundColor: 'lightcoral', color: 'white', position: 'absolute', transform: 'translateY(780%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '9999'}}
                    size="sm"
                    onClick={() => warningWithConfirmAndCancelMessage(content.contentBoxId)}
                    >
                      <i className="fa fa-trash" />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="deleteContentBox"
                        placement="left"
                      >
                          Delete
                      </UncontrolledTooltip>
                  </>
                  )}
            </>
          )}
          <CardBody>
            {content.content !== null ?
              <div style={{ minHeight: '100%', overflowY: 'auto' }}>
                <iframe 
                  loading="lazy"
                  style={{position: "absolute", width: "100%", height: "100%", top: 0, left: 0, border: "none", padding: "10px", margin: "0px"}}
                  src={content.content}
                  allowFullscreen
                  allow="fullscreen">
                </iframe>
              </div>
            :
              <div style={{ height: '100%', overflowY: 'auto', alignContent: 'center', textAlign: 'center' }}>
                {/* <Button
                  onClick={() => setShowCanvaBoxAlert(!showCanvaBoxAlert)}
                >
                  Add PDF Link
                </Button> */}
                <Button
                  onClick={() => toggleFilePickerModal()}
                >
                  Upload PDF File
                </Button>
              </div>
            }
          </CardBody>
        </Card>
        <Modal 
            isOpen={activeDatesModal}
            toggle={toggleSetActiveDatesModal}
            scrollable
            style={{padding: '20px'}}
          >
            <ModalHeader toggle={toggleSetActiveDatesModal}>Set Active Dates</ModalHeader>
            <br></br>
            <ModalBody>
              <Container>
                <Row xs="2">
                  <FormGroup>
                    <Label for="startDate">
                      Start Date
                    </Label>
                    <Input
                        id="startDate"
                        name="startDate"
                        type="date"
                        onChange={handleActiveDateChange}
                        // defaultValue={"2024-02-02"}
                        value={activeDates.startDate || today}
                    >
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="endDate">
                      End Date
                    </Label>
                    <Input
                        id="endDate"
                        name="endDate"
                        type="date"
                        onChange={handleActiveDateChange}
                        value={activeDates.endDate || today}
                    >
                    </Input>
                  </FormGroup>
                </Row>
                <Row>
                  <a href="https://lessonlaunchpad.com/faq#active-dates">What are Active Dates?</a>
                </Row>
              </Container>
            </ModalBody>
            <Button onClick={() => saveContentBoxActiveDates(content.contentBoxId)}>Save Active Dates</Button>
          </Modal>
          <Modal 
            isOpen={backgroundContentBoxModal}
            toggle={toggleBackgroundContentBoxModal}
            backdrop={false}
            scrollable
          >
            <ModalHeader toggle={toggleBackgroundContentBoxModal} />
            <ModalBody style={{padding: '0'}}>
              <Container>
                <Row xs="6">
                  {backgroundOptionsData.map((option, index) => (
                    <Col key={index} onClick={() => saveBackgroundContentBox(option.image)} style={{ padding: '0px' }}>
                      <div className="image-container">
                        <img src={option.thumbnail} alt={`Background option ${index + 1}`} />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <FilePicker toggleFilePickerModal={toggleFilePickerModal} fileType={'pdf'} isOpen={filePickerModal} onOpenClick={saveFirestoreUrlForPdfBox} />
        </Col>
    </>
  );
}

export default PdfBox;
