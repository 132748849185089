import React from "react";
import { Col, Button, Row, FormGroup, Label, Input, UncontrolledTooltip} from "reactstrap";
import AgendaSubStep from "./AgendaSubStep";

export default function AgendaStep({index, step, stepsLength, deleteAgendaStep, handleStepTextChange, handleStepDurationChange, addAgendaSubTaskStep, deleteAgendaSubTaskStep, handleSubTaskStepTextChange, handleSubTaskStepDurationChange, handleStepMove, handleSubTaskStepMove, handleAddResourceLinkClick}) {
  return (
    <>
    <hr></hr>
    <Row key={index} className="m-0">
    <Col md="1" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '15px', paddingLeft: '40px'}}>
      <Button
        className="btn-icon btn-neutral"
        color="secondary"
        id="tooltip159182282"
        size="md"
        type="button"
        style={{margin: '0px', height: '25px'}}
        onClick={() => handleStepMove(index, 'up')}
        disabled={index === 0}
      >
        <i className="nc-icon nc-minimal-up" />
      </Button>
      <Button
        className="btn-icon btn-neutral"
        color="secondary"
        id="tooltip159185983"
        size="md"
        type="button"
        style={{margin: '0px', height: '25px'}}
        onClick={() => handleStepMove(index, 'down')}
        disabled={index === stepsLength-1}
      >
        <i className="nc-icon nc-minimal-down" />
      </Button>
    </Col>
    <Col md="4" style={{paddingRight: '0px'}}>
      <FormGroup>
        <Label>Step Text</Label>
        <Input
          placeholder="Ex: Agenda"
          id="title"
        //   value={step.text}
          defaultValue={step.title}
          onChange={(e) => handleStepTextChange(step.stepId, e)}
        />
      </FormGroup>
    </Col>
    <Col md="1" style={{paddingRight: '0px'}}>
      <FormGroup>
        <Label>Resource</Label>
        <i id="linkForStep" className="fa fa-link" style={{ fontSize: '22px', cursor: 'pointer', margin: '7px' }} onClick={() => handleAddResourceLinkClick(index, null, "step")}></i>
          <UncontrolledTooltip
          delay={0}
          target="linkForStep"
          placement="left"
          >
              (Beta) Add resource link for step.
          </UncontrolledTooltip>
        <i id="resourceUploadForStep" className="fa fa-upload" style={{ fontSize: '22px', color: 'lightgrey', margin: '7px' }} onClick={() => null}></i>
          <UncontrolledTooltip
          delay={0}
          target="resourceUploadForStep"
          placement="left"
        >
              Coming Soon: Upload resource for step.
          </UncontrolledTooltip>
      </FormGroup>
    </Col>
    <Col md="5" style={{display: 'flex'}}>
      <FormGroup style={{flexGrow: '1'}}>
        <Label>Step Duration</Label>
        <div style={{display: 'flex', width: '100%'}}> 
          <div style={{paddingLeft: '2px', paddingRight: '2px', flexGrow: '1'}}>
            <div className="inputWithUnit">
                <Input
                    disabled={step.subTaskSteps.length > 0}
                    min={0}
                    max={60}
                    type="number"
                    id="minutes"
                    placeholder="Minutes"
                    value={step.minutes}
                    onChange={(e) => handleStepDurationChange(step.stepId, e, true)}
                />
                <span>minutes</span>
            </div>
          </div>
          <div style={{paddingLeft: '2px', paddingRight: '2px', flexGrow: '1'}}>
            <div className="inputWithUnit">
                <Input
                    disabled={step.subTaskSteps.length > 0}
                    min={0}
                    max={60}
                    type="number"
                    id="seconds"
                    placeholder="Seconds"
                    value={step.seconds}
                    onChange={(e) => handleStepDurationChange(step.stepId, e, false)}

                />
                <span>seconds</span>
            </div>
          </div>
        </div>
        </FormGroup>
    </Col>
    <Col md="1" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: '0px', justifyContent: 'center'}}>
        <Button
            // className="btn-round btn-icon"
            id="deleteContentBox"
            type="button"
            style={{ backgroundColor: 'lightcoral', color: 'white', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', marginTop: '23px'}}
            size="sm"
            onClick={() => deleteAgendaStep(step.stepId)}
        >
        <i className="fa fa-trash" />
        </Button>
            <UncontrolledTooltip
            delay={0}
            target="deleteContentBox"
            placement="left"
            >
                Delete
            </UncontrolledTooltip>
    </Col>
  </Row>
  {step.subTaskSteps && step.subTaskSteps.length ? step.subTaskSteps.map((subTaskStep, subTaskStepindex) => 
    <AgendaSubStep
      key={subTaskStepindex}
      stepIndex={index}
      subTaskStepIndex={subTaskStepindex}
      subTaskStepArrayLength={step.subTaskSteps.length}
      subTaskStep={subTaskStep}
      deleteAgendaSubTaskStep={deleteAgendaSubTaskStep}
      handleSubTaskStepTextChange={handleSubTaskStepTextChange}
      handleSubTaskStepDurationChange={handleSubTaskStepDurationChange}
      handleSubTaskStepMove={handleSubTaskStepMove}
      handleAddResourceLinkClick={handleAddResourceLinkClick}
    />
  ): null}
  <Row>
    <Col md="4" style={{left: '140px', paddingRight: '0px'}}>
      <p onClick={() => addAgendaSubTaskStep(step.stepId)} style={{textDecoration: 'underline', cursor: 'pointer'}}><i className="fa fa-plus" />{' '}Add Sub-step</p>
    </Col>
    <Col md="8">
    </Col>
  </Row>
  </>
  )
}