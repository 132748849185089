import React, { useState, useEffect } from 'react';

const SpinningWheel = ({ names }) => {
//     const [selectedName, setSelectedName] = useState(null);
//     const [spinning, setSpinning] = useState(false);
//     const [rotation, setRotation] = useState(0);

//     const spinWheel = () => {
//         if (spinning) return;

//         setSpinning(true);

//         // Calculate a random spin duration and rotation
//         const duration = 3000 + Math.random() * 2000; // 3-5 seconds
//         const totalRotation = 360 * 10 + Math.random() * 360; // 10 full spins + random extra rotation

//         setRotation(totalRotation);

//         // Set a timeout to stop the wheel after spinning
//         setTimeout(() => {
//             setSpinning(false);

//             // Calculate the index of the selected name
//             const segmentAngle = 360 / names.length;
//             const selectedIdx = Math.floor((totalRotation % 360) / segmentAngle);

//             setSelectedName(names[selectedIdx]);
//         }, duration);
//     };

//     return (
//         <div>
//             <div className="wheel-container">
//                 <div
//                     className={`wheel ${spinning ? 'spinning' : ''}`}
//                     style={{ transform: `rotate(${rotation}deg)` }}
//                 >
//                     {names.map((name, index) => (
//                         <div
//                             key={index}
//                             className="segment"
//                             style={{ transform: `rotate(${index * (360 / names.length)}deg)` }}
//                         >
//                             {name}
//                         </div>
//                     ))}
//                 </div>
//                 <button onClick={spinWheel} disabled={spinning}>
//                     Spin the Wheel!
//                 </button>
//             </div>
//             {selectedName && <div className="result">Selected Name: {selectedName}</div>}
//         </div>
//     );
// };

    const [selectedName, setSelectedName] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(Math.floor(Math.random() * names?.length) || 0);
    const [spinning, setSpinning] = useState(false);
    const [speed, setSpeed] = useState(100); // Start with fast speed

    const startCarousel = () => {
        if (spinning) return;
    
        setSpinning(true);
        setSelectedName(null);
        setSpeed(100); // Reset speed to fast at the start of each spin
    
        // Randomize the starting index to add more randomness
        let initialIndex = Math.floor(Math.random() * names.length);
        setCurrentIndex(initialIndex);
    
        let duration = 3000 + Math.random() * 2000; // 3-5 seconds
        let elapsedTime = 0;
    
        const spinInterval = setInterval(() => {
            setCurrentIndex(Math.floor(Math.random() * names.length));
            elapsedTime += speed;
            console.log(currentIndex)
    
            if (elapsedTime > duration) {
                clearInterval(spinInterval);
                setSpinning(false);
                setSelectedName(names[currentIndex]);
            }
        }, speed);
    };
    

    return (
        <div className="carousel-picker-container text-center">
            <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                <div className="card-body">
                    <h1 className={selectedName ? "randomizer-flash" : "card-title"} style={{ fontSize: '125px' }}>{selectedName || spinning && names[currentIndex]}</h1>
                </div>
            </div>
            <button
                onClick={startCarousel}
                className="btn btn-primary mt-4"
                disabled={spinning}
            >
                Start
            </button>
        </div>
    );
};
export default SpinningWheel;
