import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input
} from 'reactstrap';

function FontSizePicker({selectedSize, onChangeFunction, fromEditorToolbar, disabled}) {

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const fontList = ['8px', '10px', '12px', '14px', '16px', '20px', '24px', '28px', '32px', '36px', '40px', '48px', '56px', '64px', '72px', '80px', '96px', '112px', '130px'];
      
    const toggle = () => setDropdownOpen((prevState) => !prevState); 

    return (
        <Dropdown disabled={disabled} className={fromEditorToolbar ? "font-style-picker" : "font-size-picker"} isOpen={dropdownOpen} toggle={toggle} direction='down'>
            <DropdownToggle caret disabled={disabled} >{selectedSize}</DropdownToggle>
            <DropdownMenu className="font-size-picker-menu-real">
                {fontList.map((font, index) => (
                    <DropdownItem key={index} id={font} value={font} defaultValue={selectedSize} onClick={(e) => onChangeFunction(e)}>
                        {font}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    )
}

export default FontSizePicker