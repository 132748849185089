import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getStorage } from 'firebase/storage';


const firebaseConfig = {
    apiKey: "AIzaSyAPd2LMtC7vFFEkWCatphSO2FwD1rEJbmg",
    authDomain: "lesson-launchpad.firebaseapp.com",
    databaseURL: "https://lesson-launchpad-default-rtdb.firebaseio.com",
    projectId: "lesson-launchpad",
    storageBucket: "lesson-launchpad.appspot.com",
    messagingSenderId: "403855687421",
    appId: "1:403855687421:web:1904b9fd442d046cde8894"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app)

  export { auth, db, storage};