import React, { useState } from 'react';
import { Card, CardBody, Row, Col, Collapse, Nav, NavLink, TabContent, TabPane, Navbar, NavbarBrand, Carousel, Container, CardImgOverlay, CardText, CardTitle,
    NavItem, UncontrolledAccordion, AccordionItem,
     AccordionBody, Button, Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardHeader } from "reactstrap";
import pictureUploadGIF from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/PictureUpload.gif";
import googleSlidesGIF from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/googleslides.gif";
import saveAsTemplate from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/save-as-template-screenshot.png";

export default function FAQ() {

    const [horizontalTabs, setHorizontalTabs] = React.useState("home");
    const [verticalTabs, setVerticalTabs] = React.useState("info");
    const [pageTabs, setPageTabs] = React.useState("homePages");
    const [openedCollapses, setOpenedCollapses] = React.useState([
      "collapse1",
      "collapse1"
    ]);
    // with this function we create an array with the opened collapses
    // it is like a toggle function for all collapses from this page
    const collapsesToggle = (collapse) => {
      if (openedCollapses.includes(collapse)) {
        setOpenedCollapses(openedCollapses.filter((item) => item !== collapse));
      } else {
        openedCollapses.push(collapse);
        setOpenedCollapses([...openedCollapses, collapse]);
      }
    };

    return (
        <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <div
                    aria-multiselectable={true}
                    className="card-collapse"
                    id="accordion"
                    role="tablist"
                  >
                    <CardTitle tag="h4">Frequently Asked Questions</CardTitle>
                    {/* <Card className="card-plain">
                    <CardHeader role="tab" style={{minHeight: '0px'}}>
                        <a
                          aria-expanded={openedCollapses.includes("collapseEight")}
                          href="#how-do-I-upload-a-picture?"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("collapseEight")}
                        >
                          <b>Where do I start?</b>{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes("collapseEight")}
                      >
                        <CardBody style={{paddingBottom: '5px'}}>
                            Lesson Launchpad has grown in scale over time based on user suggestions, but it's important to know that it can be as simple or complicated as you want it.
                        </CardBody>
                      </Collapse>
                    </Card> */}
                    <Card className="card-plain">
                      <CardHeader role="tab" style={{minHeight: '0px'}}>
                          <a
                            aria-expanded={openedCollapses.includes("collapse1")}
                            href="#where-do-I-start?"
                            data-parent="#accordion"
                            data-toggle="collapse"
                            onClick={() => collapsesToggle("collapse1")}
                          >
                            <b>Where do I start?</b>{" "}
                            <i className="nc-icon nc-minimal-down" />
                          </a>
                        </CardHeader>
                        <Collapse
                          role="tabpanel"
                          isOpen={openedCollapses.includes("collapse1")}
                        >
                          <CardBody style={{paddingBottom: '5px'}}>
                              That's completely up to you, but I would recommend starting by creating one of your classes in the add/edit class table.
                              After that, you can navigate to your newly created class in the present tab. You can add content for your class in the content box that's automatically created when you create a new class.
                              You might add your agenda, a bell-ringer, or your objectives. Or you could add all three of those. It's completely up to you. Lesson Launchpad can be as simple as that if you want it to,
                              or you can extend it's functionality by setting up the built-in automation features like auto-reminders or auto-countdowns.
                              <br></br>
                              <p style={{textAlign: 'center', marginTop: '20px'}}><iframe style={{width: "731px", height: "410px"}} src="https://www.youtube.com/embed/MoCoKv1hw7Q" width="731" height="410" allowfullscreen="allowfullscreen"></iframe></p>
                          </CardBody>
                        </Collapse>
                    </Card>
                    <Card className="card-plain">
                    <CardHeader role="tab" style={{minHeight: '0px'}}>
                        <a
                          aria-expanded={openedCollapses.includes("collapse2")}
                          href="#how-do-I-add-a-class?"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("collapse2")}
                        >
                          <b>How do I add a class?</b>{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes("collapse2")}
                      >
                        <CardBody style={{paddingBottom: '5px'}}>
                            You can add a class from the add/edit classes table which can be found on the add/edit page. Click the "Add Class" button. 
                            The only required fields to add a class are the "class name" and "period" fields. 
                            Entering a start time and end time and setting up your schedule is only necessary if you plan on taking advantage of the auto advance feature.
                            You can always edit the class and add these details later.
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                    <CardHeader role="tab" style={{minHeight: '0px'}}>
                        <a
                          aria-expanded={openedCollapses.includes("collapse3")}
                          href="#how-do-I-add-a-box-to-my-class?"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("collapse3")}
                        >
                          <b>How do I add a content box to my class?</b>{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes("collapse3")}
                      >
                        <CardBody style={{paddingBottom: '5px'}}>
                            This is done through the "Launch" button on the present class screen. From the "Launch" menu you can add quite a few different types of boxes.
                            <ol>
                                <li>
                                    Text Editor Box - this box contains an advanced text editor. Think of it like a word document or google doc.
                                </li>
                                <li>
                                    Agenda Box - this box lets you to create an agenda with custom timings that will countdown the time remaining on each task.
                                </li>
                                <li>
                                    Google Slides Box - the Google Slides Box lets you to add a Google slides presentation to Lesson Launchpad.
                                </li>
                                <li>
                                    Google Doc Box - the Google Doc Box lets you to add a Google doc or Google sheet to Lesson Launchpad.
                                </li>
                                <li>
                                    Canva Box - the Canva Box lets you to add a project from Canva to Lesson Launchpad.
                                </li>
                                <li>
                                    Multi Box - the Multi Box lets you to add two boxes stacked on top of each other.
                                </li>
                                <li>
                                    Timer/Stopwatch Box - this box contains either a timer or stopwatch (or both) that can always be present on your screen.
                                </li>
                                <li>
                                    Countdown Box - this box can be used to count down the days to an event.
                                </li>
                            </ol>
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                        <CardHeader role="tab" style={{minHeight: '0px'}}>
                        <a
                          aria-expanded={openedCollapses.includes(
                            "collapse3.5"
                          )}
                          href="#active-dates"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("collapse3.5")}
                        >
                          <b>What are Active Dates?</b>{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes("collapse3.5")}
                      >
                        <CardBody style={{paddingBottom: '5px'}}>
                          <p>
                            Active Dates allow you to plan your content in advance and have your content automatically show up on your present screen when you need it. 
                            By setting a start date and end date, you're letting Lesson Launchpad know on which date(s) you would like that content to be available on your present screen.
                            With this option turned on you will notice a date picker in the bottom right hand corner of the present screen. It defaults to the current date, but you can
                            change the date and your content will adjust accordingly.
                          </p>
                            <br></br>
                          <p>
                            The use of Active Dates is turned off by default but you can turn it on in your settings.
                          </p>
                          <br></br>
                          <p style={{textAlign: 'center', marginTop: '20px'}}><iframe style={{width: "731px", height: "410px"}} src="https://www.youtube.com/embed/jv0BA6KHQ1w" width="731" height="410" allowfullscreen="allowfullscreen"></iframe></p>
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                        <CardHeader role="tab" style={{minHeight: '0px'}}>
                        <a
                          aria-expanded={openedCollapses.includes(
                            "collapse3.6"
                          )}
                          href="#active-dates"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("collapse3.6")}
                        >
                          <b>My school operates on a _____ schedule. How do I set that up?</b>{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes("collapse3.6")}
                      >
                        <CardBody style={{paddingBottom: '5px'}}>
                          <p>
                            You can setup your schedule in settings. You can choose through multiple different options to fit your needs. 
                            Selecting the "Multi-Week Schedule" option will allow you to pick how many different weeks your schedule operates on. 
                            For example, if you have an A/B schedule, you would turn on "Multi-Week Schedule" then select "2 Weeks" for "How many different weeks does your schedule contain?". 
                            You can choose which week Lesson Launchpad is operating on with the "Current Week" selector. 
                            If you have classes that meet at different times depending on the day, you will need to select "Yes" from the "Do you need different times for the same class?" selector. 
                            This will allow you to set a unique start time for each class. 
                          </p>
                          <br></br>
                          <p>
                            Adjusting these settings will give you different options when creating a class, and auto countdown, or auto reminder.
                          </p>
                          <br></br>
                          <p style={{textAlign: 'center', marginTop: '20px'}}><iframe style={{width: "731px", height: "410px"}} src="https://www.youtube.com/embed/aOt1zrCXrnQ" width="731" height="410" allowfullscreen="allowfullscreen"></iframe></p>
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                    <CardHeader role="tab" style={{minHeight: '0px'}}>
                        <a
                          aria-expanded={openedCollapses.includes("collapse4")}
                          href="#how-do-I-change-my-background?"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("collapse4")}
                        >
                          <b>How do I change my background?</b>{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes("collapse4")}
                      >
                        <CardBody style={{paddingBottom: '5px'}}>
                            You can change your background for each class from the "Launch" button on the present screen.
                            From the "Launch" button menu, select "Change Background". Notice in the box that appears, there are three options at the top - Background, Announcements, Clock.
                            By selecting one those three buttons then picking a background, you can customize the class window background, announcements marquee background, and clock background.
                            You can change the background for each content box from the "Change Background" button that appears when you hover over a content box.
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                    <CardHeader role="tab" style={{minHeight: '0px'}}>
                        <a
                          aria-expanded={openedCollapses.includes("collapse5")}
                          href="#how-do-I-upload-a-picture?"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("collapse5")}
                        >
                          <b>How do I upload a picture?</b>{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes("collapse5")}
                      >
                        <CardBody style={{paddingBottom: '5px'}}>
                          <p>
                            Pictures can be added to a context box by clicking on the image button on the text editor's toolbar.
                          </p>
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                    <CardHeader role="tab" style={{minHeight: '0px'}}>
                        <a
                          aria-expanded={openedCollapses.includes("collapse6")}
                          href="#how-do-I-make-a-template?"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("collapse6")}
                        >
                          <b>How do I create a template?</b>{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes("collapse6")}
                      >
                        <CardBody style={{paddingBottom: '5px'}}>
                          <p>
                            If you setup a text editor content box the same way frequently, you should make it a template. This way, you can easily add it to another day without having to re-setup your formatting. To do this go to any Text Editor content box that you've already created and click on the "Save as Template" button from the dropdown options. It will now appear in the "Your Templates" section of the Add Content Box dialog box.
                          </p>
                          <img src={saveAsTemplate} style={{width: '600px', alignContent: 'center', textAlign: 'center', marginTop: '20px'}}></img>
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                    <CardHeader role="tab" style={{minHeight: '0px'}}>
                        <a
                          aria-expanded={openedCollapses.includes("collapse7")}
                          href="#auto-advance"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("collapse7")}
                        >
                          <b>How do I get my classes to auto advance to the next class at the correct time?</b>{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes("collapse7")}
                      >
                        <CardBody style={{paddingBottom: '5px'}}>
                        This functionality is setup in the Classes Table of the add/edit page. You'll see that you can add a start time to each class. By selecting a start time for your class, you automatically tell Lesson Launchpad you want it to auto-navigate to that class at the selected time. Notice: your class may actually start at 9:30 but the passing period starts 5 minutes before that at 9:25. Whatever time you put as the start time will be the time that your screen automatically changes so if you want the screen to change as soon as the passing period starts, you will want to set that class's start time as 9:25. If you need additional scheduling options, you can find them in the settings tab.
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                    <CardHeader role="tab" style={{minHeight: '0px'}}>
                        <a
                          aria-expanded={openedCollapses.includes(
                            "collapse8"
                          )}
                          href="#auto-coundown-reminders"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("collapse8")}
                        >
                          <b>Why aren't my Countdown Timers and/or Reminders going off?</b>{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes("collapse8")}
                      >
                        <CardBody style={{paddingBottom: '5px'}}>
                         <ol>
                             <li>
                             Countdown Timers and Reminders will only trigger from the Present screen. You must be on a class's present screen for them to work. It doesn't matter which class you're on as they aren't tied to a specific class. They will not trigger if you're on the add/edit screen or the settings screen for example.
                             </li>
                             <li>
                             Do you have them set to active? If the active box is not checked, they will not be added to the queue.
                             </li>
                             <li>
                             Do you have your schedule setup correctly? If you've selected the multi-week schedule option in settings please confirm that you have selected the correct week.
                             </li>
                             <li>
                                Do you accidentally have "Altered Schedule Mode" turned on in your settings? Altered Schedule Mode is designed to be used on days when your school is using a different schedule and you want to easily turn off all of your normal automations. Therefore, if you have this mode turned on, none of your automations will be active.
                             </li>
                         </ol>
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                    <CardHeader role="tab" style={{minHeight: '0px'}}>
                        <a
                          aria-expanded={openedCollapses.includes(
                            "collapse9"
                          )}
                          href="#google-slides"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("collapse9")}
                        >
                          <b>How do I add a Google Slides presentation?</b>{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes("collapse9")}
                      >
                        <CardBody style={{paddingBottom: '5px'}}>
                          <p>
                            First, open your Google Slides presentation in Google. You will need the share link from your Google Slides presentation. Make sure you get the shareable link by clicking the "Share" button, not the URL from the address bar. Also, make sure that you have changed the access type to "Anyone with the link". Copy that link and head back to Lesson Launchpad. Navigate to the present screen of the class you want to add the presentation to and click the "Launch" button. Select "Add Google Slides" from the Launch button menu. Follow the steps in the dialog box.
                          </p>
                          <img src={googleSlidesGIF} style={{width: '500px', alignContent: 'center', textAlign: 'center', marginTop: '20px'}}></img>
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                    <CardHeader role="tab" style={{minHeight: '0px'}}>
                        <a
                          aria-expanded={openedCollapses.includes("collapse9.1")}
                          href="#google-slides"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("collapse9.1")}
                        >
                          <b>How can I share my Lesson Launchpad content with others?</b>{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes("collapse9.1")}
                      >
                        <CardBody style={{paddingBottom: '5px'}}>
                          <p>
                            In settings, you'll see a toggle labeled "Share Lesson Content". With this setting toggled to "On" a read-only version of your Lesson Launchpad present screen
                            will be available at the URL listed. You can then share this link with students, parents, or whoever else you want to see what you're working on in class.
                          </p>
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                    <CardHeader role="tab" style={{minHeight: '0px'}}>
                        <a
                          aria-expanded={openedCollapses.includes(
                            "collapse10"
                          )}
                          href="#hyperlink"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("collapse10")}
                        >
                          <b>How do I open a hyperlink I placed in a content box?</b>{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes("collapse10")}
                      >
                        <CardBody style={{paddingBottom: '5px'}}>
                        The default behavior of clicking anywhere in a context box is to open the editor. To open the link, you can either right click on the link and then select "Open Link" from the options OR you can Ctrl+Click on Windows or Command+Click on Mac to open the link.
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                    <CardHeader role="tab" style={{minHeight: '0px'}}>
                        <a
                          aria-expanded={openedCollapses.includes(
                            "collapse11"
                          )}
                          href="#add-roster"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("collapse11")}
                        >
                          <b>The Randomizer says "No Roster Data".</b>{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes("collapse11")}
                      >
                        <CardBody style={{paddingBottom: '5px'}}>
                         The Randomizer uses the roster data from the class you are currently presenting. If you haven't entered a roster for that class, however, it will not work. You can enter your class roster by clicking on the roster icon in the add/edit classes table.
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                        <CardHeader role="tab" style={{minHeight: '0px'}}>
                        <a
                          aria-expanded={openedCollapses.includes(
                            "collapse12"
                          )}
                          href="#subscribe"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("collapse12")}
                        >
                          <b>How do I subscribe?</b>{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes("collapse12")}
                      >
                        <CardBody style={{paddingBottom: '5px'}}>
                         <a href='/pricing'>CLICK HERE</a>. :)
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                        <CardHeader role="tab" style={{minHeight: '0px'}}>
                        <a
                          aria-expanded={openedCollapses.includes(
                            "collapse13"
                          )}
                          href="#issues"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("collapse13")}
                        >
                          <b>How do I report a bug/issue?</b>{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes("collapse13")}
                      >
                        <CardBody style={{paddingBottom: '5px'}}>
                         Please reach out using our <a href='/contact'>contact form</a>.
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                        <CardHeader role="tab" style={{minHeight: '0px'}}>
                        <a
                          aria-expanded={openedCollapses.includes(
                            "collapse14"
                          )}
                          href="#suggestion"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("collapse14")}
                        >
                          <b>I have a suggestion for Lesson Launchpad.</b>{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes("collapse14")}
                      >
                        <CardBody style={{paddingBottom: '5px'}}>
                         Great! A lot of Lesson Launchpad's features have come straight from user suggestions. Please reach out using our <a href='/contact'>contact form</a>.
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                        <CardHeader role="tab" style={{minHeight: '0px'}}>
                        <a
                          aria-expanded={openedCollapses.includes(
                            "collapse15"
                          )}
                          href="#about"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("collapse15")}
                        >
                          <b>Tell me more about Lesson Launchpad.</b>{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes("collapse15")}
                      >
                        <CardBody style={{paddingBottom: '5px'}}>
                         Hi! I'm Michael, the founder of Lesson Launchpad. I'm a former band director turned software engineer and I originally came up with the idea for Lesson Launchpad in 2016.
                         At the time, I didn't have the skills to create the idea, but after switching careers to software engineering in 2022, I realized I might be able to make it happen. I started working on
                         it in August of 2023 and had a workable version ready by the end of September. I officially launched it in October and it has grown steadily since then. Lesson Launchpad is not a big operation - 
                         in fact, it's just me and I still have a full-time job as a software engineer for a national retailer. I say that for several reasons: 1.) Go easy on me - I'm doing the best I can. HAHA! 2.) Word-of-mouth
                         is EXTREMELY beneficial to Lesson Launchpad's success. If you like Lesson Launchpad, please share it with your friends/colleagues. Post a link on Facebook or your social media platform of choice. Send an email or text. 3.)
                         Feel free to send me an email and let me know what you think. If you have suggestions - I want to hear them. A lot of the functionality you see on Lesson Launchapd today came from user suggestions.
                        </CardBody>
                      </Collapse>
                    </Card>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* <Col md="12">
              <Card className="card-plain">
                <CardBody>
                  <div
                    aria-multiselectable={true}
                    className="card-collapse"
                    id="accordion"
                    role="tablist"
                  >
                    <CardTitle tag="h4">
                      Collapsible Accordion on Plain Card
                    </CardTitle>
                    <Card className="card-plain">
                      <CardHeader role="tab">
                        <a
                          aria-expanded={openedCollapses.includes("collapse1")}
                          href="#pablo"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("collapse1")}
                        >
                          Collapsible Group Item #1{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes("collapse1")}
                      >
                        <CardBody>
                          Anim pariatur cliche reprehenderit, enim eiusmod high
                          life accusamus terry richardson ad squid. 3 wolf moon
                          officia aute, non cupidatat skateboard dolor brunch.
                          Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                          wolf moon tempor, sunt aliqua put a bird on it squid
                          single-origin coffee nulla assumenda shoreditch et.
                          Nihil anim keffiyeh helvetica, craft beer labore wes
                          anderson cred nesciunt sapiente ea proident. Ad vegan
                          excepteur butcher vice lomo. Leggings occaecat craft
                          beer farm-to-table, raw denim aesthetic synth nesciunt
                          you probably haven't heard of them accusamus labore
                          sustainable VHS.
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                      <CardHeader role="tab">
                        <a
                          aria-expanded={openedCollapses.includes("collapse2")}
                          href="#pablo"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("collapse2")}
                        >
                          Collapsible Group Item #2{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes("collapse2")}
                      >
                        <CardBody>
                          Anim pariatur cliche reprehenderit, enim eiusmod high
                          life accusamus terry richardson ad squid. 3 wolf moon
                          officia aute, non cupidatat skateboard dolor brunch.
                          Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                          wolf moon tempor, sunt aliqua put a bird on it squid
                          single-origin coffee nulla assumenda shoreditch et.
                          Nihil anim keffiyeh helvetica, craft beer labore wes
                          anderson cred nesciunt sapiente ea proident. Ad vegan
                          excepteur butcher vice lomo. Leggings occaecat craft
                          beer farm-to-table, raw denim aesthetic synth nesciunt
                          you probably haven't heard of them accusamus labore
                          sustainable VHS.
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                      <CardHeader role="tab">
                        <a
                          aria-expanded={openedCollapses.includes("collapse3")}
                          href="#pablo"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("collapse3")}
                        >
                          Collapsible Group Item #3{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes("collapse3")}
                      >
                        <CardBody>
                          Anim pariatur cliche reprehenderit, enim eiusmod high
                          life accusamus terry richardson ad squid. 3 wolf moon
                          officia aute, non cupidatat skateboard dolor brunch.
                          Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                          wolf moon tempor, sunt aliqua put a bird on it squid
                          single-origin coffee nulla assumenda shoreditch et.
                          Nihil anim keffiyeh helvetica, craft beer labore wes
                          anderson cred nesciunt sapiente ea proident. Ad vegan
                          excepteur butcher vice lomo. Leggings occaecat craft
                          beer farm-to-table, raw denim aesthetic synth nesciunt
                          you probably haven't heard of them accusamus labore
                          sustainable VHS.
                        </CardBody>
                      </Collapse>
                    </Card>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
          <br />
          {/* <Row>
            <Col md="6">
              <Card>
                <CardHeader>
                  <h5>Horizontal Tabs</h5>
                </CardHeader>
                <CardBody>
                  <div className="nav-tabs-navigation">
                    <div className="nav-tabs-wrapper">
                      <Nav id="tabs" role="tablist" tabs>
                        <NavItem>
                          <NavLink
                            aria-expanded={horizontalTabs === "home"}
                            data-toggle="tab"
                            href="#pablo"
                            role="tab"
                            className={horizontalTabs === "home" ? "active" : ""}
                            onClick={() => setHorizontalTabs("home")}
                          >
                            Home
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-expanded={horizontalTabs === "profile"}
                            data-toggle="tab"
                            href="#pablo"
                            role="tab"
                            className={
                              horizontalTabs === "profile" ? "active" : ""
                            }
                            onClick={() => setHorizontalTabs("profile")}
                          >
                            Profile
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-expanded={horizontalTabs === "messages"}
                            data-toggle="tab"
                            href="#pablo"
                            role="tab"
                            className={
                              horizontalTabs === "messages" ? "active" : ""
                            }
                            onClick={() => setHorizontalTabs("messages")}
                          >
                            Messages
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                  <TabContent
                    className="text-center"
                    id="my-tab-content"
                    activeTab={horizontalTabs}
                  >
                    <TabPane tabId="home" role="tabpanel">
                      <p>
                        Larger, yet dramatically thinner. More powerful, but
                        remarkably power efficient. With a smooth metal surface
                        that seamlessly meets the new Retina HD display.
                      </p>
                    </TabPane>
                    <TabPane tabId="profile" role="tabpanel">
                      <p>Here is your profile.</p>
                    </TabPane>
                    <TabPane tabId="messages" role="tabpanel">
                      <p>Here are your messages.</p>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card>
                <CardHeader>
                  <h5>Vertical Tabs</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg="4" md="5" sm="4" xs="6">
                      <div className="nav-tabs-navigation verical-navs p-0">
                        <div className="nav-tabs-wrapper">
                          <Nav
                            className="flex-column nav-stacked"
                            role="tablist"
                            tabs
                          >
                            <NavItem>
                              <NavLink
                                data-toggle="tab"
                                href="#pablo"
                                role="tab"
                                className={
                                  verticalTabs === "info" ? "active" : ""
                                }
                                onClick={() => setVerticalTabs("info")}
                              >
                                Info
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                data-toggle="tab"
                                href="#pablo"
                                role="tab"
                                className={
                                  verticalTabs === "description" ? "active" : ""
                                }
                                onClick={() => setVerticalTabs("description")}
                              >
                                Description
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                data-toggle="tab"
                                href="#pablo"
                                role="tab"
                                className={
                                  verticalTabs === "concept" ? "active" : ""
                                }
                                onClick={() => setVerticalTabs("concept")}
                              >
                                Concept
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                data-toggle="tab"
                                href="#pablo"
                                role="tab"
                                className={
                                  verticalTabs === "support" ? "active" : ""
                                }
                                onClick={() => setVerticalTabs("support")}
                              >
                                Support
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                data-toggle="tab"
                                href="#pablo"
                                role="tab"
                                className={
                                  verticalTabs === "extra" ? "active" : ""
                                }
                                onClick={() => setVerticalTabs("extra")}
                              >
                                Extra
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </div>
                      </div>
                    </Col>
                    <Col lg="8" md="7" sm="8" xs="6">
                      <TabContent activeTab={verticalTabs}>
                        <TabPane tabId="info">
                          <p>
                            Larger, yet dramatically thinner. More powerful, but
                            remarkably power efficient. With a smooth metal
                            surface that seamlessly meets the new Retina HD
                            display.
                          </p>
                          <p>
                            It’s one continuous form where hardware and software
                            function in perfect unison, creating a new generation
                            of phone that’s better by any measure.
                          </p>
                        </TabPane>
                        <TabPane tabId="description">
                          <p>
                            The first thing you notice when you hold the phone is
                            how great it feels in your hand. The cover glass
                            curves down around the sides to meet the anodized
                            aluminum enclosure in a remarkable, simplified design.
                          </p>
                          <p>
                            There are no distinct edges. No gaps. Just a smooth,
                            seamless bond of metal and glass that feels like one
                            continuous surface.
                          </p>
                        </TabPane>
                        <TabPane tabId="concept">
                          <p>
                            It’s one continuous form where hardware and software
                            function in perfect unison, creating a new generation
                            of phone that’s better by any measure.
                          </p>
                          <p>
                            Larger, yet dramatically thinner. More powerful, but
                            remarkably power efficient. With a smooth metal
                            surface that seamlessly meets the new Retina HD
                            display.
                          </p>
                        </TabPane>
                        <TabPane tabId="support">
                          <p>
                            From the seamless transition of glass and metal to the
                            streamlined profile, every detail was carefully
                            considered to enhance your experience. So while its
                            display is larger, the phone feels just right.
                          </p>
                          <p>
                            It’s one continuous form where hardware and software
                            function in perfect unison, creating a new generation
                            of phone that’s better by any measure.
                          </p>
                        </TabPane>
                        <TabPane tabId="extra">
                          <p>
                            It’s one continuous form where hardware and software
                            function in perfect unison, creating a new generation
                            of phone that’s better by any measure.
                          </p>
                          <p>
                            Larger, yet dramatically thinner. More powerful, but
                            remarkably power efficient. With a smooth metal
                            surface that seamlessly meets the new Retina HD
                            display.
                          </p>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
          {/* <Row>
            <Col className="ml-auto mr-auto" md="8">
              <Card className="card-plain card-subcategories">
                <CardHeader>
                  <CardTitle className="text-center" tag="h4">
                    Page Subcategories
                  </CardTitle>
                  <br />
                </CardHeader>
                <CardBody>
                   {/* color-classes: "nav-pills-primary", "nav-pills-info", "nav-pills-success", "nav-pills-warning","nav-pills-danger"
                  <Nav
                    className="nav-pills-primary nav-pills-icons justify-content-center"
                    pills
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        data-toggle="tab"
                        href="#pablo"
                        role="tablist"
                        className={pageTabs === "homePages" ? "active" : ""}
                        onClick={() => setPageTabs("homePages")}
                      >
                        <i className="now-ui-icons objects_umbrella-13" />
                        Home
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        data-toggle="tab"
                        href="#pablo"
                        role="tablist"
                        className={pageTabs === "messagesPages" ? "active" : ""}
                        onClick={() => setPageTabs("messagesPages")}
                      >
                        <i className="now-ui-icons shopping_shop" />
                        Messages
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        data-toggle="tab"
                        href="#pablo"
                        role="tablist"
                        className={pageTabs === "settingsPages" ? "active" : ""}
                        onClick={() => setPageTabs("settingsPages")}
                      >
                        <i className="now-ui-icons ui-2_settings-90" />
                        Settings
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent
                    className="tab-space tab-subcategories"
                    activeTab={pageTabs}
                  >
                    <TabPane tabId="homePages">
                      Collaboratively administrate empowered markets via
                      plug-and-play networks. Dynamically procrastinate B2C users
                      after installed base benefits. <br />
                      <br />
                      Dramatically visualize customer directed convergence without
                      revolutionary ROI.
                    </TabPane>
                    <TabPane tabId="messagesPages">
                      Efficiently unleash cross-media information without
                      cross-media value. Quickly maximize timely deliverables for
                      real-time schemas. <br />
                      <br />
                      Dramatically maintain clicks-and-mortar solutions without
                      functional solutions.
                    </TabPane>
                    <TabPane tabId="settingsPages">
                      Completely synergize resource taxing relationships via
                      premier niche markets. Professionally cultivate one-to-one
                      customer service with robust ideas. <br />
                      <br />
                      Dynamically innovate resource-leveling customer service for
                      state of the art customer service.
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </div>
      </>
    )
}
