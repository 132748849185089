/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { setDoc, doc } from "firebase/firestore";
import { serverTimestamp } from "firebase/firestore";
import { createRandomId, createShareCode } from "RandomId";
import jwtDecode from "jwt-decode";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  FormFeedback
} from "reactstrap";

function Register() {
  React.useEffect(() => {
    document.body.classList.toggle("register-page");
    return function cleanup() {
      document.body.classList.toggle("register-page");
    };
  });

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [leadSource, setLeadSource] = useState("");
  const [showValidation, setShowValidation] = useState(false);

  const history = useHistory();

  const accountCreated = new Date(Date.now() + (-5 * 60) * 60 * 1000);

  const blankSchedule = {
    "A": {
      "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "B": {
      "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "C": {
      "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "D": {
      "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "E": {
      "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "F": {
      "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "G": {
      "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
  }

  function getDate30DaysInFuture() {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 30);
  
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }

  const register = async (e) => {
    e.preventDefault()
    if (isValidated()) {
      await createUserWithEmailAndPassword(
        auth,
        email,
        password
        ).then((authUser) => {
          setDoc(doc(db, "users", authUser.user.uid), {
            firstName: firstName,
            lastName: lastName,
            email: email,
            accountCreated: accountCreated,
            contentBoxTemplates: [],
            premium: false,
            trial: true,
            shareCode: createShareCode(),
            leadData: {
                leadSource: leadSource,
                mobile: navigator?.userAgentData?.mobile === false ? false : navigator?.userAgentData?.mobile === true ? true : ""
              },
            announcements: [
              {
                html: "<p><strong>🎉 <span style=\"color: rgb(11, 192, 223);\">Welcome to Lesson Launchpad!!!</span> 🎉</strong></p>",
                text: "🎉 Welcome to Lesson Launchpad!!! 🎉",
                active: true,
                show: true,
                lastEdited: "",
                dateCreated: "",
                classIds: ["welcome1"],
                id: "id111111111",
              },
              {
                html: "<p>You can use this space for whatever you wish or turn it off completely in the Add/Edit Classes tab.</p>",
                text: "You can use this space for whatever you wish or turn it off completely in the Add/Edit Classes tab.",
                active: true,
                show: true,
                lastEdited: "",
                dateCreated: "",
                classIds: ["welcome1"],
                id: "id222222222",
              },
              {
                html: "<p>✏️Hover your mouse over this space and click the pencil button to edit.</p>",
                text: "Hover your mouse over this space and click the pencil button to edit.",
                active: true,
                show: true,
                lastEdited: "",
                dateCreated: "",
                classIds: ["welcome1"],
                id: "id333333333",
              }
            ],
            settings: { //ADD NEW PROPERTIES TO addSettingsObjectToUser() FUNCTION IN APP.JS
              showSeconds: true, //ADD NEW PROPERTIES TO addSettingsObjectToUser() FUNCTION IN APP.JS
              logo: true, //ADD NEW PROPERTIES TO addSettingsObjectToUser() FUNCTION IN APP.JS
              marqueeSpeed: '150', //ADD NEW PROPERTIES TO addSettingsObjectToUser() FUNCTION IN APP.JS
              alteredSchedule: false, //ADD NEW PROPERTIES TO addSettingsObjectToUser() FUNCTION IN APP.JS
              multiWeek: false, //ADD NEW PROPERTIES TO addSettingsObjectToUser() FUNCTION IN APP.JS
              numberOfWeeks: 1, //ADD NEW PROPERTIES TO addSettingsObjectToUser() FUNCTION IN APP.JS
              currentWeek: "A", //ADD NEW PROPERTIES TO addSettingsObjectToUser() FUNCTION IN APP.JS
              differentTimes: false, //ADD NEW PROPERTIES TO addSettingsObjectToUser() FUNCTION IN APP.JS
              useActiveDates: false, //ADD NEW PROPERTIES TO addSettingsObjectToUser() FUNCTION IN APP.JS
              useShareCode: false, //ADD NEW PROPERTIES TO addSettingsObjectToUser() FUNCTION IN APP.JS
              screenTimeout: '60' //ADD NEW PROPERTIES TO addSettingsObjectToUser() FUNCTION IN APP.JS
            },
            classes: [
              {
                classId: "welcome1",
                period: "Hi",
                className: "Sample Class",
                startTime: null,
                endTime: null,
                showMarquee: true,
                showClock: true,
                active: true,
                schedule: blankSchedule,
                orderOfAnnouncements: ["id111111111", "id222222222", "id333333333"],
                contentBoxes: [
                  {
                    contentBoxId: createRandomId(),
                    heading: "Welcome!",
                    show: true,
                    content: welcomeBox,
                    userHeading: true,
                    active: true,
                    startDate: "2024-01-01",
                    endDate: "2099-12-31"
                  },
                  {
                    contentBoxId: createRandomId(),
                    heading: "Demo",
                    show: true,
                    content: demoVideoContent,
                    userHeading: true,
                    active: true,
                    startDate: "2024-01-01",
                    endDate: "2099-12-31"
                  },
                  {
                    contentBoxId: createRandomId(),
                    agendaBox: true,
                    heading: "Agenda",
                    show: false,
                    content: '<p><strong><span style="font-size: 36pt;">Agenda:</span></strong></p><ul><li style="font-size: 30pt;"><span style="font-size: 30pt;">Warm-up</span></li><li style="font-size: 30pt;"><span style="font-size: 30pt;">Tuning</span></li><li style="font-size: 30pt;"><span style="font-size: 30pt;">Announcements</span></li></ul><hr><p style="text-align: center;"><span style="font-size: 24pt;"><em>Sectionals Leave</em></span></p><hr><ul><li style="font-size: 30pt;"><span style="font-size: 30pt;">Holst</span></li><li style="font-size: 30pt;"><span style="font-size: 30pt;">Ticheli</span></li><li style="font-size: 30pt;"><span style="font-size: 30pt;">Sousa</span></li></ul>',
                    userHeading: true,
                    active: true,
                    startDate: "2024-01-01",
                    endDate: "2099-12-31",
                    startTime: "00:00",
                    steps: [
                      {
                          "subTaskSteps": [],
                          "minutes": "6",
                          "title": "Warm-up",
                          "seconds": 0,
                          "subTask": false,
                          "stepId": "id8ffac00206301"
                      },
                      {
                          "seconds": 0,
                          "minutes": "4",
                          "subTask": false,
                          "subTaskSteps": [],
                          "title": "Tuning",
                          "stepId": "id39f71d5bd6eea"
                      },
                      {
                          "minutes": "3",
                          "title": "Announcements",
                          "subTask": false,
                          "seconds": 0,
                          "stepId": "id892dfa498112e",
                          "subTaskSteps": []
                      },
                      {
                          "title": "Holst",
                          "seconds": 0,
                          "subTaskSteps": [],
                          "stepId": "idcb4dc64fe562a",
                          "minutes": "12",
                          "subTask": false
                      },
                      {
                          "subTaskSteps": [],
                          "title": "Ticheli",
                          "minutes": "12",
                          "subTask": false,
                          "stepId": "id2364dcc209838",
                          "seconds": 0
                      },
                      {
                          "minutes": "6",
                          "stepId": "id161361fc741ed",
                          "title": "Sousa",
                          "subTaskSteps": [],
                          "seconds": 0,
                          "subTask": false
                      }
                  ]
                  },
                  multiBox
                ]
              }
            ]
          })
          setTimeout(() => {
              history.push("/")
          }, 1000);
        }).catch((error) => {
          console.log(error)
          alert("Registration Failed")
        });
    } else {
      setShowValidation(true)
    }
  }

  const welcomeBox = {
    "type": "doc",
    "content": [
        {
            "type": "paragraph",
            "attrs": {
                "textAlign": "center"
            },
            "content": [
                {
                    "type": "text",
                    "marks": [
                        {
                            "type": "bold"
                        },
                        {
                            "type": "textStyle",
                            "attrs": {
                                "color": "rgb(11, 192, 223)",
                                "fontSize": "32px",
                                "fontFamily": {
                                    "fontFamily": ""
                                },
                                "lineHeight": {
                                    "lineHeight": ""
                                }
                            }
                        }
                    ],
                    "text": "🎉 Welcome to Lesson Launchpad! 🎉"
                }
            ]
        },
        {
            "type": "horizontalRule"
        },
        {
            "type": "paragraph",
            "attrs": {
                "textAlign": "center"
            },
            "content": [
                {
                    "type": "text",
                    "marks": [
                        {
                            "type": "textStyle",
                            "attrs": {
                                "color": "",
                                "fontSize": "14px",
                                "fontFamily": {
                                    "fontFamily": ""
                                },
                                "lineHeight": {
                                    "lineHeight": ""
                                }
                            }
                        }
                    ],
                    "text": "My name is Michael and I'm a former band director turned Software Developer. As a teacher I always wanted a better solution to the \"agenda slide\". I wanted to be able to present more information, but in a neat un-cluttered way. I envisioned something that looks similar to what you see here. Lesson Launchpad already has quite a few features but I have a LOT more planned. I would love to hear your feedback so feel free to reach out. I'm excited to make the best possible version of Lesson Launchpad for you."
                }
            ]
        },
        {
            "type": "horizontalRule"
        },
        {
            "type": "paragraph",
            "attrs": {
                "textAlign": "center"
            },
            "content": [
                {
                    "type": "text",
                    "marks": [
                        {
                            "type": "bold"
                        },
                        {
                            "type": "textStyle",
                            "attrs": {
                                "color": "rgb(11, 192, 223)",
                                "fontSize": "24px",
                                "fontFamily": {
                                    "fontFamily": ""
                                },
                                "lineHeight": {
                                    "lineHeight": ""
                                }
                            }
                        }
                    ],
                    "text": "Where to start?"
                }
            ]
        },
        {
            "type": "paragraph",
            "attrs": {
                "textAlign": "center"
            },
            "content": [
                {
                    "type": "text",
                    "marks": [
                        {
                            "type": "textStyle",
                            "attrs": {
                                "color": "",
                                "fontSize": "14px",
                                "fontFamily": {
                                    "fontFamily": ""
                                },
                                "lineHeight": {
                                    "lineHeight": ""
                                }
                            }
                        }
                    ],
                    "text": "Why not here? You can click directly inside this Content Box to edit its contents. You'll see that there's a wide variety of formatting options so you can customize your content in whatever way you wish. Next, you'll notice two buttons below titled \"Agenda\" and \"Sectionals\". These are two Content Boxes that show how I might have used Lesson Launchpad in my classroom. There's also a sample Google Slides box. Yes, you can use your already created google slides presentations right here in Lesson Launchpad. You can have multiple Content Boxes and toggle them on and off as you wish by clicking in the center of the Content Box buttons below. Click \"Launch\" then \"Add Content Box\" to start with a fresh box."
                }
            ]
        },
        {
            "type": "horizontalRule"
        },
        {
            "type": "paragraph",
            "attrs": {
                "textAlign": "center"
            },
            "content": [
                {
                    "type": "text",
                    "marks": [
                        {
                            "type": "bold"
                        },
                        {
                            "type": "textStyle",
                            "attrs": {
                                "color": "rgb(11, 192, 223)",
                                "fontSize": "24px",
                                "fontFamily": {
                                    "fontFamily": ""
                                },
                                "lineHeight": {
                                    "lineHeight": ""
                                }
                            }
                        }
                    ],
                    "text": "Let's create your first Class!"
                }
            ]
        },
        {
            "type": "paragraph",
            "attrs": {
                "textAlign": "center"
            },
            "content": [
                {
                    "type": "text",
                    "marks": [
                        {
                            "type": "textStyle",
                            "attrs": {
                                "color": "",
                                "fontSize": "14px",
                                "fontFamily": {
                                    "fontFamily": ""
                                },
                                "lineHeight": {
                                    "lineHeight": ""
                                }
                            }
                        }
                    ],
                    "text": "You can click the Add/Edit link in the left navigation bar to add your first class. You'll notice you can choose to have the marquee and clock hidden if you find them distracting or just have no use for them. You can also set a start time for each class. If it's time for a class to start Lesson Launchpad will automatically navigate to that class for you."
                }
            ]
        }
    ]
}

const demoVideoContent = {
    "type": "doc",
    "content": [
        {
            "type": "paragraph",
            "content": [
                {
                    "type": "text",
                    "text": "🎥 YouTube Demo ",
                    "marks": [
                        {
                            "type": "bold"
                        },
                        {
                            "attrs": {
                                "lineHeight": {
                                    "lineHeight": ""
                                },
                                "fontFamily": {
                                    "fontFamily": ""
                                },
                                "color": "",
                                "fontSize": "28px"
                            },
                            "type": "textStyle"
                        }
                    ]
                },
                {
                    "text": "🍿",
                    "type": "text",
                    "marks": [
                        {
                            "type": "textStyle",
                            "attrs": {
                                "fontFamily": {
                                    "fontFamily": ""
                                },
                                "lineHeight": {
                                    "lineHeight": ""
                                },
                                "fontSize": "28px",
                                "color": ""
                            }
                        }
                    ]
                }
            ],
            "attrs": {
                "textAlign": "center"
            }
        },
        {
            "type": "resizableYouTube",
            "attrs": {
                "width": 625,
                "height": 360,
                "src": "https://www.youtube.com/embed/MoCoKv1hw7Q"
            }
        }
    ]
}

const multiBox = {
  "heading": "MultiBox",
  "endDate": "2024-01-01",
  "active": true,
  "startDate": "2099-01-01",
  "show": false,
  "contentBoxId": createRandomId(),
  "content": [
      {
          "contentBoxId": createRandomId(),
          "content": {
            "type": "doc",
            "content": [
                {
                    "type": "paragraph",
                    "attrs": {
                        "textAlign": "left"
                    },
                    "content": [
                        {
                            "type": "text",
                            "marks": [
                                {
                                    "type": "textStyle",
                                    "attrs": {
                                        "color": "",
                                        "fontSize": "40px",
                                        "fontFamily": {
                                            "fontFamily": ""
                                        },
                                        "lineHeight": {
                                            "lineHeight": ""
                                        }
                                    }
                                }
                            ],
                            "text": "Sectionals:"
                        }
                    ]
                },
                {
                    "type": "table",
                    "content": [
                        {
                            "type": "tableRow",
                            "content": [
                                {
                                    "type": "tableCell",
                                    "attrs": {
                                        "colspan": 1,
                                        "rowspan": 1,
                                        "colwidth": [
                                            108
                                        ]
                                    },
                                    "content": [
                                        {
                                            "type": "paragraph",
                                            "attrs": {
                                                "textAlign": "center"
                                            },
                                            "content": [
                                                {
                                                    "type": "text",
                                                    "marks": [
                                                        {
                                                            "type": "textStyle",
                                                            "attrs": {
                                                                "color": null,
                                                                "fontSize": "16px",
                                                                "fontFamily": null,
                                                                "lineHeight": null
                                                            }
                                                        }
                                                    ],
                                                    "text": "Flutes"
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "type": "tableCell",
                                    "attrs": {
                                        "colspan": 1,
                                        "rowspan": 1,
                                        "colwidth": [
                                            125
                                        ]
                                    },
                                    "content": [
                                        {
                                            "type": "paragraph",
                                            "attrs": {
                                                "textAlign": "center"
                                            },
                                            "content": [
                                                {
                                                    "type": "text",
                                                    "marks": [
                                                        {
                                                            "type": "textStyle",
                                                            "attrs": {
                                                                "color": null,
                                                                "fontSize": "16px",
                                                                "fontFamily": null,
                                                                "lineHeight": null
                                                            }
                                                        }
                                                    ],
                                                    "text": "Inst. Storage"
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "type": "tableCell",
                                    "attrs": {
                                        "colspan": 1,
                                        "rowspan": 1,
                                        "colwidth": [
                                          108
                                      ]                          },
                                    "content": [
                                        {
                                            "type": "paragraph",
                                            "attrs": {
                                                "textAlign": "center"
                                            },
                                            "content": [
                                                {
                                                    "type": "text",
                                                    "marks": [
                                                        {
                                                            "type": "textStyle",
                                                            "attrs": {
                                                                "color": null,
                                                                "fontSize": "16px",
                                                                "fontFamily": null,
                                                                "lineHeight": null
                                                            }
                                                        }
                                                    ],
                                                    "text": "Parker"
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "type": "tableRow",
                            "content": [
                                {
                                    "type": "tableCell",
                                    "attrs": {
                                        "colspan": 1,
                                        "rowspan": 1,
                                        "colwidth": [
                                            108
                                        ]
                                    },
                                    "content": [
                                        {
                                            "type": "paragraph",
                                            "attrs": {
                                                "textAlign": "center"
                                            },
                                            "content": [
                                                {
                                                    "type": "text",
                                                    "marks": [
                                                        {
                                                            "type": "textStyle",
                                                            "attrs": {
                                                                "color": null,
                                                                "fontSize": "16px",
                                                                "fontFamily": null,
                                                                "lineHeight": null
                                                            }
                                                        }
                                                    ],
                                                    "text": "Clarinets"
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "type": "tableCell",
                                    "attrs": {
                                        "colspan": 1,
                                        "rowspan": 1,
                                        "colwidth": [
                                            125
                                        ]
                                    },
                                    "content": [
                                        {
                                            "type": "paragraph",
                                            "attrs": {
                                                "textAlign": "center"
                                            },
                                            "content": [
                                                {
                                                    "type": "text",
                                                    "marks": [
                                                        {
                                                            "type": "textStyle",
                                                            "attrs": {
                                                                "color": null,
                                                                "fontSize": "16px",
                                                                "fontFamily": null,
                                                                "lineHeight": null
                                                            }
                                                        }
                                                    ],
                                                    "text": "Ensemble 2"
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "type": "tableCell",
                                    "attrs": {
                                        "colspan": 1,
                                        "rowspan": 1,
                                        "colwidth": [
                                          108
                                      ]                          },
                                    "content": [
                                        {
                                            "type": "paragraph",
                                            "attrs": {
                                                "textAlign": "center"
                                            },
                                            "content": [
                                                {
                                                    "type": "text",
                                                    "marks": [
                                                        {
                                                            "type": "textStyle",
                                                            "attrs": {
                                                                "color": null,
                                                                "fontSize": "16px",
                                                                "fontFamily": null,
                                                                "lineHeight": null
                                                            }
                                                        }
                                                    ],
                                                    "text": "Mikel"
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "type": "tableRow",
                            "content": [
                                {
                                    "type": "tableCell",
                                    "attrs": {
                                        "colspan": 1,
                                        "rowspan": 1,
                                        "colwidth": [
                                            108
                                        ]
                                    },
                                    "content": [
                                        {
                                            "type": "paragraph",
                                            "attrs": {
                                                "textAlign": "center"
                                            },
                                            "content": [
                                                {
                                                    "type": "text",
                                                    "marks": [
                                                        {
                                                            "type": "textStyle",
                                                            "attrs": {
                                                                "color": null,
                                                                "fontSize": "16px",
                                                                "fontFamily": null,
                                                                "lineHeight": null
                                                            }
                                                        }
                                                    ],
                                                    "text": "Trumpets"
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "type": "tableCell",
                                    "attrs": {
                                        "colspan": 1,
                                        "rowspan": 1,
                                        "colwidth": [
                                            125
                                        ]
                                    },
                                    "content": [
                                        {
                                            "type": "paragraph",
                                            "attrs": {
                                                "textAlign": "center"
                                            },
                                            "content": [
                                                {
                                                    "type": "text",
                                                    "marks": [
                                                        {
                                                            "type": "textStyle",
                                                            "attrs": {
                                                                "color": null,
                                                                "fontSize": "16px",
                                                                "fontFamily": null,
                                                                "lineHeight": null
                                                            }
                                                        }
                                                    ],
                                                    "text": "Ensemble 1"
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "type": "tableCell",
                                    "attrs": {
                                        "colspan": 1,
                                        "rowspan": 1,
                                        "colwidth": [
                                          108
                                      ]                          },
                                    "content": [
                                        {
                                            "type": "paragraph",
                                            "attrs": {
                                                "textAlign": "center"
                                            },
                                            "content": [
                                                {
                                                    "type": "text",
                                                    "marks": [
                                                        {
                                                            "type": "textStyle",
                                                            "attrs": {
                                                                "color": null,
                                                                "fontSize": "16px",
                                                                "fontFamily": null,
                                                                "lineHeight": null
                                                            }
                                                        }
                                                    ],
                                                    "text": "Whitworth"
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
          },
          "textEditor": true,
          "show": true,
          "active": true
      },
      {
          "content": {
              "eventName": "the Concert",
              "eventDate": getDate30DaysInFuture(),
              "fontColor": "",
              "fontStyle": "helvetica"
          },
          "countdown": true,
          "active": true,
          "show": true,
          "contentBoxId": createRandomId()
      }
  ],
  "multiBox": true
}

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      register(e); // Trigger the button click event
    }
  };

  useEffect(() => {
    /* global google*/
    google.accounts.id.initialize({
      client_id: '403855687421-c6q3mv8991jn1qpcrs9kga2s55vtqlnv.apps.googleusercontent.com',
      callback: handleGoogleLogin
    })

    google.accounts.id.renderButton(
      document.getElementById("googleRegister"),
      { theme: "outline", size: "large"}
    );
  }, [])

  const handleGoogleLogin = (response) => {
    console.log(jwtDecode(response.credential))
  }

  function isEmailValid(email) {
    // Regular expression pattern for a valid email address
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  
    // Test the email against the pattern
    return emailPattern.test(email);
  }

  const isValidated = () => {
    if (firstName && lastName && email && password) {
      if (isEmailValid(email) && password.length >= 8 && firstName.length > 0 && firstName.length <= 30 && lastName.length > 0 && lastName.length <= 30) {
        return true;
      }
    } else {
      return false;
    }
  }

  const isMobile = /Mobi/.test(navigator.userAgent);

  return (
    <>
    { !isMobile ? (
    <div className="register-page">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" lg="10" >
            <Card className="card-signup text-center">
              <CardHeader style={{minHeight: '20px'}}>
                <CardTitle tag="h4">Register</CardTitle>
                {/* <div className="social">
                  <Button className="btn-icon btn-round" color="twitter">
                    <i className="fa fa-twitter" />
                  </Button>
                  <Button className="btn-icon btn-round" color="dribbble">
                    <i className="fa fa-dribbble" />
                  </Button>
                  <Button className="btn-icon btn-round" color="facebook">
                    <i className="fa fa-facebook-f" />
                  </Button>
                  <p className="card-description">or be classical</p>
                </div> */}
              </CardHeader>
              {/* <div id="googleRegister" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px'}}></div> */}
              <CardBody>
                <div style={{marginBottom: '20px'}}>
                  <a href="https://lessonlaunchpad.com/auth/login">Already registered? Click here to sign in.</a>
                </div>
                <Form action="" className="form" method="">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="First Name..."
                      type="text"
                      required
                      valid={showValidation && firstName?.length > 0 && firstName?.length <= 30}
                      invalid={showValidation && firstName?.length === undefined || firstName?.length > 30}
                    />
                      {showValidation && firstName?.length === undefined ?
                        (<FormFeedback invalid>Please enter a First Name.</FormFeedback>)
                      : showValidation && firstName?.length > 30 ?
                        (<FormFeedback invalid>First Name must be less than 30 characters.</FormFeedback>)
                      : (<FormFeedback valid>Looks good!</FormFeedback>)
                      }
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-circle-10" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Last Name..."
                      type="text"
                      required
                      valid={showValidation && lastName?.length > 0 && lastName?.length <= 30}
                      invalid={showValidation && lastName?.length === undefined || lastName?.length > 30}
                    />
                      {showValidation && lastName?.length === undefined ?
                        (<FormFeedback invalid>Please enter a Last Name.</FormFeedback>)
                      : showValidation && lastName?.length > 30 ?
                        (<FormFeedback invalid>Last Name must be less than 30 characters.</FormFeedback>)
                      : (<FormFeedback valid>Looks good!</FormFeedback>)
                      }
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email..."
                      type="email"
                      required
                      valid={showValidation && email && isEmailValid(email)}
                      invalid={showValidation && !isEmailValid(email)}
                    />
                     {showValidation && email && isEmailValid(email) ?
                        (<FormFeedback valid>Looks good!</FormFeedback>)
                      : 
                        (<FormFeedback invalid>Email Invalid</FormFeedback>)
                      }
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password..."
                      type="password"
                      onKeyPress={handleEnterKeyPress}
                      required
                      valid={showValidation && password?.length >= 8}
                      invalid={showValidation && password?.length < 8 || showValidation && password === undefined}
                    />
                      {showValidation && password?.length < 8 || password === undefined ?
                        (<FormFeedback invalid>Password must be atleast 8 characters.</FormFeedback>)
                      :
                        (<FormFeedback valid>Looks good!</FormFeedback>)
                      }
                  </InputGroup>
                  <hr></hr>
                  Where did you hear about Lesson Launchpad?
                  <InputGroup>
                    <Input 
                      onChange={(e) => setLeadSource(e.target.value)}
                      type="select"
                      onKeyPress={handleEnterKeyPress}
                      style={{border: '1px solid #DDDDDD'}}
                    >
                      <option defaultChecked={true}></option>
                      <option>Facebook Ad</option>
                      <option>Pinterest Ad</option>
                      <option>Instagram Ad</option>
                      <option>Google Ad</option>
                      <option>Google Search</option>
                      <option>Convention Booth</option>
                      <option>Colleague/Friend</option>
                      <option>Other</option>
                    </Input>
                  </InputGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  className="btn-round"
                  color="info"
                  onClick={(e) => register(e)}
                >
                  Get Started
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("assets/img/bg/jan-sendereks.jpg")})`
        }}
      />
    </div>
    ) : (
      <div className="register-page">
        <Row>
          <Col className="ml-auto mr-auto">
            <Card style={{borderRadius: '20px'}}>
              <CardHeader>
                <CardTitle tag="h1" style={{fontSize: '125px'}}>Register</CardTitle>
                {/* <div className="social">
                  <Button className="btn-icon btn-round" color="twitter">
                    <i className="fa fa-twitter" />
                  </Button>
                  <Button className="btn-icon btn-round" color="dribbble">
                    <i className="fa fa-dribbble" />
                  </Button>
                  <Button className="btn-icon btn-round" color="facebook">
                    <i className="fa fa-facebook-f" />
                  </Button>
                  <p className="card-description">or be classical</p>
                </div> */}
              </CardHeader>
              {/* <div id="googleRegister" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px'}}></div> */}
              <CardBody>
                <Form action="" className="form" method="">
                  <InputGroup style={{marginTop: '60px'}}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-single-02" style={{ fontSize: '60px'}}/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="First Name..."
                      type="text"
                      required
                      valid={showValidation && firstName?.length > 0 && firstName?.length <= 30}
                      invalid={showValidation && firstName?.length === undefined || firstName?.length > 30}
                      style={{ fontSize: '60px', marginLeft: '60px', border: 'solid', borderLeft: '0'}}
                    />
                      {showValidation && firstName?.length === undefined ?
                        (<FormFeedback invalid>Please enter a First Name.</FormFeedback>)
                      : showValidation && firstName?.length > 30 ?
                        (<FormFeedback invalid>First Name must be less than 30 characters.</FormFeedback>)
                      : (<FormFeedback valid>Looks good!</FormFeedback>)
                      }
                  </InputGroup>
                  <InputGroup style={{marginTop: '60px'}}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-circle-10" style={{ fontSize: '60px'}}/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Last Name..."
                      type="text"
                      required
                      valid={showValidation && lastName?.length > 0 && lastName?.length <= 30}
                      invalid={showValidation && lastName?.length === undefined || lastName?.length > 30}
                      style={{ fontSize: '60px', marginLeft: '60px', border: 'solid', borderLeft: '0'}}
                    />
                      {showValidation && lastName?.length === undefined ?
                        (<FormFeedback invalid>Please enter a Last Name.</FormFeedback>)
                      : showValidation && lastName?.length > 30 ?
                        (<FormFeedback invalid>Last Name must be less than 30 characters.</FormFeedback>)
                      : (<FormFeedback valid>Looks good!</FormFeedback>)
                      }
                  </InputGroup>
                  <InputGroup style={{marginTop: '60px'}}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-email-85" style={{ fontSize: '60px'}}/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email..."
                      type="email"
                      required
                      valid={showValidation && email && isEmailValid(email)}
                      invalid={showValidation && !isEmailValid(email)}
                      style={{ fontSize: '60px', marginLeft: '60px', border: 'solid', borderLeft: '0'}}
                    />
                     {showValidation && email && isEmailValid(email) ?
                        (<FormFeedback valid>Looks good!</FormFeedback>)
                      : 
                        (<FormFeedback invalid>Email Invalid</FormFeedback>)
                      }
                  </InputGroup>
                  <InputGroup style={{marginTop: '60px'}}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-key-25" style={{ fontSize: '60px'}}/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password..."
                      type="password"
                      onKeyPress={handleEnterKeyPress}
                      required
                      valid={showValidation && password?.length >= 8}
                      invalid={showValidation && password?.length < 8 || showValidation && password === undefined}
                      style={{ fontSize: '60px', marginLeft: '60px', border: 'solid', borderLeft: '0'}}
                    />
                      {showValidation && password?.length < 8 || password === undefined ?
                        (<FormFeedback invalid>Password must be atleast 8 characters.</FormFeedback>)
                      :
                        (<FormFeedback valid>Looks good!</FormFeedback>)
                      }
                  </InputGroup>
                  <hr style={{marginTop: '60px', marginBottom: '40px'}}></hr>
                  <h1>Where did you hear about Lesson Launchpad?</h1>
                  <InputGroup>
                    <Input 
                      onChange={(e) => setLeadSource(e.target.value)}
                      type="select"
                      onKeyPress={handleEnterKeyPress}
                      style={{ height: '95px', fontSize: '30px', border: '1px solid #DDDDDD', border: 'solid'}}
                    >
                      <option defaultChecked={true}></option>
                      <option>Facebook Ad</option>
                      <option>Pinterest Ad</option>
                      <option>Instagram Ad</option>
                      <option>Google Ad</option>
                      <option>Google Search</option>
                      <option>Convention Booth</option>
                      <option>Colleague/Friend</option>
                      <option>Other</option>
                    </Input>
                  </InputGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  className="btn-round"
                  color="info"
                  onClick={(e) => register(e)}
                  style={{height: '110px', fontSize: '70px', borderRadius: '20px', margin: '50px'}}
                >
                  Get Started
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
    </div>
    )}
    </>
  );
}

export default Register;
