/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useRef } from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, useLocation, NavLink } from "react-router-dom";
import Sidebar from "components/Sidebar/Sidebar.js";
import Present from "views/Present.js"
import routes from "routes.js";
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import { Button, Row, Spinner } from "reactstrap";
import logo from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/launchpad.png";

var ps;

function Admin(props) {

  const user = useSelector(selectUser); 
  const notificationAlert = useRef();
  const helpSessionAlert = useRef();

  useEffect(() => {
    getClassRoutes();
  },[props])

  const location = useLocation();
  const [backgroundColor, setBackgroundColor] = React.useState("white");
  const [activeColor, setActiveColor] = React.useState("info");
  const [classRoutes, setClassRoutes] = useState(null);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const mainPanel = React.useRef();
  const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
  const ONE_WEEK_IN_MS = 24 * 60 * 60 * 1000 * 7;

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      // Check if mainPanel.current is defined before initializing PerfectScrollbar
      if (mainPanel.current) {
        ps = new PerfectScrollbar(mainPanel.current);
      }
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        // Check if ps is defined before destroying it
        if (ps) {
          ps.destroy();
        }
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  }, [mainPanel]); // Include mainPanel in the dependency array

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // Check if mainPanel.current is defined before setting scrollTop
    if (mainPanel.current) {
      mainPanel.current.scrollTop = 0;
    }
  }, [location]);

  function detectMobileView() {
    // Define the threshold width for considering it a mobile view
    const mobileThreshold = 768; // You can adjust this value as needed
  
    // Get the current viewport width
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
  
    // Check if the viewport width is below the threshold
    if (viewportWidth < mobileThreshold) {
      alert('This is a mobile view!');
    }
  }
  
  // Call the function when the page loads or as needed
  window.addEventListener('load', detectMobileView);

  const getClassRoutes = () => {
    const classRoutesArray = [];
    user && user.classes.map((item) => {
      // Limit the name to 23 characters and add "..." if it exceeds that length
      const truncatedName = item.className
        ? item.className.length > 23
          ? item.className.substring(0, 23) + "..."
          : item.className
        : item.classId; //If className is null or undefined, use classId instead.
  
      const classPath = "/" + item.classId;
      
      classRoutesArray.push({
        path: classPath,
        name: truncatedName, // Use the truncated name
        mini: item.period != null ? item.period.replace(/ .*/, "") : ">",
        component: Present,
        layout: "/admin",
        classId: item.classId,
        showMarquee: item.showMarquee,
        showClock: item.showClock,
        visible: item.active ? true : false,
      });
    });
    const newRoutes = [...routes];
    newRoutes[0].views = classRoutesArray;
    setClassRoutes(routes);
  };
  
  const getRoutes = (classRoutes) => {
    return classRoutes && classRoutes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route 
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  // const handleActiveClick = (color) => {
  //   setActiveColor(color);
  // };
  // const handleBgClick = (color) => {
  //   setBackgroundColor(color);
  // };
  // const handleMiniClick = () => {
  //   if (document.body.classList.contains("sidebar-mini")) {
  //     setSidebarMini(false);
  //   } else {
  //     setSidebarMini(true);
  //   }
  //   document.body.classList.toggle("sidebar-mini");
  // };

  const [mobileAlert, setMobileAlert] = useState(null);
  const [trialAlert, setTrialAlert] = useState(null);

    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(orientation: portrait)").matches;
    
    const hideMobileAlert = () => {
      setMobileAlert(null);
      };

      const hideTrialAlert = () => {
        setTrialAlert(null);
        };
    
      // Function to show an alert if the user is on a mobile device
    function showAlertForMobile() {
    if (isMobileDevice) {
      setMobileAlert(
                <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px", zoom: '3'}}
                title="Are you on a mobile device?"
                onConfirm={() => hideMobileAlert()}
                confirmBtnBsStyle="info"
                cancelBtnBsStyle="danger"
                confirmBtnText="Close"
                btnSize=""
                >
                We think first impressions are important and Lesson Launchpad is designed for use on a desktop computer in the landscape orientation.
                Feel free to continue, just know that it really does look a lot better than this. We promise. :)
                </ReactBSAlert>
            );
        };
    }

    function showAlertForTrialPeriod() {
      if (user && notificationAlert.current) {
        if (!user.premium && user.trial) {
          const currentDate = new Date();
          const milliseconds = user.accountCreated?.seconds ? user.accountCreated.seconds * 1000 + (user.accountCreated?.nanoseconds || 0) / 1e6 : undefined;
          const accountCreatedDate = new Date(milliseconds);
          const daysRemaining = 30 - (Math.floor((currentDate - accountCreatedDate) / (1000 * 60 * 60 * 24)));
          var options = {};
          options = {
            place: 'br',
            message: (
              <div>
                <div>
                  <b>{daysRemaining} DAYS REMAINING ON YOUR TRIAL:</b> You have {daysRemaining} days remaining on your free trial. <a style={{color: 'white'}} target="_blank" href={'/pricing'}><b>CLICK HERE</b></a> to see pricing details.
                </div>
              </div>
            ),
            type: 'info',
            icon: "now-ui-icons ui-1_bell-53",
          };
          notificationAlert.current.notificationAlert(options);
        } 
        // else if (!user.premium && !user.trial) {
        //   var options = {};
        //   options = {
        //     place: 'br',
        //     message: (
        //       <div>
        //         <div>
        //           Your trial period ends on August 15th, 2024. <a style={{color: 'white'}} target="_blank" href={'/pricing'}><b>CLICK HERE</b></a> to see pricing details.
        //         </div>
        //       </div>
        //     ),
        //     type: 'info',
        //     icon: "now-ui-icons ui-1_bell-53",
        //   };
        //   notificationAlert.current.notificationAlert(options);
        // }
      }
    }

    function showAlertForHelpSessions() {
      if (user && helpSessionAlert.current) {
        var options = {};
        options = {
          place: 'bc',
          message: (
            <div>
              <div>
                Need help with Lesson Launchpad? {' '}
                <a style={{color: 'white'}} target="_blank" href={'https://docs.google.com/forms/d/e/1FAIpQLSdNtDowlK2t78HnMQ3b6HvIfjyr_3QCCjICjTErJHO6alWWbQ/viewform?usp=sf_link'}><b>CLICK HERE</b></a> to sign up for a help session.
              </div>
            </div>
          ),
          type: 'info',
          icon: "now-ui-icons ui-1_bell-53",
        };
        helpSessionAlert.current.notificationAlert(options);
      }
    }

    useEffect(() => {
      showAlertForMobile();
      const currentTimestamp = Date.now();
      if (!user?.premium && user.trial) {
        // Get the timestamp when the alert was last shown from localStorage
        const lastAlertTimestamp = localStorage.getItem('lastAlertTimestamp');
        // If the last alert timestamp is not set or it's been more than a day
        if (!lastAlertTimestamp || currentTimestamp - lastAlertTimestamp >= ONE_DAY_IN_MS) {
          showAlertForTrialPeriod();
  
          // Update the last alert timestamp in localStorage
          localStorage.setItem('lastAlertTimestamp', currentTimestamp.toString());
        }
      }
      const lastHelpSessionAlertTimestamp = localStorage.getItem('lastHelpSessionAlertTimestamp');
        // If the last alert timestamp is not set or it's been more than a day
        if (!lastHelpSessionAlertTimestamp || currentTimestamp - lastHelpSessionAlertTimestamp >= ONE_WEEK_IN_MS) {
          showAlertForHelpSessions();
  
          // Update the last alert timestamp in localStorage
          localStorage.setItem('lastHelpSessionAlertTimestamp', currentTimestamp.toString());
        }
    }, [user]);

    useEffect(() => {
      if (user) {
        setLoadingComplete(true);
      }
      // setTimeout(() => {
      //   setLoadingComplete(true);
      // }, 2000);
    }, [user]);


  if (!loadingComplete) {
    return(
      <Route render={() => 
        <Row
            style={{
                display: 'flex',
                justifyContent: 'center', /* Horizontally center */
                alignItems: 'center', /* Vertically center */
                minHeight: '100vh' /* Ensures it covers the entire viewport height */
            }}
        >
            <Spinner color="info">Loading...</Spinner>
        </Row>
        } 
      />
    )
  }

  const darkMode = false;

  return (
    (user && (user.premium || user.trial)) && classRoutes && !location.pathname.includes('/share/') ? (
      <>
      <NotificationAlert ref={notificationAlert} zIndex={9999}/>
      <NotificationAlert ref={helpSessionAlert} zIndex={9999}/>
      <div className={darkMode ? "wrapper-dark" : "wrapper"}>
        {mobileAlert}
        {trialAlert}
        <Sidebar
          {...props}
          routes={classRoutes}
          bgColor={backgroundColor}
          activeColor={activeColor}
        />
        <Route exact path="/"></Route>
        <div className={darkMode ? "main-panel-dark" : "main-panel"} ref={mainPanel} style={{background: ''}}>
          {/* <AdminNavbar {...props} handleMiniClick={handleMiniClick} /> */}
          <Switch>
            { user && user.classes.length < 1 ?
              <Route exact path="/"></Route>
            : <Route exact path="/"><Present props={{classId: user?.classes[0].classId, showMarquee: user?.classes[0].showMarquee, showClock: user?.classes[0].showClock}}/></Route>
            }
            {getRoutes(classRoutes)}
            {/* <Redirect exact from="/" to={classRoutes[0].views[0].path} /> */}
          </Switch>
        </div>
      </div>
      </>
    ) : !location.pathname.includes('/share/') ?
    (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
        <img src={logo} alt="lesson-launchpad-logo" style={{ width: '300px', height: 'auto', marginBottom: '25px' }}/>
        <Row>
          <h3>Your trial has ended. Please subscribe to continue using Lesson Launchpad.</h3>
        </Row>
        <Row>
          <NavLink to={{ pathname: '/pricing' }}>
            <Button style={{ marginLeft: '15px' }}>
              Click Here to Subscribe to Lesson Launchpad
            </Button>
          </NavLink>
        </Row>
        <br></br>
        <br></br>
        <br></br>
        <Row>
          <h6>If you feel you have reached this page in error, please email <a href="mailto:michael@lessonlaunchpad.com">michael@lessonlaunchpad.com</a>.</h6>
        </Row>
      </div>
    ) : null
  );
}

export default Admin;
