import React, {useState} from 'react'
import { selectUser } from "features/userSlice";
import { useSelector } from "react-redux";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { setDoc, doc } from "firebase/firestore";
import { Table, Input, Button } from "reactstrap";

function RosterTable({classId}) {

    const initialRosterData = {
        roster:  [
            {name: "", descriptor1: "", descriptor2: "", birthday: ""},
            {name: "", descriptor1: "", descriptor2: "", birthday: ""},
            {name: "", descriptor1: "", descriptor2: "", birthday: ""},
            {name: "", descriptor1: "", descriptor2: "", birthday: ""},
            {name: "", descriptor1: "", descriptor2: "", birthday: ""},
            {name: "", descriptor1: "", descriptor2: "", birthday: ""},
            {name: "", descriptor1: "", descriptor2: "", birthday: ""},
            {name: "", descriptor1: "", descriptor2: "", birthday: ""},
            {name: "", descriptor1: "", descriptor2: "", birthday: ""},
            {name: "", descriptor1: "", descriptor2: "", birthday: ""},
        ],
        descriptors: {
            descriptor1: "Descriptor 1",
            descriptor2: "Descriptor 2",
        }
    }

    const user = useSelector(selectUser);
    const classIndex = user.classes.findIndex((c) => c.classId === classId);
    const [editedRosterData, setEditedRosterData] = useState(user?.classes[classIndex]?.rosterData || initialRosterData);

    const saveRoster = async () => {
        try {
            const docRef = doc(db, "users", user.uid);
    
            const userCopy = JSON.parse(JSON.stringify(user));
    
            const classIndex = userCopy.classes.findIndex(
            (c) => c.classId === classId
            );
        
            userCopy.classes[classIndex].rosterData = editedRosterData;

            await setDoc(docRef, userCopy);

            setEditMode(false);
        } catch (error) {
            alert('Error updating document:', error);
        }
    }

    const handleRosterDataEdit = (e, index, field) => {
        const updatedData = { ...editedRosterData };
        updatedData.roster = [...updatedData.roster];
        updatedData.roster[index] = { ...updatedData.roster[index], [field]: e.target.value };
        setEditedRosterData(updatedData);
    };

    const handleRosterRowDelete = (index) => {
        const updatedData = { ...editedRosterData };
    
        // Create a new array by copying the existing roster array
        updatedData.roster = [...updatedData.roster];
    
        // Remove the item at the specified index
        updatedData.roster.splice(index, 1);
    
        // Update the state with the new roster array
        setEditedRosterData(updatedData);
    };

    const handleDescriptorChange = (e, field) => {
        const updatedData = { ...editedRosterData };
        updatedData.descriptors = { ...updatedData.descriptors };
        updatedData.descriptors[field] = e.target.value;
        setEditedRosterData(updatedData);
    }
    
    const handleAddRow = () => {
    const blankRow = { name: "", descriptor1: "", descriptor2: "", birthday: "" };
    setEditedRosterData((prev) => ({ ...prev, roster: [...prev.roster, blankRow] }));
    };

    const [editMode, setEditMode] = useState(false)
    const [editDescriptorMode, setEditDescriptorMode] = useState({descriptor1: false, descriptor2: false})

    return (
        <div>
            <Button onClick={() => !editMode ? setEditMode(!editMode) : saveRoster()}>{!editMode ? "Edit" : "Save"}</Button>
            <Button onClick={handleAddRow}>Add Row</Button>
            <Table>
                <thead>
                    <tr>
                        <th style={{width: '5%'}}>#</th>
                        <th style={{width: '25%'}}>Name</th>
                        <th style={{width: '25%'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                            {editDescriptorMode.descriptor1 === true ? (
                                <i className="fa fa-check" onClick={() => setEditDescriptorMode((prev) => ({...prev, descriptor1: false}))} style={{cursor: 'pointer', marginRight: '3px'}}/>
                            ) : (
                                <i className="fa fa-pencil" onClick={() => setEditDescriptorMode((prev) => ({...prev, descriptor1: true}))} style={{cursor: 'pointer', marginRight: '3px'}}/>
                            )}
                            {editDescriptorMode.descriptor1 === true ? (
                                        <>
                                        {" "}
                                        <Input
                                            type='text'
                                            name='descriptor1'
                                            // defaultValue={editedRosterData.descriptors.descriptor1}
                                            value={editedRosterData?.descriptors?.descriptor1}
                                            onChange={(e) => handleDescriptorChange(e, 'descriptor1')}
                                        />
                                        </>
                                    ) : (
                                        <>
                                        {editedRosterData?.descriptors?.descriptor1}
                                        </>
                                    )}
                            </div>
                        </th>
                        <th style={{width: '25%'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {editDescriptorMode.descriptor2 === true ? (
                                <i className="fa fa-check" onClick={() => setEditDescriptorMode((prev) => ({...prev, descriptor2: false}))} style={{cursor: 'pointer', marginRight: '3px'}}/>
                            ) : (
                                <i className="fa fa-pencil" onClick={() => setEditDescriptorMode((prev) => ({...prev, descriptor2: true}))} style={{cursor: 'pointer', marginRight: '3px'}}/>
                            )}
                            {editDescriptorMode.descriptor2 === true? (
                                        <>
                                        {" "}
                                        <Input
                                            type='text'
                                            name='descriptor2'
                                            // defaultValue={editedRosterData.descriptors.descriptor2}
                                            value={editedRosterData?.descriptors?.descriptor2}
                                            onChange={(e) => handleDescriptorChange(e, 'descriptor2')}
                                        />
                                        </>
                                    ) : (
                                        <>
                                        {editedRosterData?.descriptors?.descriptor2}
                                        </>
                                    )}
                            </div>
                        </th>
                        <th style={{width: '20%'}}>Birthday</th>
                    </tr>
                </thead>
                <tbody>
                    {editedRosterData?.roster?.map((data, index) => (
                        <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>
                                {editMode ? (
                                    <Input
                                        type='text'
                                        name='name'
                                        defaultValue={data.name}
                                        value={data.name}
                                        onChange={(e) => handleRosterDataEdit(e, index, 'name')}
                                    />
                                ) : (
                                    <>
                                        {data.name}
                                    </>
                                )}
                            </td>
                            <td>
                                {editMode ? (
                                    <Input
                                        type='text'
                                        name='descriptor1'
                                        defaultValue={data.descriptor1}
                                        value={data.descriptor1}
                                        onChange={(e) => handleRosterDataEdit(e, index, 'descriptor1')}
                                    />
                                ) : (
                                    <>
                                        {data.descriptor1}
                                    </>
                                )}
                            </td>
                            <td>
                                {editMode ? (
                                    <Input
                                        type='text'
                                        name='descriptor2'
                                        defaultValue={data.descriptor2}
                                        value={data.descriptor2}
                                        onChange={(e) => handleRosterDataEdit(e, index, 'descriptor2')}
                                    />
                                ) : (
                                    <>
                                        {data.descriptor2}
                                    </>
                                )}
                            </td>
                            <td>
                                {editMode ? (
                                    <Input
                                        type='date'
                                        name='birthday'
                                        defaultValue={data.birthday}
                                        value={data.birthday}
                                        onChange={(e) => handleRosterDataEdit(e, index, 'birthday')}
                                    />
                                ) : (
                                    <>
                                        {data.birthday}
                                    </>
                                )}
                            </td>
                            <td>
                                {editMode ? (
                                    <Button
                                        className="btn-icon btn-neutral"
                                        color="danger"
                                        id="tooltip808966390"
                                        size="md"
                                        type="button"
                                        onClick={() => handleRosterRowDelete(index)}
                                    >
                                        <i className="fa fa-trash" />
                                    </Button>
                                ) : (
                                    <>
                                        
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

export default RosterTable;
