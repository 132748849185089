
import CheckoutButton from 'components/CheckoutForm';
import React, { useEffect, useState, useRef } from 'react';
import Toggle from 'react-toggle';
import { useSelector } from 'react-redux';
import { selectUser } from 'features/userSlice';
import { Input, Card, Row, Col, CardTitle, CardHeader, CardFooter, CardBody, Button, UncontrolledTooltip, CardSubtitle, Tooltip } from 'reactstrap';
import { doc, setDoc } from 'firebase/firestore';
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import Contact from './Contact';
import { NavLink } from 'react-router-dom';
import Changelog from 'components/Changelog';
import ReactBSAlert from "react-bootstrap-sweetalert";
import Switch from "react-bootstrap-switch";
import { createShareCode } from 'RandomId';

const Thing = () => {
  const user = useSelector(selectUser);
  const [settings, setSettings] = useState(user && user.settings || {});
  const [saved, setSaved] = useState(false)
  const toggleRef = useRef(null);
  const [alert, setAlert] = useState(null)
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  console.log(settings)

  useEffect(() => {
    const updateSettings = async () => {
      try {
        if (user) {
          const docRef = doc(db, 'users', user.uid);
  
          const userCopy = JSON.parse(JSON.stringify(user));
          userCopy.settings = settings;
  
          // Check if settings have actually changed
          if (JSON.stringify(user.settings) !== JSON.stringify(settings)) {
            // Update the document in Firestore
            await setDoc(docRef, userCopy);

            setSaved(true);
          }
        }
  
        // Set a timer to clear the "Settings Saved" message after 1 second
        setTimeout(() => {
          setSaved(false);
        }, 1200); // 1000 milliseconds = 1 second
      } catch (error) {
        alert('Error updating document:', error);
        console.log(error);
      }
    };
  
    updateSettings();
  }, [settings, user]);

  const areYouSureAlertForAlteredScheduleMode = () => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => {
          setSettings((prev) => ({
          ...prev,
          alteredSchedule: !prev.alteredSchedule,
          }))
          hideAlert()
        }}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        This will turn off all of your automations.
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const handleShareCodeToggle = async () => {
    if (user.settings.useShareCode === undefined || user.shareCode === undefined) {
      //Create share code
      const shareCode = createShareCode();

      //store it in shareCodes collection
      try {
        console.log("attempting to write share code to database")
        await setDoc(doc(db, "shareCodes", shareCode), {uid: user.uid})
      } catch {
        console.log("Failed to write share code to database")
        return; // Exit the function if the share code creation fails
      }
      //add it to user object
      try {
        console.log("attempting to update user document")
        const docRef = doc(db, 'users', user.uid);
  
        const userCopy = JSON.parse(JSON.stringify(user));
        userCopy.settings = { ...userCopy.settings, useShareCode: true };

        userCopy.shareCode = shareCode

        // Update the document in Firestore
        await setDoc(docRef, userCopy);
      } catch {
        console.log("Failed to write share code to user document")
        return;
      }
      setSettings((prev) => ({
        ...prev,
        useShareCode: true,
      }));
    } else {
      setSettings((prev) => ({
        ...prev,
        useShareCode: !prev.useShareCode,
      }));
    }
  }

  const calculateDaysRemaining = () => {
    if (user) {
      const currentDate = new Date();
      const milliseconds = user.accountCreated?.seconds ? user.accountCreated.seconds * 1000 + (user.accountCreated?.nanoseconds || 0) / 1e6 : undefined;
      const accountCreatedDate = new Date(milliseconds);
      const daysRemaining = 30 - (Math.floor((currentDate - accountCreatedDate) / (1000 * 60 * 60 * 24)));
      return daysRemaining;
    }
  }

  // ChatGPT says you don't need this even though it created it for me to fix blurring issue making the data save endlessly
  // const blurToggle = () => {
  //   if (toggleRef.current) {
  //     toggleRef.current.blur();
  //   }
  // };

  return (
    <div className='content'>
      {alert}
    <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <Col md='12' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Card style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CardHeader>
            <CardTitle>
              <h2>Settings</h2>
            </CardTitle>
          </CardHeader>

      <Col style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Col>
          <CardSubtitle>
            Present
          </CardSubtitle>
          <hr></hr>
          <label style={{margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
            <Switch
              checked={settings.showSeconds}
              defaultValue={settings.showSeconds}
              // ref={toggleRef}
              onChange={() => {
                setSettings((prev) => ({
                  ...prev,
                  showSeconds: !prev.showSeconds,
                }));
              }}
            />
            <span style={{marginLeft: '10px'}}>Display seconds on Clock</span>
          </label>
          <label style={{margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center'}} id={"alteredSchedule"}>
            <UncontrolledTooltip
              delay={0}
              target={"alteredSchedule"}
              placement="top"
            >
              A convenient way to turn off any automations when your school is operating on a different schedule for the day.
            </UncontrolledTooltip>
            <Switch
              onColor='default'
              offColor='default'
              checked={settings.alteredSchedule}
              defaultValue={settings.alteredSchedule}
              // ref={toggleRef}
              onChange={() => !settings.alteredSchedule ? areYouSureAlertForAlteredScheduleMode() : setSettings((prev) => ({
                ...prev,
                alteredSchedule: !prev.alteredSchedule,
              }))}
            />
            <span style={{marginLeft: '10px'}}>Altered Schedule Mode</span>
          </label>
          <label style={{margin: '15px'}} id="marqueeSpeed">
            <UncontrolledTooltip
              delay={0}
              target={"marqueeSpeed"}
              placement="top"
            >
              Change the speed of the scrolling marquee on the present screen.
            </UncontrolledTooltip>
          <span>Marquee Speed</span>
          <Input
            id="exampleSelect"
            name="select"
            type="select"
            value={settings.marqueeSpeed || '150'}
            onChange={(e) => {
              setSettings((prev) => ({
                ...prev,
                marqueeSpeed: e.target.value
              }));
            }}
          > <option value={'0'}>
              None
            </option>
            <option value={'50'}>
              Largo
            </option>
            <option value={'100'}>
              Andante
            </option>
            <option value={'150'}>
              Moderato
            </option>
            <option value={'200'}>
              Allegro
            </option>
            <option value={'250'}>
              Vivace
            </option>
          </Input>
          </label>
          <label style={{margin: '15px'}} id="screenTimeout">
            <UncontrolledTooltip
              delay={0}
              target={"screenTimeout"}
              placement="top"
            >
              Use this setting to keep the present screen turned on.
            </UncontrolledTooltip>
          <span>Screen Timeout</span>
          <Input
            id="exampleSelect"
            name="select"
            type="select"
            value={settings.screenTimeout || '60'}
            onChange={(e) => {
              setSettings((prev) => ({
                ...prev,
                screenTimeout: e.target.value
              }));
            }}
          > <option value={'0'}>
              Use System Setting
            </option>
            <option value={'30'}>
              30 minutes
            </option>
            <option value={'45'}>
              45 minutes
            </option>
            <option value={'60'}>
              1 hour (default)
            </option>
            <option value={'90'}>
              1 hour 30 minutes
            </option>
            <option value={'120'}>
              2 hours
            </option>
            <option value={'180'}>
              3 hours
            </option>
            <option value={'240'}>
              4 hours
            </option>
          </Input>
          </label>
        </Col>
        <Col>
          <CardSubtitle>
            Display
          </CardSubtitle>
          <hr></hr>
          <label style={{margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
            <Switch
              checked={settings.useActiveDates === true}
              defaultValue={settings.useActiveDates === true}
              // ref={toggleRef}
              onChange={() => {
                setSettings((prev) => ({
                  ...prev,
                  useActiveDates: !prev.useActiveDates,
                }));
              }}
            />
            <span style={{marginLeft: '10px'}}>Use Active Dates</span>
          </label>
          {/* <label style={{margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
            <Switch
              checked={settings.hideBarsWhenFullscreen === true}
              defaultValue={settings.hideBarsWhenFullscreen === true}
              // ref={toggleRef}
              onChange={() => {
                setSettings((prev) => ({
                  ...prev,
                  hideBarsWhenFullscreen: !prev.hideBarsWhenFullscreen,
                }));
              }}
            />
            <span style={{marginLeft: '10px'}}>Fullscreen Hide Nav and Launch Bar</span>
          </label> */}
          <label style={{margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
            <Toggle
              ref={toggleRef}
              disabled
            />
            <span style={{marginLeft: '10px'}}>Dark Mode (coming soon)</span>
          </label>
          <label style={{margin: '15px'}}>
          <span>Theme Selector (coming soon)</span>
          <Input
            ref={toggleRef}
            disabled
          >
          </Input>
          </label>
        </Col>
        <Col>
          <CardSubtitle>
            Scheduling
          </CardSubtitle>
          <hr></hr>
          <label style={{margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center'}} id={"multiWeek"}>
            <UncontrolledTooltip
              delay={0}
              target={"multiWeek"}
              placement="top"
            >
              Select this option if your class schedule changes week-to-week.
            </UncontrolledTooltip>
            <Toggle
              checked={settings.multiWeek}
              ref={toggleRef}
              onChange={() => {
                setSettings((prev) => ({
                  ...prev,
                  multiWeek: !prev.multiWeek,
                }));
              }}
            />
            <span style={{marginLeft: '10px'}}>Multi-Week Schedule</span>
          </label>
          {settings.multiWeek && 
          <>
            <label style={{margin: '15px'}}>
            <span>How many different weeks does your schedule contain?</span>
            <Input
              id="numberOfWeeks"
              name="select"
              type="select"
              defaultValue={settings.numberOfWeeks}
              onChange={(e) => {
                setSettings((prev) => ({
                  ...prev,
                  numberOfWeeks: e.target.value
                }));
              }}
            >
              <option disabled value={1}>
                --
              </option>
              <option value={2}>
                2 Weeks
              </option>
              <option value={3}>
                3 Weeks
              </option>
              <option value={4}>
                4 Weeks
              </option>
              <option value={5}>
                5 Weeks
              </option>
              <option value={6}>
                6 Weeks
              </option>
              <option value={7}>
                7 Weeks
              </option>
            </Input>
            </label>
            <label style={{margin: '15px'}}>
            <span>Current Week</span>
            <Input
              id="currentWeek"
              name="select"
              type="select"
              value={settings.currentWeek}
              onChange={(e) => {
                setSettings((prev) => ({
                  ...prev,
                  currentWeek: e.target.value
                }));
              }}
            >
              <option value={"A"}>
                Week A
              </option>
              <option value={"B"}>
                Week B
              </option>
              {settings.numberOfWeeks >= "3" && (
                <option value={"C"}>
                  Week C
                </option>
              )}
              {settings.numberOfWeeks >= "4" && (
                <option value={"D"}>
                  Week D
                </option>
              )}
              {settings.numberOfWeeks >= "5" && (
                <option value={"E"}>
                  Week E
                </option>
              )}
              {settings.numberOfWeeks >= "6" && (
                <option value={"F"}>
                  Week F
                </option>
              )}
              {settings.numberOfWeeks >= "7" && (
                <option value={"G"}>
                  Week G
                </option>
              )}
            </Input>
            </label>
          </>
          }
          <label style={{margin: '15px'}}>
            <span>Do you need different times for the same class?</span>
            <UncontrolledTooltip
              delay={0}
              target={"differentTimes"}
              placement="top"
              autohide={false}
            >
              If you have any class that regularly meets at different times this is the option for you. It will give you the ability to set specific times for each day the class meets.
            </UncontrolledTooltip>
            <Input
              id="differentTimes"
              name="select"
              type="select"
              value={settings.differentTimes}
              onChange={(e) => {
                const isDifferentTimes = e.target.value === "true"; // Convert the value to a boolean
                setSettings((prev) => ({
                  ...prev,
                  differentTimes: isDifferentTimes
                }));
              }}
            >
              <option value="false">
                No
              </option>
              <option value="true">
                Yes
              </option>
            </Input>
          </label>
        </Col>
        <Col>
          <CardSubtitle>
            Share
          </CardSubtitle>
          <hr></hr>
            <label id="switch-useShareCode" style={{margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
              <Switch
                checked={settings.useShareCode === true}
                defaultValue={settings.useShareCode === true}
                // ref={toggleRef}
                onChange={() => handleShareCodeToggle()}
              />
              <span style={{marginLeft: '10px'}}>Share Lesson Content</span>
            </label>
            <Tooltip
              placement="top"
              autohide={false}
              isOpen={tooltipOpen}
              target="switch-useShareCode"
              toggle={toggle}
            >
              Share your lesson content with anyone by using a lesson launchpad share link.
            </Tooltip>
            {user.settings?.useShareCode && (
              <>
                <div>
                  Your share URL is:
                </div>
                <br></br>
                <div style={{ textAlign: 'center', fontSize: '14px' }}>
                  {user && 
                    <a href={`https://www.lessonlaunchpad.com/share/${user.shareCode}`} id="shareURL">
                      {`www.lessonlaunchpad.com/share/${user.shareCode}`}
                    </a>
                  }
                  <UncontrolledTooltip
                    delay={0}
                    target={"shareURL"}
                    placement="top"
                    autohide={false}
                  >
                    Share this link with others so they can view a read-only copy of your present screen content.
                  </UncontrolledTooltip>
                </div>
              </>
            )}
            <br></br>
            <CardSubtitle>
            Account
          </CardSubtitle>
            <hr></hr>
            {user && user.premium ?
              <Row style={{margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
                <a href='https://billing.stripe.com/p/login/9AQ29QgsR09R9bOfYY'>
                    <Button>
                        Customer Portal
                    </Button>
                </a>
              </Row>
            :  user.trial ?
              <Row style={{margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
                <br></br>
                <p>You are currently on a 30-day free trial.</p>
                <p>You have {calculateDaysRemaining()} days remaining on your free trial.</p>
                <NavLink to={{pathname: '/pricing'}} >
                    <Button style={{height: '60px'}}>
                        Subscribe to Lesson Launchpad
                    </Button>
                </NavLink>
              </Row>
            :
              <Row style={{margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
                <br></br>
                <p>Your trial has ended.</p>
                <NavLink to={{pathname: '/pricing'}} >
                    <Button style={{height: '60px'}}>
                        Subscribe to Lesson Launchpad
                    </Button>
                </NavLink>
              </Row>
            }
        </Col>
      </Col>
      
      
      <CardFooter style={{height: '20px'}}>
      {saved && saved ? <div style={{display: 'flex', justifyContent: 'left', alignItems: 'center'}}><i className="fa fa-check" style={{marginRight: '5px', marginBottom: '15px', color: 'green'}}/><p style={{color: 'green'}}>Settings Saved</p></div> : <></>}
      </CardFooter>
    </Card>
    </Col>
    </Row>
    <br></br>
    <Row style={{display: 'flex', justifyContent: 'center'}}>
      <Contact />
    </Row>
    {/* <br></br>
    <Row style={{display: 'flex', justifyContent: 'center'}}>
      <h2>FAQ (coming soon)</h2>
    </Row> */}
    <br></br>
    <Row style={{display: 'flex', justifyContent: 'center'}}>
      <Changelog />
    </Row>
    </div>
  );
};

export default Thing;

