import React from 'react';
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const VideoModal = ({ isOpen, toggle, videoUrl, modalClassName, backdrop }) => {
    const getYouTubeEmbedUrl = (url) => {
        if (url.includes('start=')) {
            console.log("#5")
            // https://www.youtube.com/embed/1k8craCGpgs?si=DxDLCQTj_Ke5fAXx&amp;start=102
            const startTime = url.match(/t=(\d+)/);
            const videoId = url.split("/embed/")[1]?.split("?")[0];
            return `https://www.youtube.com/embed/${videoId}?autoplay=1&start=${startTime[1]}`;
        } else if (url.includes('t=')) {
            if (url.includes('feature=shared')) {
                console.log("#1")
                // https://youtu.be/1k8craCGpgs?feature=shared&t=10
                const startTime = url.match(/t=(\d+)/);
                const videoId = url.split("youtu.be/")[1]?.split("?")[0];
                return `https://www.youtube.com/embed/${videoId}?autoplay=1&start=${startTime[1]}`;
            } else if ("youtu.be") {
                console.log("#2")
                // https://youtu.be/1k8craCGpgs?t=57
                const startTime = url.match(/t=(\d+)/);
                const videoId = url.split("youtu.be/")[1]?.split("?")[0]
                return `https://www.youtube.com/embed/${videoId}?autoplay=1&start=${startTime[1]}`;
            } else {
                console.log("#3")
                // https://www.youtube.com/watch?v=1k8craCGpgs&t=100s
                const startTime = url.match(/t=(\d+)/);
                const videoId = url.split("v=")[1]?.split("&")[0];
                return `https://www.youtube.com/embed/${videoId}?autoplay=1&start=${startTime[1]}`;
            }
        } else if (url.includes('feature=shared')) {
            console.log("#4")
            // https://youtu.be/1k8craCGpgs?feature=shared
            const videoId = url.split("youtu.be/")[1]?.split("?")[0];
            return `https://www.youtube.com/embed/${videoId}?autoplay=1`;       
        } else {
            console.log("#6")
            const videoId = url.split("v=")[1]?.split("&")[0];
            return `https://www.youtube.com/embed/${videoId}?autoplay=1`;
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} modalClassName={modalClassName || null} backdrop={backdrop || false}>
        <ModalHeader toggle={toggle}/>
        <ModalBody>
            <iframe
            width="100%"
            height="315"
            src={getYouTubeEmbedUrl(videoUrl)}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YouTube video player"
            ></iframe>
            <div style={{color: 'white'}}>{videoUrl} {' => '} {getYouTubeEmbedUrl(videoUrl)}</div>
        </ModalBody>
        </Modal>
    );
    };

export default VideoModal;
