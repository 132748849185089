import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { Modal, ModalBody, Button } from "reactstrap";
import CountdownTimer from 'components/CountdownTimer.js';
import { useLocation } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";

const UseAutoCountdown = ({clockKey}) => {
  const user = useSelector(selectUser);
  const location = useLocation();
  const [showCountdownModal, setShowCountdownModal] = useState(false);
  const [activeCountdowns, setActiveCountdowns] = useState([]);
  const [countdownSnapshot, setCountdownSnapshot] = useState({});
  const [currentWeek, setCurrentWeek] = useState(user.settings.multiWeek ? user.settings.currentWeek : "A");
  const [nextCountdownDisplay, setNextCountdownDisplay] = useState();
  const [timeDifferenceDisplay, setTimeDifferenceDisplay] = useState();
  const [currentSystemTimeDisplay, setCurrentSystemTimeDisplay] = useState();
  const [isFlashing, setIsFlashing] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkAndTrigger();
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [user, clockKey]);

  const checkAndTrigger = async () => {
    const currentTime = new Date();
    setCurrentSystemTimeDisplay(formatCurrentAndStartTime(currentTime));

    const currentDayNumber = currentTime.getDay();
    let currentDay;

    switch (currentDayNumber) {
      case 1:
        currentDay = "Monday";
        break;
      case 2:
        currentDay = "Tuesday";
        break;
      case 3:
        currentDay = "Wednesday";
        break;
      case 4:
        currentDay = "Thursday";
        break;
      case 5:
        currentDay = "Friday";
        break;
      case 6:
        currentDay = "Saturday";
        break;
      case 7:
        currentDay = "Sunday";
        break;
      default:
        currentDay = "";
        break;
    }

    const processCountdowns = async () => {
      const countdowns = user && user.countdownTimers
        ? await Promise.all(
            user.countdownTimers
              .filter(classMap => classMap.active && classMap?.schedule?.[currentWeek || "A"]?.[currentDay]?.active)
              .map(async countdownMap => {
                if (countdownMap && countdownMap.schedule[currentWeek || "A"]?.[currentDay]) {
                  const startTimeString = countdownMap.schedule[currentWeek || "A"][currentDay].startTime || "0:00";
                  const [startHours, startMinutes] = startTimeString.split(':');
                  const parsedStartTime = new Date();
                  parsedStartTime.setHours(startHours);
                  parsedStartTime.setMinutes(startMinutes);
                  parsedStartTime.setSeconds(0);

                  let bufferAddition = 10;
                  if (countdownMap.youtube === true) {
                    bufferAddition = 500;
                  } else if (countdownMap.alertSound && countdownMap.alertSound.startsWith('http')) {
                    bufferAddition = await calculateBufferAddition(countdownMap.alertSound);
                  } else {
                    if (countdownMap.alertSound === "long") bufferAddition = 18;
                    if (countdownMap.alertSound === "medium") bufferAddition = 11;
                    if (countdownMap.alertSound === "short") bufferAddition = 8;
                    if (countdownMap.alertSound === "none") bufferAddition = countdownMap.duration;
                  }

                  return {
                    startTime: parsedStartTime,
                    duration: countdownMap.duration,
                    header: countdownMap.header,
                    alertMessage: countdownMap.alertMessage,
                    alertSound: countdownMap.alertSound,
                    alertSoundName: countdownMap.alertSoundName,
                    youtube: countdownMap.youtube,
                    youtubeSrc: countdownMap.youtubeSrc,
                    active: countdownMap.active,
                    buffer: (parseFloat(countdownMap.duration) + bufferAddition) * 1000,
                    modalClassName: countdownMap.modalClassName,
                    backdrop: countdownMap.backdrop,
                    flash: countdownMap.youtube ? false : countdownMap.flash || false,
                  };
                } else {
                  return {}; // Return an empty object or handle the null case as needed
                }
              })
          )
        : [];

      const filteredCountdowns = countdowns.filter(countdown => {
        const countdownTime = countdown?.startTime?.getTime();
        const currentTimeTime = currentTime.getTime();
        return countdownTime >= currentTimeTime || currentTimeTime - countdownTime <= 3000;
      });

      filteredCountdowns.sort((a, b) => a.startTime.getTime() - b.startTime.getTime());

      setActiveCountdowns(filteredCountdowns);

      if (filteredCountdowns.length > 0) {
        const nextCountdown = filteredCountdowns[0];
        setNextCountdownDisplay(nextCountdown);
        const timeDifference = nextCountdown.startTime.getTime() - currentTime.getTime();
        setTimeDifferenceDisplay(timeDifference);

        if (timeDifference <= 0 && timeDifference >= -3000) {
          setCountdownSnapshot(nextCountdown);
          setShowCountdownModal(true);
          setTimeout(() => {
            setShowCountdownModal(false);
            setIsFlashing(false);
          }, nextCountdown.buffer - Math.abs(timeDifference));
        }
      }
    };

    processCountdowns();
  };

  const fetchAudioDuration = (url) => {
    return new Promise((resolve, reject) => {
      const audio = new Audio(url);
      audio.onloadedmetadata = () => {
        resolve(audio.duration);
      };
      audio.onerror = (error) => {
        reject(error);
      };
    });
  };

  const calculateBufferAddition = async (alertSound) => {
    let bufferAddition = 10;
    if (alertSound === "none") return 3;

    try {
      const duration = await fetchAudioDuration(alertSound);
      return duration + 3; // Adding 3 seconds to the duration
    } catch (error) {
      console.error('Error fetching audio duration:', error);
      return bufferAddition;
    }
  };

  const handleCountdownComplete = () => {
    if (countdownSnapshot.flash) {
      setIsFlashing(true);
    }
  };

  const toggle = () => {
    setShowCountdownModal(!showCountdownModal);
  };

  const formatTime = (milliseconds) => {
    const hours = Math.floor(milliseconds / 3600000);
    const minutes = Math.floor((milliseconds % 3600000) / 60000);
    const seconds = Math.floor((milliseconds % 60000) / 1000);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const formatCurrentAndStartTime = (currentTime) => {
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format
    return `${formattedHours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm}`;
  };

  return (
    <>
      {location.pathname === "/auto-status" ? (
        <Card>
          <CardHeader className='h3 mb-0'>Auto Countdown</CardHeader>
          <CardBody>
            <b>Next Countdown:</b> {nextCountdownDisplay && nextCountdownDisplay.header || "No countdowns scheduled for the rest of the day."}
            <br></br>
            <b>Next Countdown Start Time:</b> {nextCountdownDisplay && formatCurrentAndStartTime(nextCountdownDisplay.startTime) || "No countdowns scheduled for the rest of the day."}
            <br></br>
            <b>Current System Time:</b> {currentSystemTimeDisplay}
            <br></br>
            <b>Time Until Triggered:</b> {timeDifferenceDisplay && formatTime(timeDifferenceDisplay) || "No countdowns scheduled for the rest of the day."}
          </CardBody>
        </Card>
      ) : (
        showCountdownModal && (
          <Modal isOpen={showCountdownModal} toggle={toggle} contentClassName="custom-modal-content" modalClassName={countdownSnapshot.modalClassName || null} backdrop={countdownSnapshot.backdrop || false}>
            <ModalBody style={{padding: '10px 25px'}}>
              <CountdownTimer props={countdownSnapshot} onComplete={handleCountdownComplete} modalClassName={countdownSnapshot.modalClassName} backdrop={countdownSnapshot.backdrop}/>
              <Button color="primary" onClick={toggle}>Close</Button>
            </ModalBody>
          </Modal>
        )
      )}
      {isFlashing && <div className="flash-effect" style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 9999}}></div>}
    </>
  );
};

export default UseAutoCountdown;
