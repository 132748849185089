import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { Modal, ModalBody, Button } from "reactstrap";
import CountdownTimer from 'components/CountdownTimer.js';
import { useLocation } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";

const UseAutoReminder = ({clockKey}) => {
  const user = useSelector(selectUser);
  const location = useLocation();
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [reminderSnapshot, setReminderSnapshop] = useState({});
  const [currentWeek, setCurrentWeek] = useState(user.settings.multiWeek ? user.settings.currentWeek : "A")
  const [nextReminderDisplay, setNextReminderDisplay] = useState();
  const [timeDifferenceDisplay, setTimeDifferenceDisplay] = useState();
  const [currentSystemTimeDisplay, setCurrentSystemTimeDisplay] = useState();

  const formatTimeUntilTriggered = (milliseconds) => {
    const hours = Math.floor(milliseconds / 3600000);
    const minutes = Math.floor((milliseconds % 3600000) / 60000);
    const seconds = Math.floor((milliseconds % 60000) / 1000);
  
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const formatCurrentAndStartTime = (currentTime) => {
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format
    return `${formattedHours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm}`;
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkAndTrigger();
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [user, clockKey]);

  const checkAndTrigger = () => {
    const currentTime = new Date();
    setCurrentSystemTimeDisplay(formatCurrentAndStartTime(currentTime));
    
    const currentDayNumber = currentTime.getDay();
    let currentDay;

    switch (currentDayNumber) {
      case 1:
        currentDay = "Monday";
        break;
      case 2:
        currentDay = "Tuesday";
        break;
      case 3:
        currentDay = "Wednesday";
        break;
      case 4:
        currentDay = "Thursday";
        break;
      case 5:
        currentDay = "Friday";
        break;
      case 6:
        currentDay = "Saturday";
        break;
      case 7:
        currentDay = "Sunday";
        break;
      default:
        currentDay = "";
        break;
    }

    const reminders = user && user.reminders
    ? user.reminders
      .filter(classMap => classMap.active && classMap?.schedule?.[currentWeek || "A"]?.[currentDay]?.active)
      .map(reminderMap => {
        if (reminderMap && reminderMap?.schedule[currentWeek || "A"]?.[currentDay]) {
          const startTimeString = reminderMap.schedule[currentWeek || "A"][currentDay].startTime || "0:00";
          const [startHours, startMinutes] = startTimeString.split(':');
          const parsedStartTime = new Date();
          parsedStartTime.setHours(startHours);
          parsedStartTime.setMinutes(startMinutes);
          parsedStartTime.setSeconds(0);
  
          return {
            startTime: parsedStartTime,
            duration: reminderMap.duration,
            reminderMessage: reminderMap.reminderMessage,
          };
        } else {
          return {}; // Return an empty object or handle the null case as needed
        }
      })
    : [];
  
  const filteredReminders = reminders.filter(reminder => {
    const reminderTime = reminder?.startTime?.getTime();
    const currentTimeTime = currentTime.getTime();
    const tolerance = -3000; // 3-second tolerance
  
    // Adjusted condition to include countdowns within 3 seconds after their start time
    return reminderTime >= currentTimeTime || currentTimeTime - reminderTime <= 3000;
  });
  
  filteredReminders.sort((a, b) => a.startTime.getTime() - b.startTime.getTime());
  
  if (filteredReminders.length > 0) {
    const nextReminder = filteredReminders[0];
    setNextReminderDisplay(nextReminder);
    const timeDifference = nextReminder.startTime.getTime() - currentTime.getTime();
    setTimeDifferenceDisplay(timeDifference);
  
    // This now checks if the timeDifference is between -3000ms (3 seconds after the startTime) and 0ms (exact startTime)
    if (timeDifference <= 0 && timeDifference >= -3000) {
      setReminderSnapshop(nextReminder);
      setShowReminderModal(true);
      setTimeout(() => {
        setShowReminderModal(false);
      }, nextReminder.duration * 1000);
    }
  }
}

  const toggle = () => {
    setShowReminderModal(!showReminderModal);
  };

  return (
    <>
      {location.pathname === "/auto-status" ? (
        <Card>
          <CardHeader className='h3 mb-0'>Auto Reminder</CardHeader>
          <CardBody>
          <b>Next Reminder Message:</b> {nextReminderDisplay && nextReminderDisplay.reminderMessage || "No reminders scheduled for the rest of the day."}
          <br></br>
          <b>Next Reminder Start Time:</b> {nextReminderDisplay && formatCurrentAndStartTime(nextReminderDisplay.startTime) || "No reminders scheduled for the rest of the day."}
          <br></br>
          <b>Current System Time:</b> {currentSystemTimeDisplay}
          <br></br>
          <b>Time Until Triggered:</b> {timeDifferenceDisplay && formatTimeUntilTriggered(timeDifferenceDisplay) || "No reminders scheduled for the rest of the day."}
          </CardBody>
        </Card>
      ) : (
        showReminderModal && (
          <Modal isOpen={showReminderModal} toggle={toggle}>
            <ModalBody>
              <div>
              <h1 style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>{reminderSnapshot.reminderMessage}</h1>
              </div>
              <Button color="primary" onClick={toggle}>
                Close
              </Button>
            </ModalBody>
          </Modal>
        )
      )}
    </>
  );
};

export default UseAutoReminder;
