/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { arrayOf, objectOf } from "prop-types";
import AddEditView from "views/AddEditView";
import AddEditClassForm from "views/forms/AddEditClassForm";
import AddEditAnnouncementForm from "views/forms/AddEditAnnouncementForm";
import AddEditCountdownForm from "views/forms/AddEditCountdownForm";
import Contact from "views/Contact";
import Thing from "views/Thing";
import LandingPage from "views/LandingPage"
import Privacy from "components/Privacy"
import PricingTable from "components/PricingTable";
import AddEditReminderForm from "views/forms/AddEditReminderForm";
import ShowRegisterPay from "views/ShowRegisterPay";
import FAQ from "components/FAQ";
import AutoFeaturesStatus from "components/AutoFeaturesStatus";
import TabScroll from "components/TabScroll";
import PrintView from "components/PrintView";
import PanelsTest from "components/PanelsTest";

const routes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: "nc-icon nc-bank",
  //   component: Dashboard,
  //   layout: "/admin"
  // },
  {
    collapse: true,
    name: "Present Classes",
    icon: "nc-icon nc-book-bookmark",
    state: "pagesCollapse",
    views: []
  },
  {
    path: "/add-edit-classes",
    name: "Add/Edit",
    icon: "nc-icon nc-simple-add",
    component: AddEditView,
    layout: "/admin",
    visible: true
  },
  {
    path: "/form",
    name: "Form",
    icon: "nc-icon nc-laptop",
    component: AddEditClassForm,
    layout: "/admin",
    visible: false
  },
  {
    path: "/countdown-form",
    name: "Countdown Form",
    icon: "nc-icon nc-laptop",
    component: AddEditCountdownForm,
    layout: "/admin",
    visible: false
  },
  {
    path: "/reminder-form",
    name: "Reminder Form",
    icon: "nc-icon nc-laptop",
    component: AddEditReminderForm,
    layout: "/admin",
    visible: false
  },
  {
    path: "/announcement-form",
    name: "Add/Edit Announcements",
    icon: "nc-icon nc-laptop",
    component: AddEditAnnouncementForm,
    layout: "/admin",
    visible: false
  },
  {
    path: "/auto-status",
    name: "Auto Status",
    icon: "nc-icon nc-laptop",
    component: AutoFeaturesStatus,
    layout: "/admin",
    visible: false
  },
  // {
  //   path: "/tools",
  //   name: "Tools",
  //   icon: "nc-icon nc-settings",
  //   component: Tools,
  //   layout: "/admin",
  //   visible: true
  // },
  {
    path: "/fullscreen",
    name: "Fullscreen",
    icon: "nc-icon nc-tv-2",
    layout: "/admin",
    visible: true
  },
  // {
  //   path: "/contact",
  //   name: "Contact",
  //   icon: "nc-icon nc-email-85",
  //   component: Contact,
  //   layout: "/admin",
  //   visible: true
  // },
  {
    path: "/print",
    name: "Print",
    component: PrintView,
    layout: "/admin",
    visible: false
  },
  {
    path: "/print",
    name: "Print",
    icon: "fa fa-print",
    component: PrintView,
    layout: "/admin",
    visible: true
  },
  {
    path: "/share",
    name: "Print",
    layout: "/share",
    visible: false
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "nc-icon nc-settings-gear-65",
    component: Thing,
    layout: "/admin",
    visible: true
  },
  {
    path: "/faq",
    name: "FAQ",
    icon: "fa fa-question",
    component: FAQ,
    layout: "/admin",
    visible: true
  },
  {
    path: "/auth/logout",
    name: "Logout",
    icon: "nc-icon nc-user-run",
    layout: "/auth",
    visible: true
  },
  {
    path: "/get-started",
    name: "Landing Page",
    component: LandingPage,
    layout: "/auth",
    visible: false
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
    layout: "/admin",
    visible: false
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
    layout: "/auth",
    visible: false
  },
  // {
  //   path: "/scroll",
  //   name: "Scroll",
  //   component: TabScroll,
  //   layout: "/admin",
  //   visible: false
  // },
    {
    path: "/panel",
    name: "Panel",
    component: PanelsTest,
    layout: "/admin",
    visible: false
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: PricingTable,
    layout: "/auth",
    visible: false
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: PricingTable,
    layout: "/admin",
    visible: false
  },

  // {
  //   path: "/login",
  //   name: "Login",
  //   mini: "",
  //   component: Login,
  //   layout: "/auth"
  // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   // mini: "R",
  //   component: Register,
  //   layout: "/auth"
  // },
  // {
  //   collapse: true,
  //   name: "Pages",
  //   icon: "nc-icon nc-book-bookmark",
  //   state: "pagesCollapse",
  //   views: [
  //     {
  //       path: "/timeline",
  //       name: "Timeline",
  //       mini: "T",
  //       component: Timeline,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/login",
  //       name: "Login",
  //       mini: "L",
  //       component: Login,
  //       layout: "/auth"
  //     },
  //     {
  //       path: "/register",
  //       name: "Register",
  //       mini: "R",
  //       component: Register,
  //       layout: "/auth"
  //     },
  //     {
  //       path: "/lock-screen",
  //       name: "LockScreen",
  //       mini: "LS",
  //       component: LockScreen,
  //       layout: "/auth"
  //     },
  //     {
  //       path: "/user-profile",
  //       name: "UserProfile",
  //       mini: "UP",
  //       component: UserProfile,
  //       layout: "/admin"
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Components",
  //   icon: "nc-icon nc-layout-11",
  //   state: "componentsCollapse",
  //   views: [
  //     {
  //       path: "/buttons",
  //       name: "Buttons",
  //       mini: "B",
  //       component: Buttons,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/grid-system",
  //       name: "Grid System",
  //       mini: "GS",
  //       component: GridSystem,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/panels",
  //       name: "Panels",
  //       mini: "P",
  //       component: Panels,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/sweet-alert",
  //       name: "Sweet Alert",
  //       mini: "SA",
  //       component: SweetAlert,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/notifications",
  //       name: "Notifications",
  //       mini: "N",
  //       component: Notifications,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/icons",
  //       name: "Icons",
  //       mini: "I",
  //       component: Icons,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/typography",
  //       name: "Typography",
  //       mini: "T",
  //       component: Typography,
  //       layout: "/admin"
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Forms",
  //   icon: "nc-icon nc-ruler-pencil",
  //   state: "formsCollapse",
  //   views: [
  //     {
  //       path: "/regular-forms",
  //       name: "Regular Forms",
  //       mini: "RF",
  //       component: RegularForms,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/extended-forms",
  //       name: "Extended Forms",
  //       mini: "EF",
  //       component: ExtendedForms,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/validation-forms",
  //       name: "Validation Forms",
  //       mini: "VF",
  //       component: ValidationForms,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/wizard",
  //       name: "Wizard",
  //       mini: "W",
  //       component: Wizard,
  //       layout: "/admin"
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Tables",
  //   icon: "nc-icon nc-single-copy-04",
  //   state: "tablesCollapse",
  //   views: [
  //     {
  //       path: "/regular-tables",
  //       name: "Regular Tables",
  //       mini: "RT",
  //       component: RegularTables,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/extended-tables",
  //       name: "Extended Tables",
  //       mini: "ET",
  //       component: ExtendedTables,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/react-tables",
  //       name: "React Tables",
  //       mini: "RT",
  //       component: ReactTables,
  //       layout: "/admin"
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   state: "mapsCollapse",
  //   views: [
  //     {
  //       path: "/google-maps",
  //       name: "Google Maps",
  //       mini: "GM",
  //       component: GoogleMaps,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/full-screen-map",
  //       name: "Full Screen Map",
  //       mini: "FSM",
  //       component: FullScreenMap,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/vector-map",
  //       name: "Vector Map",
  //       mini: "VM",
  //       component: VectorMap,
  //       layout: "/admin"
  //     }
  //   ]
  // },
  // {
  //   path: "/charts",
  //   name: "Charts",
  //   icon: "nc-icon nc-chart-bar-32",
  //   component: Charts,
  //   layout: "/admin"
  // },
  // {
  //   path: "/calendar",
  //   name: "Calendar",
  //   icon: "nc-icon nc-calendar-60",
  //   component: Calendar,
  //   layout: "/admin"
  // }
];

export default routes;
