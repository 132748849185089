import userSlice from 'features/userSlice';
import React from 'react';
import { Card, CardBody } from 'reactstrap'
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { useLocation } from "react-router-dom";

function PricingTable() {

    const user = useSelector(selectUser)
    const location = useLocation();

    return (
      location.pathname.includes("non-renew") ? (
        <div style={{ backgroundColor: "rgb(11, 192, 223)", display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <Card>
              <CardBody>
                <stripe-buy-button
                  buy-button-id="buy_btn_1PiLdHEcrsj3dDGxqfH9U2fO"
                  publishable-key="pk_live_51Nu711Ecrsj3dDGxkLAjXv80asu2XZSy93BwoTvLzMJZIS40tt1BSVy6yy0yTZzvcsyLKgyyCeIcOitrB78uCvvn00OlAZth85"
                >
                </stripe-buy-button>
              </CardBody>
            </Card>
        </div>
      )
      :
      (
        <>
        <div style={{ backgroundColor: "rgb(11, 192, 223)", display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          {user &&
              <Card>
              <CardBody>
                  <stripe-pricing-table
                  pricing-table-id="prctbl_1NwTptEcrsj3dDGxYb7Hj30Y"
                  publishable-key="pk_live_51Nu711Ecrsj3dDGxkLAjXv80asu2XZSy93BwoTvLzMJZIS40tt1BSVy6yy0yTZzvcsyLKgyyCeIcOitrB78uCvvn00OlAZth85"
                  client-reference-id={user.uid}
                  >
                  </stripe-pricing-table>
              </CardBody>
              </Card>
          }
        </div>
        {/* <div style={{ backgroundColor: "rgb(11, 192, 223)", display: 'flex', justifyContent: 'center', height: '30vh'}}>
          <a href='pricing-multiple' style={{color: 'white'}}><h2>Want a discount?</h2></a>
        </div> */}

        </>
      )
    );
  }

export default PricingTable;