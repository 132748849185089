/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {React, useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { setDoc, doc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useLocation, useHistory } from 'react-router-dom';
import { createRandomId } from "RandomId";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  FormFeedback,
  UncontrolledTooltip
} from "reactstrap";

function AddEditReminderForm() {

  const location = useLocation();
  const { state } = location;
  const item = state?.reminder;
  const user = useSelector(selectUser);
  const history = useHistory();
  const [validated, setValidated] = useState(false);
  const [multiWeek, setMultiWeek] = useState(user.settings.multiWeek);
  const [numberOfWeeks, setNumberOfWeeks] = useState(user.settings.numberOfWeeks || 2);
  const [differentTimes, setDifferentTimes] = useState(user.settings.differentTimes)
  const [currentWeek, setCurrentWeek] = useState("A") //this is not the settings currentWeek - "A" should remain the default

  const addWeeksToSchedule = async () => {
    if (item !== undefined) {
      if (!('G' in item.schedule)) {
        try {
          const docRef = doc(db, "users", user.uid);
    
          const userCopy = JSON.parse(JSON.stringify(user));
    
          const classIndex = userCopy.classes.findIndex(
            (c) => c.classId === data.classId
          );
    
          const newWeeks = {
            "E": {
              "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
            },
            "F": {
              "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
            },
            "G": {
              "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
            },
          };
    
          userCopy.reminders[state.index].schedule = { ...userCopy.reminders[state.index].schedule, ...newWeeks };
    
          // Update the document in Firestore
          await setDoc(docRef, userCopy);

          // Update the data state
          setData((prevData) => ({
            ...prevData,
            schedule: { ...prevData.schedule, ...newWeeks },
          }));
    
        } catch (error) {
          console.error("Error updating schedule: ", error);
        }
      }
    }
  };

  useEffect(() => {
    addWeeksToSchedule();
  }, []);

  function cycleWeeks(direction) {
    const weekLetterOptions = ["A", "B", "C", "D", "E", "F", "G"];
    let currentIndex = weekLetterOptions.indexOf(currentWeek);

    if (direction === "forward") {
      if (currentIndex === numberOfWeeks-1) {
        currentIndex = 0;
      } else {
        currentIndex++;
      }
    } else if (direction === "backward") {
      if (currentIndex === 0) {
        currentIndex = numberOfWeeks-1;
      } else {
        currentIndex--;
      }
    }
  
    setCurrentWeek(weekLetterOptions[currentIndex]);
  }

  const blankSchedule = {
    "A": {
      "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "B": {
      "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "C": {
      "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "D": {
      "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "E": {
      "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "F": {
      "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "G": {
      "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
  }

  const [data, setData] = useState({
    startTime: item?.startTime || "00:00",
    duration: item?.duration || 0,
    active: item?.active === false ? false : item?.active === true ? true : true,
    reminderMessage: item?.reminderMessage || '',
    days: [],
    schedule: item?.schedule || blankSchedule
  });

  const [alert, setAlert] = useState(null);
  // to stop the warning of calling setState of unmounted component
  useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(db, "users", user.uid);

      const userCopy = JSON.parse(JSON.stringify(user));

      const newReminder = {
        startTime: data.startTime,
        duration: data.duration,
        active: data.active,
        reminderMessage: data.reminderMessage,
        days: data.days,
        schedule: data.schedule
      }

      if (!userCopy.reminders) {
        userCopy.reminders = []; // Initialize it as an empty array if it doesn't exist
      }

      // Update the content box in the document data
      if (item === undefined) {
        userCopy.reminders.push(newReminder);
      } else {
        userCopy.reminders[state.index] = data 
      }

      // Update the document in Firestore
      await setDoc(docRef, userCopy);

      successAlert();
    } catch (error) {
      window.alert('Error updating document:', error);
    }
  }

  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Reminder Saved!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
    history.push("/add-edit-classes")
  };

  function handleFormNonDays(e) {
    const { id, value, type, checked } = e.target;

    // Check if the input element is a checkbox
    if (type === 'checkbox') {
      // Update the state based on the checkbox's checked property
      setData((prevData) => ({
        ...prevData,
        [id]: checked, // Update the corresponding property in the state
      }));
    } else {
      // For non-checkbox inputs, update the state based on the input's value
      setData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  }

  function handleFormDays(e) {
    const { value, checked } = e.target;

    // Clone the existing days map from state
    const updatedDays = [ ...data.days ];

    if (data.days.length === 0) {
      updatedDays.push(
        {
          M: false,
          T: false,
          W: false,
          Th: false,
          F: false,
        }
      )
    }

    // Update the value in the map based on checkbox state
    updatedDays[0][value] = checked;

    // Update the state with the new days map
    setData((prevData) => ({
      ...prevData,
      days: updatedDays,
    }));
  }

  function handleSecondWeekFormDays(e) {
    const { value, checked } = e.target;

    // Clone the existing days map from state
    const updatedDays = [ ...data.days ];

    if (data.days.length === 1) {
      updatedDays.push(
        {
          M: false,
          T: false,
          W: false,
          Th: false,
          F: false,
        }
      )
    }

    // Update the value in the map based on checkbox state
    updatedDays[1][value] = checked;

    // Update the state with the new days map
    setData((prevData) => ({
      ...prevData,
      days: updatedDays,
    }));
  }

  const handleScheduleChange = (week, day, field, value) => {

    // Create a copy of the schedule to avoid mutating the state directly
    const updatedSchedule = JSON.parse(JSON.stringify(data.schedule));
    
    // Update the specified field in the schedule
    updatedSchedule[week][day][field] = value;
  
    // Set the updated schedule in your state or wherever you store it
    setData((prevData) => ({
      ...prevData,
      schedule: updatedSchedule,
    }));
  };

  function handleSingleStartTime(e) {
    const startTime = e.target.value;
    const updatedSchedule = JSON.parse(JSON.stringify(data.schedule));
  
    for (const group in updatedSchedule) {
      for (const day in updatedSchedule[group]) {
        updatedSchedule[group][day] = { ...updatedSchedule[group][day], startTime };
      }
    }
  
    setData({ ...data, schedule: updatedSchedule, startTime: startTime });
  }

  return (
    <>
      <div className="content">
        <Row style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
          <Col md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Add/Edit Reminder</CardTitle>
              </CardHeader>
              <CardBody>
                <Form
                  className="form-horizontal"
                  noValidate // Remove 'noValidate' if you want to use HTML5 validation as well
                  validated={validated}
                >
                  <Row style={{ marginTop: "30px"}}>
                    <Col>
                      <label style={{ fontSize: '14px' }}>Reminder Message</label>
                      <FormGroup>
                        <Input
                          onChange={(e) => handleFormNonDays(e)}
                          placeholder="Ex: It's time for the volleyball players to leave."
                          type="text"
                          id="reminderMessage"
                          value={data.reminderMessage}
                          // required
                          valid={data.reminderMessage.length <= 100} // Display valid feedback
                          invalid={data.reminderMessage.length > 100} // Display invalid feedback
                          >
                        </Input>
                        {data.reminderMessage.length > 100 ? (
                          <FormFeedback invalid>Alert Message is too long (max 100 characters).</FormFeedback>
                        ) : (
                          <FormFeedback valid>Looks good!</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginLeft: '35px'}}>
                    {!differentTimes &&
                    <Col>
                      <UncontrolledTooltip
                        delay={0}
                        target={"startTime"}
                        placement="top"
                      >
                        This is the time you want the reminder to appear on the screen.
                      </UncontrolledTooltip>
                      <label id="startTime" style={{ fontSize: '14px' }}>Start Time</label>
                      <FormGroup>
                        <Input required onChange={(e) => handleSingleStartTime(e)} type="time" id="startTime" value={data.startTime}/>
                      </FormGroup>
                    </Col>
                    }
                    <UncontrolledTooltip
                        delay={0}
                        target={"duration"}
                        placement="top"
                      >
                        This is how long in seconds you want your reminder to remain on the screen. You can always manually close it before this.
                      </UncontrolledTooltip>
                    <Col>
                      <label id="duration" style={{ fontSize: '14px' }}>Duration</label>
                      <FormGroup>
                        <Input required onChange={(e) => handleFormNonDays(e)} type="number" min={1} max={3600} id="duration" value={data.duration}/>
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <label id="activeTip" style={{ fontSize: '14px' }}>Active</label>
                      <FormGroup >
                      <UncontrolledTooltip
                        delay={0}
                        target={"activeTip"}
                        placement="top"
                      >
                        If checked, this reminder will activate at the time requested.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleFormNonDays(e)} 
                          type="checkbox" 
                          id="active" 
                          checked={data.active}
                          defaultChecked={true}
                          style={{
                            marginLeft: '15px',
                            marginTop: '12px',
                            transform: 'scale(2)'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {!multiWeek && !differentTimes &&
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"monday"}
                        placement="top"
                      >
                        Check if this class meets on Monday.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleScheduleChange("A", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.A.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"tuesday"}
                        placement="top"
                      >
                        Check if this class meets on Tuesday.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleScheduleChange("A", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.A.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"wednesday"}
                        placement="top"
                      >
                        Check if this class meets on Wednesday.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleScheduleChange("A", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.A.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"thursday"}
                        placement="top"
                      >
                        Check if this class meets on Thursday.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleScheduleChange("A", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.A.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"friday"}
                        placement="top"
                      >
                        Check if this class meets on Friday.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleScheduleChange("A", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.A.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  }




                  {multiWeek && !differentTimes &&
                  <>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    Week A
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("A", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="monday" 
                        checked={data.schedule?.A?.Monday?.active}
                        // value={"M"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <Input 
                          onChange={(e) => handleScheduleChange("A", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="tuesday" 
                        checked={data.schedule?.A?.Tuesday?.active}
                        // value={"T"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                   <Input 
                          onChange={(e) => handleScheduleChange("A", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="wednesday" 
                        checked={data.schedule?.A?.Wednesday?.active}
                        // value={"W"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <Input 
                          onChange={(e) => handleScheduleChange("A", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="thursday" 
                        checked={data.schedule?.A?.Thursday?.active}
                        // value={"Th"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <Input 
                          onChange={(e) => handleScheduleChange("A", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="friday" 
                        checked={data.schedule?.A?.Friday?.active}
                        // value={"F"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>



                <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    Week B
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("B", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="monday" 
                        checked={data.schedule?.B?.Monday?.active}
                        // value={"M"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <Input 
                          onChange={(e) => handleScheduleChange("B", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="tuesday" 
                        checked={data.schedule?.B?.Tuesday?.active}
                        // value={"T"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                   <Input 
                          onChange={(e) => handleScheduleChange("B", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="wednesday" 
                        checked={data.schedule?.B?.Wednesday?.active}
                        // value={"W"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <Input 
                          onChange={(e) => handleScheduleChange("B", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="thursday" 
                        checked={data.schedule?.B?.Thursday?.active}
                        // value={"Th"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <Input 
                          onChange={(e) => handleScheduleChange("B", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="friday" 
                        checked={data.schedule?.B?.Friday?.active}
                        // value={"F"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                

                {numberOfWeeks === "3" && multiWeek && !differentTimes &&
                <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week C
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("C", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule?.C?.Monday?.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule?.C?.Tuesday?.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("C", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule?.C?.Wednesday?.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule?.C?.Thursday?.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data?.schedule?.C?.Friday?.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  }

                  {numberOfWeeks === "4" && multiWeek && !differentTimes &&
                  <>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week C
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("C", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule?.C?.Monday?.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule?.C?.Tuesday?.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("C", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule?.C?.Wednesday?.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule?.C?.Thursday?.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule?.C?.Friday?.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week D
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("D", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule?.D?.Monday?.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule?.D?.Tuesday?.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("D", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule?.D?.Wednesday?.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule?.D?.Thursday?.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data?.schedule?.D?.Friday?.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  </>
                  }
                  </>
                  }

{ differentTimes && !multiWeek &&
                  <Row>
                  <Col md="4">
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'center'}}>
                    <Col md="4" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("A", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule["A"].Monday.active}
                          // value={"M"}
                          style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="8">
                      <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                      <FormGroup>
                        <Input onChange={(e) => handleScheduleChange("A", "Monday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule["A"].Monday.active ? data.schedule["A"].Monday.startTime : null}/>
                      </FormGroup>
                    </Col>
                    {/* <Col md="5">
                      <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                      <FormGroup>
                        <Input onChange={(e) => handleScheduleChange("A", "Monday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule["A"].Monday.endTime}/>
                      </FormGroup>
                    </Col> */}
                  </Row>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'center'}}>
                  <Col md="4" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange("A", "Tuesday", "active", e.target.checked)} 
                        type="checkbox" 
                        id="tuesday" 
                        checked={data.schedule["A"].Tuesday.active}
                        // value={"T"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="8">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Tuesday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule["A"].Tuesday.active ? data.schedule["A"].Tuesday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  {/* <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Tuesday", "endTime", e.target.checked)} type="time" id="endTime" value={data.schedule["A"].Tuesday.endTime}/>
                    </FormGroup>
                  </Col> */}
                </Row>
                </Col>
                <Col md="4">
                <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'center'}}>
                  <Col md="4" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange("A", "Wednesday", "active", e.target.checked)} 
                        type="checkbox" 
                        id="wednesday" 
                        checked={data.schedule["A"].Wednesday.active}
                        // value={"W"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="8">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Wednesday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule["A"].Wednesday.active ? data.schedule["A"].Wednesday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  {/* <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Wednesday", "endTime", e.target.checked)} type="time" id="endTime" value={data.schedule["A"].Wednesday.endTime}/>
                    </FormGroup>
                  </Col> */}
                </Row>
                <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'center'}}>
                  <Col md="4" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange("A", "Thursday", "active", e.target.checked)}
                        type="checkbox" 
                        id="thursday" 
                        checked={data.schedule["A"].Thursday.active}
                        // value={"Th"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="8">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Thursday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule["A"].Thursday.active ? data.schedule["A"].Thursday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  {/* <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Thursday", "endTime", e.target.checked)} type="time" id="endTime" value={data.schedule["A"].Thursday.endTime}/>
                    </FormGroup>
                  </Col> */}
                </Row>
                </Col>
                <Col md="4">
                <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'center'}}>
                  <Col md="4" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange("A", "Friday", "active", e.target.checked)}
                        type="checkbox" 
                        id="friday" 
                        checked={data.schedule["A"].Friday.active}
                        // value={"F"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="8">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Friday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule["A"].Friday.active ? data.schedule["A"].Friday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  {/* <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Friday", "endTime", e.target.checked)} type="time" id="endTime" value={data.schedule["A"].Friday.endTime}/>
                    </FormGroup>
                  </Col> */}
                </Row>
                </Col>
                </Row>
                  }




                { differentTimes && multiWeek &&
                  <>
                  <Row style={{marginTop: "30px", marginBottom: '10px', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <i className="nc-icon nc-minimal-left" style={{cursor: 'pointer'}} onClick={(() => cycleWeeks("backward"))}/>
                      <h3 style={{marginLeft: '15px', marginRight: '15px', marginBottom: '0px'}}>Week {currentWeek}</h3>
                    <i className="nc-icon nc-minimal-right"style={{cursor: 'pointer'}} onClick={(() => cycleWeeks("forward"))}/>
                  </Row>
                  <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange(currentWeek, "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule[currentWeek]?.Monday.active}
                          // value={"M"}
                          style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="5">
                      <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                      <FormGroup>
                      {console.log(currentWeek)}
                      <Input onChange={(e) => handleScheduleChange(currentWeek, "Monday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule[currentWeek].Monday?.active ? data.schedule[currentWeek].Monday?.startTime : null}/>
                      </FormGroup>
                    </Col>
                    {/* <Col md="5">
                      <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                      <FormGroup>
                      <Input onChange={(e) => handleScheduleChange(currentWeek, "Monday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule[currentWeek].Monday.active ? data.schedule[currentWeek].Monday.endTime : null}/>
                      </FormGroup>
                    </Col> */}
                  </Row>
                  <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange(currentWeek, "Tuesday", "active", e.target.checked)} 
                        type="checkbox" 
                        id="tuesday" 
                        checked={data.schedule[currentWeek]?.Tuesday.active}
                        // value={"T"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Tuesday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule[currentWeek].Tuesday.active ? data.schedule[currentWeek].Tuesday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  {/* <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Tuesday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule[currentWeek].Tuesday.active ? data.schedule[currentWeek].Tuesday.endTime : null}/>
                    </FormGroup>
                  </Col> */}
                </Row>
                <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange(currentWeek, "Wednesday", "active", e.target.checked)} 
                        type="checkbox" 
                        id="wednesday" 
                        checked={data.schedule[currentWeek]?.Wednesday.active}
                        // value={"W"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Wednesday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule[currentWeek].Wednesday.active ? data.schedule[currentWeek].Wednesday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  {/* <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Wednesday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule[currentWeek].Wednesday.active ? data.schedule[currentWeek].Wednesday.endTime : null}/>
                    </FormGroup>
                  </Col> */}
                </Row>
                <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange(currentWeek, "Thursday", "active", e.target.checked)} 
                        type="checkbox" 
                        id="thursday" 
                        checked={data.schedule[currentWeek]?.Thursday.active}
                        // value={"Th"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Thursday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule[currentWeek].Thursday.active ? data.schedule[currentWeek].Thursday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  {/* <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Thursday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule[currentWeek].Thursday.active ? data.schedule[currentWeek].Thursday.endTime : null}/>
                    </FormGroup>
                  </Col> */}
                </Row>
                <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange(currentWeek, "Friday", "active", e.target.checked)} 
                        type="checkbox" 
                        id="friday" 
                        checked={data.schedule[currentWeek]?.Friday.active}
                        // value={"F"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Friday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule[currentWeek].Friday.active ? data.schedule[currentWeek].Friday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  {/* <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Friday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule[currentWeek].Friday.active ? data.schedule[currentWeek].Friday.endTime : null}/>
                    </FormGroup>
                  </Col> */}
                </Row>
                </>
                  }

                  <Row style={{marginTop: "30px", marginLeft: "50px"}}>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-round" color="info" type="submit"  onClick={(e) => handleSubmit(e)}>
                  Save
                </Button>
              </CardFooter>
            </Card>
            {alert}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AddEditReminderForm;
